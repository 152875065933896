.slider{
    position: relative;
    height: 100%;
    width: 100%;
}

.slider-body {
    display: flex;
    flex-direction: row;
    height: 16rem ;
    width: 100%;
    gap: 1rem;
    overflow-x: scroll;
    scroll-behavior: smooth;
}

.slider-content{
    height: 100%;
    border-radius: 1rem;
    /* overflow: clip; */
    aspect-ratio: 4/3;
}

.slider-content-image {
    height: 100%;
    width: 100%;
    border-radius: 1rem;
    object-position: center;    
    object-fit: cover;
}

.slider-action {
    position: absolute;
    display: flex;
    height: 100%;
    align-items: center;
    bottom: 0;
    top: 0;
}

.slider-action#right{
    right: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}

.slider-action#left{
    left: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}

.slider-action-button {
    aspect-ratio: 1;
    cursor: pointer;
    border-radius: 100%;
    border-width: 1px;
    background-color: white;
    padding: 0.25rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease; /* Add a smooth transition effect */
}
  
.slider-action-button:hover {
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
  }
  
.slider-action-button .icon {
    color: gray;
}

.slider-action-button .icon:hover {
    color: black;
}