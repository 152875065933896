*{
    font-family: Nunito;
}
.eventMenu{
    background-color: #F5F5F5;
    border: 1px solid #ECECEC;
    height: 34px;
    width: 34px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.event-thumbnail{
    height: 200px;
    width: auto;
    object-fit: cover;
}
.event-details{
    padding: 0 20px 20px 20px;
}
.event-title{
    font-size: 16px;
    font-weight: 600;
    color:#1D1D25;
    margin-top: 10px;
}
.event-price{
    color: #1D1D25;
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
}
.event-address{
    color: #707070;
    font-size: 14px;
    font-weight: normal;
    display: flex;
    align-items: center;
    margin-top: 10px;
}
.event-address::before{
    content: url('../images/gray_location_icon.svg');
    width: 12px;
    height: 17px;
    margin-right: 6px;

}
.event-address-1 {
    color: #707070;
    font-size: 16px;
    font-weight: normal;
    display: flex;
    align-items: center;
    margin-top: 14px;
}

.event-address-1::before {
    /* content: url('../images/gray_location_icon.svg'); */
    width: 12px;
    height: 17px;
    margin-right: 6px;

}
.event-action-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 4px;
}
.event-action-wrapper a{
    padding: 7px 7px;
    background-color: #00B4BD;
    color: white;
    font-size: 14px;
    font-weight: bold;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.event-action-wrapper p{
    color: #707070;
    font-size: 14px;
    font-weight: normal;
    display: flex;
    align-items: center;
}
.event-action-wrapper p::before{
    content: url('../images/calendar-event.svg');
    width: 15px;
    height: 17px;
    margin-right: 10px;
    color: red !important;
}
.no-scroll::-webkit-scrollbar{
    display: none;
}
.play-icon{
    color: #02B3BD !important;
    width: 6px;
    height: 12px;
}
.nav-inactive{
    height: 40px;
    width: 142px;
    background-color: transparent;
    border: 1px solid #BBBBBB;
    color: #BBBBBB;
    font-size: 14px;
    font-weight: 600;

}
.nav-active{
    height: 40px;
    width: 142px;
    background-image: linear-gradient(#02B3BD, #00868E);
    border: none !important;
    color: white !important;
    font-size: 14px;
    font-weight: 600;
}
.tabs{
    display: flex;
    column-gap: 10px;
    
}

.cancled-details{
    background-color: #FFE8E9 !important;
    border: 1px solid red;
}
.cancle-button{
    background-color: transparent !important;
    border: 1px solid #FF3F00;
    color: #FF3F00 !important;

}
.create-location{
    display: flex;
    column-gap: 20px;
}
.location-button{
    border: 1px solid #00B4BD !important;
    min-width: 200px;
    height: 40px;
    color: #00B4BD !important;
}
.location-button p{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #00B4BD;
    font-size: 14px;
    font-weight: 600;
}
.location-button p::before{
    content: url('../images/location.svg');
    width: 12px;
    height: 18px;
    margin-right: 0px;
}
.dot{
    margin: 0 4px;
    height: 4px;
    width: 4px;
    border-radius: 2px;
    background-color: #00B4BD;
}
.create-event-button{
    height: 40px;
    width: 180px;
    background-color: #00FFDC;
    color: #21212C !important;
    font-size: 14px;
    font-weight: 600 !important;
    border: none !important;
}

.globalPages_tabWrapper{
    
    min-width: auto;
    /* overflow: auto !important; */
}
.create-event-title{
    color: #1D1D25;
    font-size: 20px;
    font-weight: bold;
}
.ce-heading{
    color: #1D1D25;
    font-size: 20px !important;
    font-weight: bold !important;
    font-family: Nunito;
    margin-bottom: 10px;
}
.ce-input{
    background-color:#FBF8F8;
    border: 1px solid #E1E1E1;
    width: 100%;
    height: 40px;
    display: block;
    border-radius: 10px;
    padding: 0 20px;
    color: #1D1D25 !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    margin-top: 10px;
    margin-bottom: 20px;
}
.ce-input::placeholder{
    color: #BBBBBB;
    
}
.custom-icon-image {
    text-align: center;
}
.ce-label{
    color: #707070;
    font-size: 14px !important;
    font-weight: normal !important;
}
.ce-lg-input{
    height: 80px ;
    display: flex;
    align-items: flex-start ;
    padding-top: 10px;
}
.ce-map{
    height: 80px ;
    padding: 0 !important;
    
}
.ce-center{
    text-align: center !important;
}
.ce-upload{
    height: 130px ;
    border: 2px dashed #00B4BD !important;
    position: relative;
}
.custom-file input{
    width: 100%;
    height: 100%;
    opacity: 0;
}
.custom-icon-image{
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    
}
.cancel{
    background-color: transparent;
    border: 1px solid #00B4BD;
    width: 160px;
    height: 50px;
    border-radius: 25px;
    color: #00B4BD;
    font-size: 14px;
    font-weight: 600;
}
.create{
    border: none;
    width: 160px;
    height: 50px;
    background-color: #00B4BD;
    color: white;
    font-size: 14px;
    font-weight: 600;
    border-radius: 25px;
}
.upload-icon{
    height: 20px;
    width: 20px;

}
.custom-icon-image p{
    color: #BBBBBB;
    font-size: 14px;
    font-weight: 600;
    margin-top: 20px;
}

.img_thumbWrapper.img_thumbWrapperAdj01 > a{
    position: absolute !important;
    left:28px !important;
    top:28px !important;
}
.dot-button{
    position: absolute;
    right: 28px !important;
    top: 28px !important;
}
.thumbImage{
    height: 200px !important;
}
.tooltipInfoWrapper{
    position: absolute;
}
.gbl_thumbWrapper02{
    margin-top: 0 !important;
}
.detail-heading{
    display: flex;
    justify-content: space-between;
}
.career-card-wrapper{
    padding: 20px !important;
}
.uploaded-image{
    position: relative;
}
.uploaded-image img{
    /* width: 100% !important;
    height: 148px !important; */
    object-fit: cover;
    border-radius: 10px;
}
.close-mark{
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: white;
    display: block;
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}
.uimage{
    color: #00B4BD !important;
    font-size: 14px !important;
    font-weight: bold !important;
}
.fo-heading{
    color: #1D1D25;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
}

.search{
    position: absolute;
    right: 10px;
    width: fit-content;
}
.ep-body{
   
    color: #707070;
    font-size: 14px;
    font-weight: normal;
}
.modal-dialog-centered{
    height: fit-content !important;
    top:50% !important;
    transform: translateY(-50% ) !important;
}
.ep-action{
    width: fit-content;
    margin-top: 30px;
    background-color: #00B4BD;
    padding: 14px 34px;
    border-radius: 100px;
    color:white;
     font-size: 16px;
     font-weight: bold !important;
     border: none;
}
.eventHeart{
    width: 34px;
    height: 34px;
    background-color: #F5F5F5;
    border-radius: 10px;
    position: absolute;
    top:10px;
    left: 10px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center ;
}
.triangle-left {
	width: 0;
	height: 0;
	border-top: 5px solid transparent;
	border-right: 8px solid white;
	border-bottom: 5px solid transparent;

}
.right-icon{
    position: absolute;
    top: 70px;
    left:-8px;
}
.right-popup{
    position: absolute;
    top:190px;
    right: -320px;
}
.left-icon{
    transform: rotate(180deg);
    position: absolute;
    top: 70px;
    right:-8px;
	border-right: 8px solid black;
}
.left-popup{
    position: absolute;
    top:192px;
    right: 60px;
} 
.tool-open{
    display: block !important;
}
.tool-close{
    display: none !important;
}
.gm-style-moc{
    border: 1px solid #BBBBBB;
    border-radius: 10px !important;
}
.actAsDiv{
    border: 1px solid #BBBBBB;
    border-radius: 10px !important;
}
.cd-heading{
    color: #1D1D25;
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
}
.cd-by{
    color: #1D1D25;
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
}
.cd-by span{
    text-decoration: underline;
}
.salary{
    display: flex;
    column-gap: 60px;
    margin-top: 10px;
}
.salary .key{
    color: #707070;
    font-size: 16px;
    font-weight: normal;
}
.salary .value{
    color: #21212C;
    font-size: 16px;
    font-weight: 600;
}
.cd-about-heading{
    color: #1D1D25;
    font-size: 16px;
    font-weight: 600;
}
.cd-about-body{
    color: #707070;
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px;
}
.cm-heading{
    color: #1D1D25;
    font-size: 30px;
    font-weight: bold;
}
.cm-input{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-top: 20px;
}
.cm-input label{
    color: #707070;
    font-size: 14px;
    font-weight: normal;
}
.cm-input input{
    height: 50px;
    border: 1px solid #E1E1E1;
    border-radius: 10px;
    font-size: 16px !important;
    font-weight: 600;
    color: #21212C;
    padding:  0 20px;
    background-color: white !important;

}
.cm-input textarea{
    height: 50px;
    border: 1px solid #E1E1E1;
    border-radius: 10px;
    font-size: 16px !important;
    font-weight: 600;
    color: #21212C;
    padding:  10px 20px 0;
    background-color: white !important;

}
.cm-input input::placeholder,.cm-input textarea::placeholder{
    color: #BBBBBB;
}



.bhk-height{
    height: 343px;
   
}

.continue-button{
    text-align: center !important;
    margin-left: 50px;
}

