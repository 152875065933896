// .offcanvas.show {
//     margin: 20px !important;
//     border-radius: 24px !important;
//     padding: 20px !important;
//     width: 360px;
// }

.chatListTextcontent {
    margin-left: 14px;
}

.chat_sidebarWrapper .chat_tabList ul::-webkit-scrollbar {
    display: none;
}

.connection-card-wrapper {
    background-color: white;
    padding: 20px;
    border-radius: 24px !important;
    overflow: auto;
}

.connectionSwip-card .connection-top-section {
    border: 0 !important;
}

.connection-top-section {
    border: 0px !important;
}

.chat_sidebarWrapper {
    height: 100%;
}

.custom-wrapper-height {
    height: calc(100vh - 138px);
}

.chat_sidebarWrapper .chat_tabList {
    height: 100%;
    overflow: auto;
}

.chat_sidebarWrapper .chat_tabList::-webkit-scrollbar {
    display: none;
}

// .connectionSwip-card {
//     margin-top: 10px !important;
//     margin-left: 6px !important;
//     margin-right: 6px !important;
//     width: 32%;

// }

.card-t {
    font-size: 18px;
    font-weight: bold;
}

.connectionSwip-card .connection-top-section {
    box-shadow: none;
}

.image-name-con {
    padding: 8px !important;

}

.connectionSwip-card .connection-top-section .image-name-con {
    justify-content: start !important;
}

.connectionSwip-card .connection-top-section .image-name-con img {
    height: 92px !important;
    width: 96px !important;
}

.gblThumb_outerWrapper {
    // height: calc(100vh - 199.5px) !important;
}

.connection-card-wrapper {
    height: calc(86vh - 138px);
    overflow: auto;
   

}

.create_screenWrapper {
    // height: calc(100vh - 138px) !important;
    overflow: auto;
}

.formsscreenAdj01 {
    height: auto !important;
}

.forumListWrapper {
    height: 59vh;
    overflow: auto;
}

.forumListWrapper .flInnerItemsWrapper .fl_section03 .dropdown button {

    padding: 4px 15px !important;
}

.upp_selectWrapper select {
    background-color: white;
}

.upp_selectWrapper input {
    background-color: white;
}

.cp-select {
    background-color: #fbf8f8 !important;
}

.upp_selectWrapper input {
    background-color: #fbf8f8 !important;
}

.mtop {
    margin-top: 0px !important;
}

.btn-tertiary {
    margin-bottom: 4px;
}

.create_screenWrapper .profile_modal_contentWrapper .uploadPPWrapper .uploadPP_input .input-file+.js-labelFile {
    padding: 57px 10px !important;
}

.reduce-margin {
    margin-top: 3px !important;
}

.globalPages_tabWrapper .gb_tabs a.ct_active {
    border: none;
    background-color: #00FFDC;
    color: black;
}

.ct-all-forms {
    background-image: linear-gradient(#02B3BD, #00868E);
    color: white !important;
    border: none !important;
}

.select-width {
    width: 100% !important;
    padding-right: 10px !important;
}

.forumListWrapper {
    height: 58.5vh;
}

.gblThumb_outerWrapper {
    // height: calc(100vh - 203.5px) !important;
}

.fs {
    background-color: #fbf8f8 !important;
    background: #fbf8f8 !important;
}

.img_thumbWrapper.img_thumbWrapperAdj01>a {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.create_screenWrapper .profile_modal_contentWrapper .profile_form .uploadPP_inputTextRow {
    margin: 0 0 4px 0;
}

.forumListWrapper {
    // height: 50vh !important;
}

.inner-wrapper {
    overflow: auto;
    height: 100%;
    width: 100%;

}

.inner-wrapper::-webkit-scrollbar {
    display: none;
}



.forumListWrapper::-webkit-scrollbar {
    display: none;
}

.forumListWrapper .flInnerItemsWrapper .fl_section03 .dropdown button:focus,
.create_screenWrapper.formsscreenAdj01 select:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

.create_screenWrapper.formsscreenAdj01 select {
    border: 1px solid #e1e1e1 !important;
    border-radius: 8px;
}

.ct_active-forum {
    border: none !important;
    background-color: #00FFDC;
    color: black;
    font-weight: bold;
}

.create_screenWrapper .profile_modal_contentWrapper .uploadPPWrapper .uploadPP_input .input-file+.js-labelFile {
    padding: 66px 10px !important;
}

.create_screenWrapper.createforumAdj01 textarea {
    height: 214px !important;
    background-color: #F5F5F5 !important;
    border: 1px solid #E1E1E1 !important;
}

.create_screenWrapper.createforumAdj01::-webkit-scrollbar {
    display: none;
}

.friendSectionWrapper .friendSectionScroll::-webkit-scrollbar {
    display: none;
}

.friendSectionWrapper .friendSectionScroll .friendSectionItem {
    margin: 0px 0px 20px 0 !important;
}

.create_screenWrapper::-webkit-scrollbar {
    display: none !important;
}

.create_screenWrapper .profile_modal_contentWrapper .cp_uploadImgHolder img {
    // height: 110px !important;
    
}
.create_screenWrapper .profile_modal_contentWrapper .cp_uploadImgHolder-img img {
    height: 110px !important;
}
.create_screenWrapper .profile_modal_contentWrapper .cp_uploadImgHolder-img img {
    height: 110px !important;
}

.create_screenWrapper .profile_modal_contentWrapper .uploadPPWrapper .uploadPP_input .input-file+.js-labelFile {
    padding: 0 !important;
}

.create_screenWrapper::-webkit-scrollbar {
    display: none;
}

.scroll-hidden::-webkit-scrollbar {
    display: none;
}

.cx-main::-webkit-scrollbar {
    display: none;
    height: calc(100vh - 138px);
}

.create_screenWrapper .cc_uploadRow {
    margin-top: 15px !important;
}

.dis {
    border-color: #00B4BD !important;
    color: #00B4BD !important;
    background-color: white !important;

}

.content-main {
    // height: calc(100vh - 138px);
}

.modal-content {
    width: 100%;
    margin: 0px 0px;
}

.upp_selectWrapper input {

    text-align: center;
    background-color: #fbf8f8 !important;
}

.gbl_thumbWrapper .gbl_thumbContent {
    // padding: 14px !important;
}

.modal-header {
    // padding: 0 0 20px 14px;
}

.modal-header .btn-close {
    background-color: white;

}


.connection-card-wrapper::-webkit-scrollbar {
    display: none;
}

.connectionSwip-card .connection-top-section .image-name-con img {
    margin-right: 16px !important;
}

.connectionSwip-card {
    height: fit-content;
}

.sli-noti {
    margin-left: 20px;
}

.offcanvas-body::-webkit-scrollbar {
    display: none;
    padding: 0px !important;
}

// .offcanvas.show {
//     width: 300px !important;
// }

.profile_header .ph_section01 .ph_profilePhoto {

    border: none !important;
}

.header-container {
    padding: 0 20px;
}