.cards-housing {
  width: calc(33.33% - 20px);
  max-width:330px ;
  margin-bottom: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  overflow: hidden;
  height: 250px;
}

@media screen and (max-width: 1120px) {
  .cards-housing {
    width: calc(50% - 20px);
  }
}

@media screen and (max-width: 840px) {
  .cards-housing {
    width: calc(100% - 20px);
  }
}


/* Image Slider CSS */

.slider-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 320px;
}

.main-image {
  position: relative;
  width: 600px;
  height: 330px;
  /* margin-bottom: 10px; */
}

.main-image img {
  width: 100%;
  height: 250px; 
  object-fit: cover;
  margin-bottom: 10px;
}

/* .nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
} */

.nav {
  position: absolute;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
  border-radius: 5px; /* Adds rounded buttons */
  transition: background-color 0.3s ease; /* Smooth hover transition */
}

.btns{
  padding: 5px;
  background-color: #f44336;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition */
}

.btns.toolbar:hover {
  background-color: #d32f2f; /* Darker shade on hover */
}
.nav:hover {
  background-color: rgba(0, 0, 0, 0.7); /* Darken background on hover */
}

.nav.prev {
  top:120px;
  left: 10px;
}

.nav.next {
  top:120px;
  right: 10px;
}

.thumbnail-container {
  margin-top: 130px;
  display: flex;
  gap: 10px;
}

.thumbnail {
  width: 100px;
  height: 75px;
  object-fit: cover;
  cursor: pointer;
  opacity: 0.6;
}

.thumbnail.active {
  opacity: 1;
  border: 2px solid #333;
}

/* Interested User Btn Manage */
.buttonInterestedContainer {
  position: absolute;
  bottom: 10px;
  left: 20px;
}

.buttonInterested {
  border: none;
  border-radius: 10px;
  background-color: teal;
  width: 180px;
  height: 30px;
  transition: background-color 0.3s;
}

.buttonInterested:hover {
  background-color: lightseagreen;
}

