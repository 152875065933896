.upload-image {
    cursor: pointer;
    margin-top: 20px;
    color: darkblue;
    background: #00b4bd;
    border-radius: 20px;
    cursor: pointer;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .default-upload-image {
    margin-top: 100%;
    margin-left: 15%;
    cursor: pointer;
  }
  
  .company-logo {
    width: 120px;
    height: 120px;
    border-radius: 60px;
  }
  
  .cx-pro-cam img{
    height: auto;
    width: 139px;
  }
  .custom-label{
    background: #00b4bd;
    border-radius: 20px;
    cursor: pointer;
    overflow: hidden;
    padding: 8px 10px 8px 38px;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    color: white !important;
    text-decoration: none !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
} 
.custom_data{
  border: 2px solid #00b4bd;
    border-radius: 36px !important;
    display: block;
    margin: 0 auto;
    padding: 4px;

    height: 129px;
    width: 129px;
    margin: 0px;
    margin: 0px;
    object-fit: cover

}