.card {
  width: calc(33.33% - 20px);
  margin-bottom: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  overflow: hidden;
  height:220px;
}

@media screen and (max-width: 1024px) {
  .card {
    width: calc(50% - 20px);
  }
}

@media screen and (max-width: 600px) {
  .card {
    width: calc(100% - 20px);
  }
}

.image-container {
  position: relative;
}

.main-image {
  width: 100%;
  height: 125px;
  border-radius: 15px 15px 0 0;
}

.thumbnail-container {
  display: flex;
  padding: 10px;
}

.thumbnail {
  width: 50%;
  height: 60px;
  border-radius: 10px;
  margin-right: 5px;
}

.content {
  padding: 20px;
}

@media screen and (max-width: 600px) {
  .thumbnail-container {
    padding: 5px;
  }

  .thumbnail {
    width: 100%;
    margin-right: 0;
  }
}
