.card {
    width: 100%;
    // max-width:330px ;
    // margin-bottom: 30px;
    // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    // border-radius: 15px;
    // overflow: hidden;
    // height:240px;
  }

.cardSize {
    height: 278.5px;
    // width: 244.5px;
    width: 100%;
    background-color: #EFEFEF;
    border: none;
    border-radius: 10px;
    opacity: 1;
    margin: 10px auto;
}

.img-responsive {
    height: 40.61px;
    width: 45.69px;
}

.cardText {
    align-items: center;
    margin-top: 90px;
    padding: 10px;
}

.text-center {
    margin-top: 16px;
}
.offers-gb-tabs{
    width: 130px;
}
.globalPages_tabWrapper .gb_tabs a.ct_active{
    background-image: linear-gradient(#02B3BD, #00868E);
    color: white !important;
    border: none !important;
}
.globalPages_tabWrapper .gb_tabs-btn a.ct_active {
    background-image: linear-gradient(#02B3BD, #00868E);
    color: white !important;
    border: none !important;
}