 *::-webkit-scrollbar{
   display: none;
 }
 .fcanvas{
    margin: 20px !important;
    border-radius: 24px !important;
    padding: 20px !important;
    width: 338px !important;

  }

  .chatListTextcontent{
    margin-left: 14px;
  }
  .chat_sidebarWrapper .chat_tabList ul::-webkit-scrollbar{
    display: none;
  }
  .connection-card-wrapper{
    background-color: white;
    padding: 20px;
    border-radius: 24px !important;
    overflow: auto  ;
  }
  .connectionSwip-card .connection-top-section{
    border: 0 !important;
  }
  .connection-top-section{
    border: 0px !important;
  }
  .chat_sidebarWrapper{
    height: 100%  ;
  }
  .custom-wrapper-height{
    height: calc(100vh - 138px);
  }
  .chat_sidebarWrapper .chat_tabList{
    height: 80%;
    overflow: auto;
  }
  .chat_sidebarWrapper .chat_tabList::-webkit-scrollbar{
    display: none;
  }

  .card-t{
    font-size: 18px;
    font-weight: bold;
  }
  .connectionSwip-card .connection-top-section{
    box-shadow: none;
  }
  .image-name-con{
    padding: 8px !important;
  
  }
  .connectionSwip-card .connection-top-section .image-name-con{
    justify-content: start !important;
  }
  .connectionSwip-card .connection-top-section .image-name-con img{
    height: 92px !important;
    width:96px !important;
  }
  .gblThumb_outerWrapper{
    /* height: calc(100vh - 199.5px) !important; */
  }
  .connection-card-wrapper{
    height: calc(100vh - 138px);
    overflow: auto;
  
  }
  .create_screenWrapper{
    height: calc(100vh - 138px) 
    !important;
    overflow: auto;
  }
  .formsscreenAdj01{
    height: auto 
    !important;
  }
  .forumListWrapper{
    height: 59vh;
    overflow: auto;
  }
  .forumListWrapper .flInnerItemsWrapper .fl_section03 .dropdown button{
   
    padding: 4px 15px !important;
  }
  .upp_selectWrapper select{
    background-color: white;
  }
  .upp_selectWrapper input{
    background-color: white;
  }
  .cp-select{
    background-color: #fbf8f8 !important;
  }
  .upp_selectWrapper input{
    background-color: #fbf8f8 !important;
  }
  .mtop{
    margin-top: 0px !important;
  }
  .btn-tertiary{
    margin-bottom: 4px;
  }
  .create_screenWrapper .profile_modal_contentWrapper .uploadPPWrapper .uploadPP_input .input-file + .js-labelFile{
    padding: 57px 10px !important;
  }
  .reduce-margin{
    margin-top: 3px !important;
  }
  .globalPages_tabWrapper .gb_tabs a.ct_active{
    border: none;
    background-color: #00FFDC;
    color: black;
  }
  .ct-all-forms{
    background-image: linear-gradient(#02B3BD, #00868E);
    color: white !important;
    border: none !important;
    width: 142px !important;
    height: 40px !important;
    font-weight: 600;
    font-size: 14px;
  }
  .inActiveButton{
 
    color: #BBBBBB !important;
    border: 1px solid #BBBBBB !important;
    width: 142px !important;
    height: 40px !important;
    font-weight: 600;
    font-size: 14px;
  }
  .select-width{
    width: 100% !important;
    padding-right: 10px !important;
  }
  .forumListWrapper{
    height: 58.5vh;
  }
  
  .gblThumb_outerWrapper {
    /* height: calc(100vh - 203.5px) !important; */
  }
  
  .fs{
    background-color: #fbf8f8!important;
    background: #fbf8f8 !important;
  }
  .img_thumbWrapper.img_thumbWrapperAdj01 > a{
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .create_screenWrapper .profile_modal_contentWrapper .profile_form .uploadPP_inputTextRow{
    margin: 0 0 4px 0;
  }
  /* .forumListWrapperi{
    height: calc(100vh - 296px) !important;
  }
  .forumListWrapper{
    height: 50vh !important;
  } */
  .inner-wrapper{
    overflow: auto;
    height: 100%;
    width: 100%;

  }
  .inner-wrapper::-webkit-scrollbar{
    display: none;
  }
  .form-select{
    background-image: url('../images/drop_down.svg');
    background-size: 8px !important;
    
  }
  .form-select-2{
    background-position: right 20px center;
  }
 

  .forumListWrapper::-webkit-scrollbar{
    display: none;
  }
  .forumListWrapper .flInnerItemsWrapper .fl_section03 .dropdown button:focus,
  .create_screenWrapper.formsscreenAdj01 select:focus
  {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }
  .create_screenWrapper.formsscreenAdj01 select{
    border: 1px solid #e1e1e1 !important;
    border-radius: 8px;
  }
  .ct_active-forum{
    border: none !important;
    background-color: #00FFDC;
    color: black;
    font-weight: bold;
  }
  .create_screenWrapper .profile_modal_contentWrapper .uploadPPWrapper .uploadPP_input .input-file + .js-labelFile{
    padding: 66px 10px !important;
  }
  .create_screenWrapper.createforumAdj01 textarea{
    height: 214px !important;
    background-color: #F5F5F5 !important;
    border: 1px solid #E1E1E1 !important;
  }
  .create_screenWrapper.createforumAdj01::-webkit-scrollbar{
    display: none;
  }
  .friendSectionWrapper .friendSectionScroll::-webkit-scrollbar{
    display: none;
  }
  .friendSectionWrapper .friendSectionScroll .friendSectionItem{
    margin: 0px 0px 20px 0 !important;
  }
  .create_screenWrapper::-webkit-scrollbar{
    display: none !important;
  }
  .create_screenWrapper .profile_modal_contentWrapper .cp_uploadImgHolder img{
    height: 148px !important;
    width: 228px !important;
    border-radius: 10px !important;
    
  }
  .create_screenWrapper .profile_modal_contentWrapper .uploadPPWrapper .uploadPP_input .input-file + .js-labelFile{
    padding: 0 !important;
  }
  .create_screenWrapper::-webkit-scrollbar{
    display: none;
  }
  .scroll-hidden::-webkit-scrollbar{
    display: none ;
  }
.cx-main::-webkit-scrollbar{
  display: none;
  height: calc(100vh - 138px);
}
.create_screenWrapper .cc_uploadRow{
  margin-top: 31px !important;
}
.dis{
  border-color: #00B4BD !important;
  color: #00B4BD !important;
  background-color: white !important;

}
.content-main{
  /* height: calc(100vh - 138px); */
}
.modal-content{
  width: 100%;
  margin: 0px 0px ;
}
.mod-con{
  /* width: 393px !important; */
}
.upp_selectWrapper input{
  
  text-align: center;
  background-color: #fbf8f8 !important;
}
.gbl_thumbWrapper .gbl_thumbContent{
  padding: 14px ;
}
.modal-header{
/* padding: 0 0 20px 14px; */
padding: 0 0  20px 0;
}

.modal-header .btn-close{
  background-color: white;
 
}


.connection-card-wrapper::-webkit-scrollbar{
  display: none;
}
.connectionSwip-card .connection-top-section .image-name-con img{
  margin-right: 16px !important;
}
.connectionSwip-card {
  height: fit-content;
}
.sli-noti{
  margin-left: 20px;
}
.offcanvas-body::-webkit-scrollbar{
  display: none;
  padding: 0px !important;
}

.profile_header .ph_section01 .ph_profilePhoto{

  border: none !important;
}
.header-container{
  padding:0  20px;
}
.pencil-link{
  width: 34px !important;
  height: 34px !important;
 
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  justify-content: start;
  position: absolute;
  top: 20px;
  right: 20px;
}
.profile_header .ph_section02 a{
  padding: 0;
}
.f-count{
    margin-top: 20px !important;
    margin-right: 16px;
    padding: 0 !important;
}
.create_screenWrapper .profile_modal_contentWrapper .uploadPPWrapper .uploadPP_imgHolder img{
  border-radius: 36px !important;
  /* margin-top: 8px !important; */
}
.place-fw{
  font-weight: 600 !important;
  font-size: 14px !important;
  font-family: Nunito !important;
color: #707070;
font-weight: 600 !important;
}
.place-fw::placeholder{
  font-weight: normal;
  color: #BBBBBB;
}
.intrest-list{
  /* display: flex; */
  /* justify-content: space-between; */
  padding: 0px !important;
  margin-top: 20px !important;
}
.intrest-list li{

  padding: 0px !important; 
  display: flex;
  justify-content: start;
}
.intrest-list li label{
  font-size: 14px;
  border-radius: 12px;
  padding: 10px !important;
}
.btn-check{
  font-size: 14px;
  border-radius: 12px !important; 
}
.create_screenWrapper .profile_modal_contentWrapper .profile_tagWrapper ul li{
  margin: 0px !important;
}
.create_screenControls{
  margin-top: 56px !important;
}
.create_screenControls a{
  border-radius: 100px !important;
}

.btn-check:checked + .btn-outline-danger{
  background-color: white;
  border-color:#00b4bd;
  border-radius: 12px !important;
}
.fName{
  font-weight: 600 !important;
  font-size: 14px !important;
  font-family: Nunito !important;
color: #1D1D25;
}
.ofImage{
  width: 40px;
  height: 40px !important;

}
.frnd-botm{
  margin-top: 20px !important;
}
.sLocation{
  font-weight: normal !important;
  font-size: 16px !important;
  font-family: Nunito !important;
color: #FFFFFF;
padding: 0 !important;
margin: 0 !important;
margin-left: 88px !important;
text-decoration: none !important;
color: white !important;
}
.sLocation:hover{
  color: white !important;
}
.friendSectionWrapper .friendSectionScroll .friendSectionItem .fs_details .fs_control ul{
  margin-top: 10px;
  
}
.friendSectionWrapper .friendSectionScroll .friendSectionItem .fs_details p{
  margin-top: 2px !important;
}
.friendSectionWrapper .friendSectionScroll .friendSectionItem{
  padding: 10px 10px !important;
  margin-bottom: 24px !important;
}
.friendSectionWrapper .friendSectionScroll{
  height: 352px !important;
}
.form-label{
  font-weight: normal !important;
  font-size: 16px !important;
  font-family: Nunito !important;
color: #707070;

}
.intrest-list li label{
  font-weight: normal !important;
  font-size: 16px !important;
  font-family: Nunito !important;
color: #21212C;
font-size: 16px;
border: 1px solid #BBBBBB;
width: 95px;
    height: 40px;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 15px !important;
}
.profile_tagWrapperf h1{
  font-weight: 600 !important;
  font-size: 16px !important;
  font-family: Nunito !important;
color: #1D1D25;

}
.create_screenWrapper .profile_modal_contentWrapper .profile_form .uploadPP_inputTextRow{
  margin: 0 0 18px 0 !important;
}
.profile-headings{
  font-weight: 600 !important;
  font-size: 16px !important;
  font-family: Nunito !important;
color: #1D1D25;

}
.aboutSection .abt_labelSection h1{
  margin: 20px 0 10px 0 !important;
}
.freq{
  font: normal normal 600 16px/22px Nunito;
letter-spacing: 0px;  
color: #1D1D25;
font-weight: 600px !important;
font-size: 16px !important;
}
.friendSectionWrapper .fs_header h1{
  font-weight: 600 !important;
  font-size: 16px !important;
  font-family: Nunito !important;
  color: #1D1D25;

}
.profile_header .ph_section01 .ph_profilePhoto img{
  border-radius: 12px !important;
}
.friendSectionWrapper .friendSectionScroll .friendSectionItem .fs_details h2{
  font-weight: 600 !important;
  font-size: 14px !important;
  font-family: Nunito !important;
color: #00B4BD;


}
.friendSectionWrapper .friendSectionScroll .friendSectionItem .fs_details p{
  font-weight: 600 !important;
font-size: 14px !important;
font-family: Nunito !important;

color: #707070 !important;
}
.friendSectionWrapper .friendSectionScroll .friendSectionItem .fs_details .fs_control ul li a.primary{
  font-weight: 600 !important;
  font-size: 14px !important;
  font-family: Nunito !important;

color: #FFFFFF;
  
}
.deleteButton{
  color: #BBBBBB !important;
  border: 1px solid #BBBBBB !important  ;
}
.create_screenWrapper .profile_modal_contentWrapper .profile_tagWrapper h1{
  font-weight: 600 !important;
  font-size: 16px !important;
  font-family: Nunito !important;

color: #1D1D25;
font-weight: 600 !important;
}
.aboutSection .abt_paraSection p{
  /* font-weight: 600 !important;
  font-size: 14px !important;
  font-family: Nunito !important;
color: #707070 !important; */
}
.aboutSection .abt_labelSection ul li a{
  padding: 16px 20px !important;
}
.profile_header .ph_section01 .ph_profilePhoto_details ul > li h2{
  margin-top: 12px;
  font-weight: 600 !important;
  font-size: 14px !important;
  font-family: Nunito !important;
  color: #FFFFFF;

}
.profile_header .ph_section01 .ph_profilePhoto_details ul > li h1{
  font-weight: 600 !important;
  font-size: 20px !important;
  font-family: Nunito !important;
color: #00B4BD;
font-size: 20px !important;
}
.aboutSection .abt_paraSection{
  height: 138px !important;
  overflow: auto;
}
.profile_header .ph_section01 .ph_profilePhoto_details ul > li ul > li p{
 
  font-weight: 600 !important;
font-size: 14px !important;
font-family: Nunito !important;
color: #FFFFFF;
}
.intrest-list{
  margin: 0px !important;
}
.create_screenWrapper .profile_modal_contentWrapper .profile_tagWrapper h1{
  margin-bottom: 8px !important;
}
.create_screenWrapper .profile_modal_contentWrapper .profile_form .uploadPP_inputTextRow textarea{
 
  font-weight: 600 !important;
font-size: 16px !important;
font-family: Nunito !important;
letter-spacing: 0px;
color: #1D1D25;
}
.create_screenWrapper .profile_modal_contentWrapper .profile_form .uploadPP_inputTextRow textarea:focus{
  background-color: white;
  color: #1D1D25;
}
.friendSectionWrapper .friendSectionScroll .friendSectionItem .fs_details .fs_control ul li a.primary{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px !important;
  width: 100px !important;
}
/* comment */
/* comment */
/* comment */
/* comment */
.deleteButton{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px !important;
  width: 100px !important;
}
/* minor-change */
.profile_header .ph_section02 .ph_userSection h3{
color: #F5F5F5;
font-weight: 600 !important;
font-size: 16px !important;
font-family: Nunito !important;
}
.fcanvas.show{
  width: 360px !important;
}
.profile_header .ph_section01 .ph_profilePhoto_details ul > li{
  padding: 0px !important;
  margin-right: 10px;
}
.aboutSection{
  margin-top: 20px;
}
.profile_header .ph_section01 .ph_profilePhoto_details ul > li ul{
  margin-top: 13px !important;
}
.friendSectionWrapper .friendSectionScroll .friendSectionItem .fs_details .fs_control ul{
  margin-top: 5px !important;
}
.friendSectionWrapper .friendSectionScroll .friendSectionItem .fs_details p{
  color: #707070;
  font-weight: normal;
  font-size: 14px;
}
#hif::placeholder,#fif::placeholder{
  color: #BBBBBB;
}
.create_screenWrapper.formsscreenAdj01{
  padding: 0;
}
.create_screenWrapper{

  /* border-radius: 0 !important; */
}
.forumListWrapper .flInnerItemsWrapper .fl_section02 p{
  padding: 10px 0 20px 0px !important;
  letter-spacing: 0 !important;
}
.forumListWrapper .flInnerItemsWrapper .fl_section03 .dropdown button{
  height: 34px;
  width: 34px;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.forumListWrapper .flInnerItemsWrapper .fl_section02 .fl_controlsWrapper ul li h4{
  letter-spacing: 0 !important;
}
.aboutSection{
  /* background-color: black !important; */
  max-height: fit-content !important;
}
.friendSectionWrapper{
  display: flex;
  flex-direction: column;
  overflow:unset;
}
.friendSectionWrapper .friendSectionScroll{
  height: 504px;
}
.friendSectionScroll{
  flex:1;
}
.aboutSection{
  height: fit-content !important;
}
.friendSectionScroll:last-child{
  margin-bottom: 0px !important;
}
.friendSectionWrapper .friendSectionScroll .friendSectionItem:last-child{
  margin-bottom: 0px !important;
}
.profile_header{
  height: 166px !important;
}
.navbar>.container, .navbar>.container-fluid, .navbar>.container-lg, .navbar>.container-md, .navbar>.container-sm, .navbar>.container-xl, .navbar>.container-xxl{
  /* height: 91px !important; */
  height: fit-content !important;
  /* min-height: 91px !important; */
  padding-top: 24px;
  padding-bottom: 24px;
}
/* status */
.friendSectionWrapper{
  /* height: fit-content !important; */
  min-height: calc(100vh - 345px) !important;
}
.mywrapper{
  border-radius: 20px !important;
  height: fit-content !important;
}
.create_screenControls{
  margin:20px 0 10px 0 !important ;
}
.create_screenWrapper .create_screenControls a{
  width: 158px !important;
  height: 50px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.cx-header ul li:last-child > a{
  padding-top: 0px !important;
}
.cx-header ul li:last-child > a{
  /* margin: 0px !important;  */
  padding: 0px !important;
}
.sLocation{
  margin-left: 140px !important;
}
.of-head{
  display: flex !important;
  justify-content: space-between !important;
}
.f-custom-wrapper{
  display: flex;
}
.cForum{
  color: #1D1D25;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0px;
  padding: 0 !important;
}
.create_screenWrapper.createforumAdj01{
  border-radius: 20px !important;
}
.create_screenWrapper .modal-header{
  padding: 0 !important;
}
.create_screenWrapper .profile_modal_contentWrapper .profile_form .uploadPP_inputTextRow textarea{
  /* margin-top: 8px !important; */
  padding: 10px 10px !important;
  /* height: 272px !important; */
}
.create_screenWrapper .profile_modal_contentWrapper .profile_form .uploadPP_inputTextRow textarea::placeholder{
  color: #BBBBBB;
  font-size: 16px;
  font-weight: 600;
}
.create_screenWrapper .cc_uploadRow{
  margin: 0 !important;
}
.create_screenWrapper .profile_modal_contentWrapper .uploadPPWrapper .uploadPP_input .input-file + .js-labelFile{
  height: 148px !important;
  width: 148px !important;
  border: 2px dashed #00B4BD !important;
  border-radius: 10px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  background-color: #F5F5F5 !important;
  margin-right: 20px !important;
}
.create_screenWrapper .profile_modal_contentWrapper .uploadPPWrapper .uploadPP_input .input-file + .js-labelFile span{
  color: #00B4BD !important;
  font-size: 14px !important;
  font-weight: bold !important;
  margin-top: 14px;
}
.create_screenWrapper .profile_modal_contentWrapper .uploadPPWrapper .uploadPP_input .input-file + .js-labelFile::before{
  width: 24px !important;
  height: 24px !important;
}
.cp_uploadImgHolder{
  width: 228px !important;
  height: 148px !important;
  position: relative;
}
.create_screenWrapper .profile_modal_contentWrapper .cp_uploadImgHolder a{
  top: 10px;
  right: 10px !important;
  position: absolute;
  border-radius: 10px !important;
  cursor:pointer;
  margin: 0 !important;
}

.create_screenWrapper .profile_modal_contentWrapper .cp_uploadImgHolder img{
  height: 100% !important; 
  width: 100% !important;
  object-fit: cover !important;
}
.forum-controls{
  display: flex;
  justify-content: center;
  margin-top: 30px !important;
  margin-bottom: 10px !important;
}
.forum-controls  a{
  width: 158px !important;
  height: 50px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.forum-controls a{
  border-radius: 100px !important;
  font-size: 14px !important;
  font-weight: 600 !important ;
  font-family: Nunito;
}
.cc_uploadRow{
  column-gap: 20px !important;
}
.forumsSearchWrapper{
  position:relative;

}
.forumsSearchWrapper button{
  position: absolute;
  top: 8px;
  right: 20 px;
}
.gbl_thumbWrapper .gbl_thumbContent .jobImgThumbWrapper .jiInnerWrapper .jii_wrapper .jii_TextContent h1{
  font-weight: 600 !important;
  margin-bottom: 10px;
}
.gbl_thumbWrapper .gbl_thumbContent .jobImgThumbWrapper .jiInnerWrapper .jii_wrapper .jii_TextContent p{
  font-weight: 600 !important;
}
.career-desc{
  color:#707070;
  font-size: 14px;
  font-weight: normal !important;
  /* margin-top: 10px !important; */
}
.dotsBtn{
  background-color: white;
  height: 34px;
  width: 34px;
  border-radius: 10px;
  /* position: absolute;
  top: 10px;
  right: 10px; */
}
.dButton{
  position: absolute;
  right: 10px;
  top: 10px;
}
.jobbtn{
  width: fit-content !important;
  padding: 8px 18px !important;
  color: #FFFFFF;
  font-size: 14px !important;
  font-weight: bold !important;
  display: flex;
  justify-content: center !important;
  text-align: center;
}
.jobPara{
  color: #1D1D25;
  font-size: 16px !important;
  font-weight: bold;
}
.nav-link{
  margin-left: 0 !important;
}
.setabtmain .mainabt1 p{
  text-align: center;
}
.filter-ic-btn{
  background-color: transparent;
}
.icon-div{
  width: fit-content;
}
.up-img-wrapper{
  display: flex;
}
.cp_uploadImgHolder{
  height: 148px !important;
}