#main-chat {
    margin-top: 2rem !important;
    max-height: calc(100vh - 140px) !important;

    // #Frinds_Chat_section {
    //     .chat-user-lists {
    //         height: calc(100vh - 229px) !important;
    //     }

    //     .nav {
    //         padding-bottom: 0 !important;
    //         border-bottom: none !important;
    //     }
    // }

    .left-chat-section {
        padding-bottom: 10px !important;
        border-radius: 10px !important;
        background-color: #fff !important;
        width: 30%;
        height: fit-content !important;

        ::-webkit-scrollbar {
            display: none !important;
        }

        #customise_intrest {
            height: calc(100vh - 228px) !important;
        }

        .chat-user-lists {
            height: calc(100vh - 294px) !important;
            overflow-y: auto !important;

        }

        .left-heading-chat {
            margin-bottom: 20px !important;
            border-bottom: 1px solid #F6EFE9 !important;
            padding: 20px 0 !important;

            .left-head {
                label {
                    font-weight: 600 !important;
                    font-size: 18px !important;
                    line-height: 27px !important;
                    /* identical to box height */

                    color: rgba(17, 17, 17, 0.8) !important;
                }
            }

            .right-icon {}
        }

        .pinned-section {
            display: flex !important;
            justify-content: space-between !important;

            .left {
                label {
                    font-weight: 400 !important;
                    font-size: 12px !important;
                    line-height: 18px !important;
                    /* identical to box height */

                    color: rgba(17, 17, 17, 0.5) !important;
                }
            }
        }

        .user-chat-tab {
            display: flex !important;
            position: relative !important;
            width: 100% !important;

            .left-profile-pic {
                display: flex !important;
                position: relative !important;

                a {
                    img {
                        margin-right: 10px !important;
                        height: 40px !important;
                        width: 40px !important;
                        border-radius: 30px !important;
                    }
                }

                .indication-img {
                    position: absolute !important;
                    bottom: 2px !important;
                    right: 8px !important;
                }
            }

            .right-name-date {
                .top-section {
                    display: flex !important;
                    justify-content: space-between !important;
                    align-items: center !important;

                    .date {
                        position: absolute !important;
                        right: 0 !important;

                        label {
                            font-weight: 400 !important;
                            font-size: 10px !important;
                            line-height: 15px !important;
                            color: #0c0c0c !important;
                        }
                    }

                    .name {
                        label {
                            font-weight: 600 !important;
                            font-size: 14px !important;
                            line-height: 21px !important;

                            color: #000000 !important;
                        }
                    }
                }

                .bottom-section {
                    label {
                        font-weight: 400 !important;
                        font-size: 10px !important;
                        line-height: 15px !important;
                        color: #131212 !important;
                    }
                }
            }
        }

        .nav-pills .nav-link.active {
            background: #e4e1e1 !important;

            .user-chat-tab .right-name-date .top-section .date label {
                color: #0a0908 !important;
            }

            .user-chat-tab .right-name-date .bottom-section label {
                color: #0f0f0f !important;
            }

            .user-chat-tab .right-name-date .top-section .name label {
                color: #141414 !important;
            }
        }

        .nav-pills .nav-link {
            border-radius: 10px !important;
            background-color: transparent !important;
            padding: 10px !important;
        }

        .nav {
            // padding-bottom: 40px !important;
            // border-bottom: 1px solid rgba(17, 17, 17, 0.2) !important;
        }

        #invite-people {
            padding-top: 10px !important;
        }
    }

    .right-chat-section {
        // position: relative !important;
        background: #ffffff !important;
        border-radius: 20px !important;
        width: calc(69% - 20px) !important;
        padding: 0 1rem 1rem 1rem !important;
        margin-top: -70px;

        ::-webkit-scrollbar {
            display: none !important;
        }

        .heading-chat-section {
            display: flex !important;
            justify-content: space-between !important;
            align-items: center !important;
            padding: 20px 0 15px 0 !important;
            border-bottom: 1px solid #F6EFE9 !important;

            .left {
                display: flex !important;
                align-items: center !important;

                .name {
                    padding-left: 10px !important;

                    label {
                        font-weight: 600 !important;
                        font-size: 18px !important;
                        line-height: 27px !important;
                        /* identical to box height */

                        color: rgba(17, 17, 17, 0.8) !important;
                    }
                }

                .left-profile-pic {
                    position: relative !important;
                    height: 40px !important;
                    width: 40px !important;

                    a {
                        img {
                            margin-right: 10px !important;
                            height: 40px;
                            width: 40px;
                            border-radius: 50% !important;
                        }
                    }

                    .indication-img {
                        position: absolute !important;
                        bottom: -9px !important;
                        right: -1px !important;
                    }
                }
            }

            .right {
                display: flex !important;
                align-items: center !important;

                .right-icon-chat {
                    margin-left: 15px !important;
                    display: inline-block !important;
                    height: 40px !important;
                    width: 40px !important;
                    background-color: #fff !important;
                    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.03),
                        0px 7px 25px rgba(42, 139, 242, 0.03),
                        0px 5px 25px rgba(42, 139, 242, 0.07) !important;
                    border-radius: 50% !important;
                    display: flex !important;
                    align-items: center !important;
                    justify-content: center !important;
                }
            }
        }
    }

    p {
        margin-bottom: 0 !important;
    }

    #customWidthForGroups {
        height: calc(100vh - 381px) !important;

    }

    .chatting-section {
        height: calc(100vh - 330px) !important;
        // height: calc(100vh - 370px)!important ;
        overflow-y: auto !important;
        margin: 25px 0 0 0 !important;

        .message-main {

            // display: flex!important ;
            .send-msg {
                margin-bottom: 5px !important;
                display: flex !important;
                flex-direction: row-reverse !important;
                align-items: center !important;
                flex-wrap: wrap !important;

                .send-msg-main {
                    // max-width: 80% !important;
                    // flex-basis: 40%!important ;
                    // margin-bottom: 20px!important ;

                    background: rgb(145, 239, 220) !important;
                    border: 1px solid #F6EFE9 !important;
                    border-radius: 20px 20px 0px 20px !important;
                    padding: 15px !important;
                    padding-bottom: 7px !important;
                    padding-top: 7px !important;

                    .msg-text {
                        word-break: break-word !important;
                        font-weight: 500 !important;
                        font-size: 14px !important;
                        line-height: 26px !important;
                        color: black !important;
                    }

                    .msg-time {
                        margin-top: 3px !important;
                        text-align: right !important;
                        font-family: "Rubik" !important;
                        font-style: normal !important;
                        font-weight: 300 !important;
                        font-size: 8px !important;
                        line-height: 9px !important;
                        color: #08242b !important;
                    }

                    .time-msg {
                        font-size: 11px;
                        text-align: end;
                        margin-bottom: 0 !important;
                        margin-top: 10px;
                    }

                    .msg-img {
                        img {
                            height: 200px;
                            border-radius: 10px;
                            width: auto;
                        }
                    }
                }
            }

            #FrindsSingleChat {
                display: block !important;
            }

            .receive-msg {
                margin-bottom: 5px !important;

                // display: flex !important;

                .right-receive-msg {
                    display: flex !important;
                    align-items: center !important;
                    flex-wrap: wrap !important;
                }

                .msg-with-img {
                    margin-right: 10px !important;
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;

                    .Sender_name {
                        font-size: 13px;
                        color: black;
                        font-weight: 700;
                        margin-left: 7px;
                    }

                    img {
                        height: 36px !important;
                        width: 36px !important;
                        border-radius: 50% !important;
                    }
                }

                .receive-msg-main {
                    // flex-basis: 40%!important ;
                    // margin-bottom: 20px!important ;
                    background: rgb(218, 218, 218) !important;
                    border-radius: 10px 10px 0px 10px !important;
                    padding: 15px !important;
                    max-width: 100% !important;
                    padding-top: 7px !important;

                    .msg-text {
                        font-weight: 500 !important;
                        font-size: 14px !important;
                        line-height: 26px !important;
                        word-break: break-word !important;
                        color: rgb(61, 61, 61) !important;
                    }

                    .msg-time {
                        margin-top: 3px !important;
                        text-align: right !important;
                        font-family: "Rubik" !important;
                        font-style: normal !important;
                        font-weight: 300 !important;
                        font-size: 8px !important;
                        line-height: 9px !important;
                        color: #08242b !important;
                    }

                    .msg-img {
                        img {
                            height: 200px;

                            width: auto;
                        }
                    }

                    .time-msg {
                        font-size: 11px;
                        text-align: end;
                        margin-bottom: 0 !important;
                        margin-top: 10px;
                    }
                }
            }

            .time-date-sms-receive {
                max-width: 80% !important;
                text-align: end !important;
                font-weight: 500 !important;
                font-size: 12px !important;
                color: rgba(17, 17, 17, 0.5) !important;
                margin-bottom: 15px !important;
            }

            .time-date-sms-send {
                font-weight: 500 !important;
                font-size: 12px !important;
                color: rgba(17, 17, 17, 0.5) !important;
                display: flex !important;
                flex-direction: row-reverse !important;
                margin-right: 35px !important;
                margin-bottom: 15px !important;
            }
        }
    }

    .send_section_btn_inputs {
        border-top: 1px solid rgba(17, 17, 17, 0.2) !important;
        padding: 6px 10px !important;
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
        // position: absolute!important ;
        // bottom: 20px!important ;
    }

    .text-input {
        width: calc(100% - 100px) !important;

        input {
            width: 100% !important;
            height: 30px !important;
            border: none !important;
            margin-left: 10px !important;
        }

        .form-control:focus {
            box-shadow: none !important;
        }
    }

    .send-btn {
        background-color: #F6EFE9 !important;
        height: 40px !important;
        width: 40px !important;
        border-radius: 50% !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
    }

    .speed-dial {
        left: -10px !important;
        bottom: 260px !important;
        position: relative !important;

        .p-speeddial-opened .p-speeddial-list {
            background-color: #fff !important;
            border-radius: 20px !important;
        }

        .p-speeddial-button.p-button.p-button-icon-only {
            height: 40px !important;
            width: 40px !important;
        }

        .p-button {
            background-color: #F6EFE9 !important;
            color: #0c0c0b !important;
            border: none !important;
        }

        .pi-plus:before {
            font-weight: bolder !important;
        }

        .p-button:enabled:hover,
        .p-button:not(button):not(a):not(.p-disabled):hover {
            background-color: #F6EFE9 !important;
            border: none !important;
        }

        .p-speeddial-action {
            font-weight: bolder !important;
            height: 40px !important;
            width: 40px !important;
            color: #141413 !important;
            background-color: #F6EFE9 !important;
        }
    }
}

// Three dot Action Dropdown

.ActionDropdown {
    .ActionToggle {
        background-color: transparent !important;
        border: none !important;

        &:focus {
            background-color: transparent !important;
        }

        &::after {
            display: none !important;
        }

        &:hover {
            background-color: #fff !important;
        }
    }

    .ActionMenu {
        border: none !important;
        box-shadow: 2px 2px 10px lightgray !important;
        background-color: white !important;
        padding: 0 !important;
        position: absolute !important;
        margin: 0 !important;
        //   box-shadow: 0px 4px 10px 0px rgb(0 0 0 / 20%)!important ;

        .actionnmenulist {
            padding: 4px 13px !important;
            margin: 5px 5px !important;
            list-style-type: none !important;
            border-radius: 10px !important;
            color: lightgrey !important;
            cursor: pointer !important;
            width: 9vw !important;

            li {
                color: lightgrey !important;
                font-size: 14px !important;
                padding: 5px 0 !important;
                transition: all 0.2s ease-in-out !important;

                &:hover {
                    color: lightblue !important;
                    font-weight: 500 !important;
                }
            }
        }
    }
}

// Teams Tab

.teams-tab {
    background-color: transparent !important;

    .teams-user-tab {
        display: flex !important;
        position: relative !important;
        width: 100% !important;

        .left-profile-pic {
            display: flex !important;
            position: relative !important;

            a {
                img {
                    margin-right: 10px !important;
                    height: 40px !important;
                    width: 40px !important;
                    border-radius: 50% !important;
                }
            }

            .indication-img {
                position: absolute !important;
                bottom: -6px !important;
                right: 7px !important;
            }
        }

        .right-name-date {
            cursor: pointer !important;
            display: flex !important;
            justify-content: space-between !important;
            width: 100% !important;

            .top-section {
                display: flex !important;
                justify-content: space-between !important;
                align-items: center !important;

                .name {
                    display: flex !important;
                    align-items: center !important;
                    justify-content: center !important;

                    .active {
                        color: lightblue !important;
                    }

                    label {
                        color: #000 !important;
                        font-weight: 600 !important;
                        font-size: 14px !important;
                    }
                }
            }
        }
    }
}

.teams-user-lists {
    height: calc(100vh - 270px) !important;
    overflow-y: auto !important;
    width: auto !important;

    .team-user {
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
        padding: 10px 2px 10px 10px !important;

        .menus-items {
            display: flex !important;
            justify-content: space-between !important;
            align-items: center !important;

            a {
                text-decoration: none !important;
            }

            // align-items: center!important ;

            .icon-menu-name-arrow {
                display: flex !important;
                justify-content: space-between !important;

                .menu-left {
                    width: 100% !important;
                    display: flex !important;
                    justify-content: space-between !important;

                    .menu-name {
                        padding-left: 10px !important;
                        display: flex !important;
                        align-items: center !important;
                        font-style: normal !important;
                        font-weight: 500 !important;
                        font-size: 14px !important;
                        line-height: 24px !important;
                        color: lightgrey !important;
                    }

                    .team-image {
                        margin: 0 10px 0 3px !important;

                        img {
                            width: 100% !important;
                            height: auto !important;
                            border-radius: 50% !important;
                        }
                    }
                }

                .arrow {}
            }
        }
    }

    .ActionDropdown {}

    .sub-menus {
        margin: 10px 0 20px 30px !important;
        transition: all 0.5s !important;
        border-left: 1px solid lightblue !important;

        a {
            text-decoration: none !important;
        }

        .sub-menue-items {
            margin-bottom: 15px !important;
            display: block !important;
            margin-left: 24px !important;
        }

        .menu-name {
            display: flex !important;
            align-items: center !important;
            font-style: normal !important;
            font-weight: 500 !important;
            font-size: 16px !important;
            line-height: 24px !important;
            color: lightgrey !important;
        }
    }
}

// Chat History

.right-chat-custom-height-1 {
    height: calc(100vh - 148px) !important;
}

.right-chat-custom-height-2 {
    height: calc(100vh - 106px) !important;
}

.custom-height {
    height: calc(100vh - 160px) !important;
}

.chatting-section-custom-height {
    height: calc(100vh - 370px) !important;
}

.new-conversation-btn {
    position: relative !important;

    button {
        color: lightblue !important;
        background-color: #fff !important;
        padding: 10px 25px !important;
        border: none !important;
        border-radius: 10px !important;
        font-weight: 600 !important;
        letter-spacing: 0.04rem !important;
        position: absolute !important;
        right: 6px !important;
        bottom: 0 !important;
    }
}

.toast {
    position: absolute !important;
}

.inner-tabsectiom {
    display: flex;
    justify-content: space-between;
}

.main_tab_wrapper {
    ul {
        width: 30%;
        margin-bottom: 0 !important;
        border: none;
        display: flex;
        justify-content: space-between;
        padding-right: calc(var(--bs-gutter-x) * .5);
        margin-bottom: 25px;

        li {
            width: 30%;
            border: none;

            button {
                padding: auto;
                width: auto;
                background-color: white !important;
                border: 1px solid lightgray !important;
                border-radius: 20px;
                height: 40px;
                align-items: center;
            }
        }
    }

    .nav-tabs .nav-link {
        border-radius: 20px;
        color: grey;
    }

    .nav-tabs .nav-link.active {
        background-image: linear-gradient(#02B3BD, #00868E) !important;
        height: 40px;
        color: white;
        font-size: 14px;
        font-weight: 600;
        align-items: center;
        border-radius: 20px;
    }
}

.chat_bodyFooter {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    background: #ececec;
    border: 1px solid #bbbbbb;
    padding: 6px 6px 6px 20px;
    border-radius: 50px;

    .chat_footer_inputWrapper {
        width: 80%;
        height: 30px;

        div {
            width: 100% !important;
        }

        input {
            border: none !important;
            width: 100%;
            text-align: left;
            font-size: 14px;
            // font-family: $global-font;
            // font-weight: $bold-weight;
            background: transparent;
            // height: 38px;
            display: block;
            width: 100%;

            &:focus {
                box-shadow: none;
                outline: none;
            }
        }

        // div{
        //     div{
        //         div{
        //             height: 60px !important;
        //         }
        //     }
        // }
        ul {
            background-color: white !important;
            display: block;
            width: 100%;
            padding: 12px 15px;
            max-height: 350px !important;
            overflow-y: auto !important;
            border-radius: 15px;
            box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;

            li {
                background-color: #daf3ee;
                width: 100%;
            }

            li:hover {
                background-image: linear-gradient(#02B3BD, #00868E);
                color: white;
            }
        }
    }

    .chat_footerControls {
        button {
            cursor: pointer;
            border: 0;
            background: transparent;
            padding: 0 0 0 30px;
        }
    }

    textarea {
        height: 40px !important;
        border: none !important;
        overflow-y: auto !important;

        &:focus-visible {
            border: none !important;
            outline: none !important;
        }
    }
}

.leaveGropu {
    .dropdown-toggle::after {
        display: none;
    }

    button {
        border: none !important;
    }

    .dropdown-menu.show {
        a {
            font-size: 14px !important;
            padding: 0 !important
        }

        a:hover {
            background-color: transparent !important;
        }
    }
}

.Frinds_Add_btn {
    text-align: center;

    a {
        button {
            height: 50px;
            width: 50px;
            border-radius: 50%;
            background-image: linear-gradient(#02B3BD, #00868E);
            border: none;
            color: white;
        }
    }
}

.button-container button {
    background-image: linear-gradient(#02B3BD, #00868E);

    a {
        color: white;
    }
}