/*------------------------------------
            base reset Styles
------------------------------------*/
* {
    padding: 0;
    margin: 0;
  }
  
html {
    width: 100%;
    height: 100%;
}
$global-font: "Poppins", sans-serif;
body {
    width: 100%;
    height: 100%;
    font-family: $global-font;
    background-color: #F5F5F5;
    letter-spacing: .7px;
    padding: 0;
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;
    // letter-spacing: 1.5px;
    color: #1C0909;
    padding: 0;
    margin: 0;
}

p {
    color: #5F5F5F;
    padding: 0;
    margin: 0;
}

label {
    color: #5F5F5F;
}

a {
    text-decoration: none;
    color: #1C0909;
}

a:hover {
    color: #56B3F4;
}

ul {
    padding: 0;
    margin: 0;
}

li {
    list-style: none;
}

input[type="text"]{
    &:focus{
        box-shadow: none;
        outline: none;
    }
}

.fa-solid.fa-copy{
position: absolute;
right: 30px;
bottom: 38%;
}

.makeStyles-copyIcon-7{
    display: none !important;
}
.makeStyles-copyIcon-15 p{
    color: black !important;
}

.copy-icon{
    color: #fff;
}