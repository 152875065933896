
/* // CSS */
.chips-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  
  .chip {
    display: inline-block;
    padding: 0 10px;
    margin: 5px;
    height: 30px;
    line-height: 30px;
    border-radius: 15px;
    background-color: #e0e0e0;
    font-size: 14px;
  }
  
  .closebtn {
    color: #aaa;
    margin-left: 10px;
    cursor: pointer;
  }
  
  .closebtn:hover {
    color: #000;
  }