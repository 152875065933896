@import url('./reset.scss');
@import url('./issues.css');
// @import url('../../pages/groups/Chating.scss');

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// font-family: 'Nunito', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400;1,500&display=swap');
//font-family: 'Poppins', sans-serif;
$blueBG_color: #230342;
$globalGreenthemeBG_color: #00b4bd;
$themeOffWhite: #f9f9f9;
$global-fontBlackcolor: #1d1d25;
$global-fontBlackcolorlight: #707070;
$global-font: 'Nunito', sans-serif;
$global-font2: 'Poppins', sans-serif;
$global_border_color: #d2d2d2;

// Weights//
$hairline-weight: 100;
$thin-weight: 200;
$light-weight: 300;
$normal-weight: 400;
$medium-weight: 500;
$semibold-weight: 600;
$bold-weight: 700;
$xbold-weight: 800;
$black-weight: 900;
$global-fontColor1: #ffffff;
$global-fontColor2: #bbbbbb;

////// New Style////
// fontFamily: Source Sans Pro, sans-serif,
// fontSize: "12px",
// lineHeight: "19px",
// fontStyle: "normal"
//  font-family: Source Sans Pro,
//  sans-serif;
//  font-size: 14px;
//  font-style: normal;
//  font-weight: 600;

#root {
  letter-spacing: 0;
  outline: none;

  div {
    span {
      &.css-1xdhyk6 {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%);
      }
    }
  }
}

h1 {
  font-family: $global-font;
  font-weight: $light-weight;
  color: $global-fontColor1;
}

.sitemainBGwrapper {
  height: 100vh;
  padding: 20px;
  //   display: flex;
  //   align-items: flex-start;
  //   justify-content: space-between;
}

// sidebar style start here
/*------------------------------------
            General Styles
------------------------------------*/
#cx-sidebar {
  position: fixed;
  //   top: 14px;
  //   left: 14px;
  // height: 95%;
  height: calc(100vh - 40px);
  overflow: auto;
  border-radius: 20px;
  padding-top: 0;
  width: 228px;
  transition: all 0.3s;
  white-space: nowrap;
  z-index: 998;
  background: rgb(33, 33, 44);
  background: -moz-linear-gradient(0deg,
      rgba(33, 33, 44, 1) 0%,
      rgba(20, 19, 20, 1) 100%);
  background: -webkit-linear-gradient(0deg,
      rgba(33, 33, 44, 1) 0%,
      rgba(20, 19, 20, 1) 100%);
  background: linear-gradient(0deg,
      rgba(33, 33, 44, 1) 0%,
      rgba(20, 19, 20, 1) 100%);
}

#cx-sidebar::-webkit-scrollbar {
  display: none;
}

.cx-container {
  width: 100%;
}

ul.cx-list.scrollbar.thin-scroll.cx-active {
  height: 100%;
}

//   .cx-list li {
//     font-size: 16px;
//     position: relative;
//     border-bottom: 1px solid #aa2830;
//   }
.cx-list li a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  margin: 0 0 10px auto;
  // height: 60px;
  transition: all 0.5s ease;
  background: transparent;
  position: relative;
  color: #ffffff;
  font-family: $global-font;
  font-size: 16px;
  // padding: 8px 0 8px 24px;
  border-radius: 20px 0 0 20px;

  span {
    padding: 0 0 0 10px;
  }

  &:hover {
    background: #fff;
    color: #21212c;
  }
}

.cx-list li a span,
.cx-list li span+svg {
  // display: none;
}

.cx-list.cx-active li a {
  padding-left: 20px;

  i {
    padding: 0;
    font-size: 16px;
    margin: 0 8px 0 0;
  }
}

.cx-list .sub-menu.is-open li a i {
  color: #fe7f87;
  width: 30px;
  height: 30px;
  display: flex;
  font-size: 14px;
  background: #931921;
  border-radius: 100%;
  vertical-align: middle;
  align-items: center;
  justify-content: center;

  img {
    display: block;
    width: 60%;
    margin: 0 auto;
    padding: 0;
  }
}

.cx-list.cx-active li a img {
  padding-right: 8px;
  width: 30px;
}

.cx-list.cx-active li a span,
.cx-list.cx-active li span+svg {
  display: block;
  // position: relative;
  // top: 2px;
}

// .cx-list li.cx-nav-active a {
//   background: rgba(255, 255, 255, 0.15);
//   color: #ffffff !important;
// }

.cx-list li a.active {
  background: #f5f5f5;
  color: #21212c;
  border-radius: 20px 0 0 20px;
}

.cx-list li a.inactive {
  background: transparent;
}

.cx-list {
  .sub-menu {
    display: none;
  }

  .sub-menu.is-open {
    display: block;

    li {
      &:last-child {
        border: 0;
      }

      &:first-child {
        border-top: 1px solid #aa2830;
      }

      a {
        padding: 10px 0 10px 20px;

        height: auto;

        justify-content: start;
      }
    }
  }

  &.cx-active .sub-menu.is-open {
    display: block;

    &span {
      display: none;
    }
  }
}

.cx-sidebar-brand {
  // height: 60px;
  /* width: 100%; */
  flex-direction: column;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  margin: 0px 20px 30px 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px groove #009299;

  // border-bottom: 1px solid #aa2830;
  a {
    display: block;
    margin: 0 auto;

    img {
      display: block;
      width: 80%;
      width: 80%;
      margin: 0 auto;
    }
  }
}

.cx-sidebar-brand .cx-brand-icon-text {
  display: block;
}

.cx-sidebar-brand a img.cx-brand-icon {
  display: none;
}

.cx-sidebar-brand.cx-active {
  justify-content: center;
  padding-left: 0;
  width: 100%;
  flex-direction: column;
}

// .cx-sidebar-brand.cx-active .cx-brand-icon-text {
//   display: block;
// }
.cx-list li .cx-tooltip {
  padding: 0 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 78px;
  // right: -150px;
  width: auto;
  height: 40px;
  line-height: 40px;
  background: #ffffff;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  display: none;
  z-index: 1001;
}

.cx-tooltip::after {
  content: '';
  position: absolute;
  top: 12px;
  left: -7px;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: 7px solid #56b3f4;
}

.cx-list li:hover .cx-tooltip {
  opacity: 1;
  display: block;
}

.cx-list li .cx-tooltip:hover {
  opacity: 0;
  display: none;
}

/*------------------------------------
                  Toggler
  ------------------------------------*/

#cx-sidebar.cx-active #cx-sidebar-toggler {
  justify-content: end;

  button {
    svg {
      transform: rotate(180deg);
    }
  }
}

#cx-sidebar-toggler {
  display: none;
  height: 60px;
  // border-bottom: 1px solid #aa2830;
  line-height: 60px;
  // display: flex;
  align-items: center;
  justify-content: center;
}

#cx-sidebar-toggler button {
  height: 60px;
  width: 40px;
  background: transparent;
  border: 0;
}

#cx-sidebar-toggler button svg {
  fill: #ffffff !important;
  width: 48%;
  display: block;
  margin: 0 auto;
}

#cx-sidebar-toggler button:hover svg {
  fill: #ffffff !important;
}

#cx-sidebar-toggler.cx-active {
  justify-content: end;
  padding-right: 15px;
}

#cx-sidebar-toggler.cx-active button {
  width: 100%;
  transform: rotate(180deg);
}

/*------------------------------------
                  Account
  ------------------------------------*/
.cx-account img {
  width: 36px;
  height: 36px;
}

/*------------------------------------
                 Active Styles
  ------------------------------------*/
#cx-sidebar.cx-active {
  width: 100%;
  border-radius: 0;
  // background: #c22e37;
}

.cx-list.cx-active li a {
  text-align: left;
  font-family: $global-font;
  font-size: 13.5px;
  position: relative;

  svg {
    right: 14px;
    position: relative;
    font-size: 20px;
    top: 2px;
  }
}

.cx-list.cx-active li a .cx-list-c {
  right: unset;
  left: 12px;
}

.cx-sidebar-brand.cx-active a .cx-brand-logo {
  width: 100px;
  opacity: 1;
}

.cx-list.cx-active li a span {
  width: 100%;
  opacity: 1;
}

.cx-sidebar-brand.cx-active {
  text-align: left;
}

.cx-sidebar-brand.cx-active a .cx-brand-icon {
  margin-right: 15px;
  display: none;
}

.cx-list.cx-active li .cx-tooltip {
  display: none;
}

//sidebar style end here

//header style start here
/*------------------------------------
              Navbar Styles
  ------------------------------------*/
.cx-navbar {
  transition: all 0.3s;
  height: 60px;
}

.navbar-brand {
  h1 {
    color: $global-fontColor1;
    font-family: $global-font;
    font-size: 16px;
    font-weight: $medium-weight;
    padding: 0;
    margin: 0;
  }
}

.cx-navbar .container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}

.cx-navbar {
  flex-direction: row;

  ul li a {
    padding: 0;
    padding-left: 6px !important;
    padding-right: 6px !important;
    position: relative;

    img {
      width: 30px;
      display: block;
    }
  }
}

.cx-navbar ul li:nth-last-child(1) a {
  padding: 0;
  padding-left: 30px;
  padding-right: 0;
}

.cx-navbar ul li:nth-last-child(1) a img {
  border-radius: 50px;
}

.cx-alert {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 20px;
  height: 20px;
  background-color: $global-fontColor1;
  color: $globalGreenthemeBG_color;
  font-size: 10px;
  line-height: 21px;
  text-align: center;
  border-radius: 50px;
  letter-spacing: 0.5px;
}

/*------------------------------------
            Sidemenu Expand
  ------------------------------------*/
.cx-header {
  //   width: 80%;
  margin-left: 250px;
  margin-bottom: 20px;
  border-radius: 20px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.05);
  background: rgb(33, 33, 44);
  background: -moz-linear-gradient(0deg,
      rgba(33, 33, 44, 1) 0%,
      rgba(20, 19, 20, 1) 100%);
  background: -webkit-linear-gradient(0deg,
      rgba(33, 33, 44, 1) 0%,
      rgba(20, 19, 20, 1) 100%);
  background: linear-gradient(0deg,
      rgba(33, 33, 44, 1) 0%,
      rgba(20, 19, 20, 1) 100%);

  .navbar-nav {
    flex-direction: revert;
  }

  .cx-navbar {
    margin-left: 50px;
  }

  &.cx-active {
    margin-left: 250px;

    .cx-navbar {
      margin-left: 0px;

      .container-fluid {
        padding-left: 10px;
      }
    }
  }

  ul {
    li {
      >a {
        color: $global-fontColor1;
        font-size: 15px;
        font-family: $global-font;
        font-weight: $light-weight;
        padding: 40px 24px !important;

        &:hover {
          color: #00b4bd;
        }

        &.dropdown-toggle::after {
          display: none;
        }
      }

      &:last-child {
        >a {
          padding-bottom: 0 !important;
        }
      }

      //   &ul.dropdown-menu {
      //     left: -54px;
      //     background: #1f1f29;
      //   }
    }

    & li ul.dropdown-menu {
      left: -50px;
      background: #1e1e27;

      li {
        >a {
          padding: 6px 10px !important;

          &:hover {
            background: transparent;
          }
        }

        &:last-child {
          >a {
            padding-bottom: 0 !important;
            padding-top: 0px !important;
          }
        }

        hr {
          margin: 0;
          border-top: 1px solid #707070;
          padding: 0 0 6px 0;
        }
      }
    }
  }

  nav {
    padding: 0;
  }
}

//header style end here

main.cx-main {
  // height: calc(100vh - 60px);
  margin-left: 228px;
  overflow-x: hidden;
  transition: all 0.3s;
  padding: 0px 0px 0px 24px;
  position: relative;
  // height: 75vh;

  &.cx-active {
    margin-left: 228px;
  }
}

.profile_header {
  background: url('../images/profileHeaderBackCover.jpg');
  padding: 20px;
  display: flex;
  justify-content: space-between;
  background-size: cover;
  border-radius: 20px;

  .ph_section01 {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ph_profilePhoto {
      border-radius: 10px;
      overflow: hidden;
      border: 1px solid #00b4bd;
      padding: 4px;

      img {
        border-radius: 6px;
      }
    }

    .ph_profilePhoto_details {
      margin: 0 0 0 25px;

      ul {
        >li {
          padding: 4px 0;

          h1 {
            color: $globalGreenthemeBG_color;
            font-size: 16px;
            font-family: $global-font;
            font-weight: $medium-weight;
          }

          h2 {
            color: $global-fontColor1;
            font-size: 14px;
            font-family: $global-font;
            font-weight: $light-weight;
          }

          ul {
            display: flex;

            >li {
              padding: 0 12px 0 0;

              p {
                color: $global-fontColor1;
                font-size: 14px;
                font-family: $global-font;
                font-weight: $light-weight;
              }
            }
          }
        }
      }
    }
  }

  .ph_section02 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    a {
      background: #f5f5f5;
      padding: 10px;
      border-radius: 10px;

      &.trackingIcon05 {
        padding: 10px 10px;
        height: 38px !important;
        display: block;
      }

      img {
        display: block;
        margin: 0 auto;
      }
    }

    button {
      background: #f5f5f5;
      padding: 12px 10px;
      border-radius: 10px;
      border: 0;

      img {
        display: block;
        margin: 0 auto;
      }
    }

    .ph_userSection {
      img {
        display: block;
        margin: 0 auto;
      }

      p {
        color: $global-fontColor1;
        font-size: 12px;
        font-family: $global-font;
        font-weight: $light-weight;
        text-align: center;
        margin: 4px 0;
      }

      h3 {
        color: $global-fontColor1;
        font-size: 16px;
        font-family: $global-font;
        font-weight: $light-weight;
        text-align: center;
      }
    }

    .phs02_Wrapper {
      display: flex;

      .dropdown {
        button {
          &.userControlWrap {
            background: transparent;
            color: white;
            position: relative;
            right: 10px;

            &:focus {
              box-shadow: none;
            }
          }
        }
      }
    }
  }
}

.profile_modalWrapper {
  .modal-dialog.modal-lg {
    max-width: 600px;
  }

  .modal-content {
    border-radius: 20px;
  }

  h5 {
    color: $global-fontBlackcolor;
    font-size: 20px;
    font-family: $global-font;
    font-weight: $bold-weight;
  }

  button {
    padding: 0;
    // background: #bbbbbb;
    // border-radius: 10px;
  }

  .modal-header {
    border-bottom: 0;
  }

  .profile_modal_contentWrapper {
    .uploadPPWrapper {
      .uploadPP_imgHolder {
        width: 100%;
        padding: 0;

        img {
          border: 2px solid #00b4bd;
          padding: 4px;
          border-radius: 16px;
          margin: 0 auto;
          display: block;
          width: 100%;
        }
      }

      .uploadPP_input {
        .btn-tertiary {
          color: #555;
          padding: 0;
          // line-height: 40px;
          margin: auto;
          display: block;
        }

        /* input file style */

        .input-file {
          width: 0.1px;
          height: 0.1px;
          opacity: 0;
          overflow: hidden;
          position: absolute;
          z-index: -1;

          +.js-labelFile {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 8px 10px 8px 38px;
            width: 100%;
            background: #00b4bd;
            position: relative;
            cursor: pointer;
            margin: 20px 0 0 0;
            border-radius: 20px;

            &::before {
              content: '';
              background: url('../images/camera_icon.svg');
              position: absolute;
              left: 12px;
              z-index: 999;
              top: 11px;
              padding: 10px;
              background-repeat: no-repeat;
            }

            &.has-file {
              .icon:before {
                //font-awesome
                content: '\f00c';
                color: #5aac7b;
              }
            }

            span {
              font-size: 14px;
              font-family: $global-font;
              font-weight: $medium-weight;
              color: $global-fontColor1;
            }
          }
        }

        input[type='file'] {}
      }
    }

    .profile_form {
      .uploadPP_inputTextRow {
        margin: 0 0 14px 0;

        label {
          color: $global-fontBlackcolorlight;
          font-size: 14px;
          font-family: $global-font;
          font-weight: $medium-weight;
        }

        input[type='text'] {
          border: 1px solid #e1e1e1;
          border-radius: 10px;
          background: #fbf8f8;
          color: $global-fontBlackcolorlight;
          font-size: 14px;
          font-family: $global-font;
          font-weight: $medium-weight;
        }

        textarea {
          border: 1px solid #e1e1e1;
          border-radius: 10px;
          background: #fbf8f8;
          color: $global-fontBlackcolorlight;
          font-size: 14px;
          font-family: $global-font;
          font-weight: $medium-weight;
          width: 100%;
          padding: 10px;
        }

        .upp_selectWrapper {
          position: relative;

          &::before {
            content: '';
            background: url('../images/drop_down.svg');
            background-repeat: no-repeat;
            position: absolute;
            top: 50%;
            right: 10px;
            background-repeat: no-repeat;
            padding: 4px;
            transform: translate(-50%);
          }

          select {
            border: 1px solid #e1e1e1;
            border-radius: 10px;
            background: #fbf8f8;
            color: $global-fontBlackcolorlight;
            font-size: 14px;
            font-family: $global-font;
            font-weight: $medium-weight;
          }
        }
      }
    }

    .profile_tagWrapper {
      h1 {
        color: $global-fontBlackcolor;
        font-size: 16px;
        font-family: $global-font;
        font-weight: $bold-weight;
        margin-bottom: 10px;
      }

      ul {
        li {
          display: inline-block;
          margin: 6px auto;
          padding: 0 6px;

          h2 {
            color: $globalGreenthemeBG_color;
            font-size: 16px;
            font-family: $global-font;
            font-weight: $bold-weight;
            border-radius: 15px;
            border: 1px solid $globalGreenthemeBG_color;
            display: inline-block;
            padding: 6px 18px;
          }

          h3 {
            color: $global-fontBlackcolorlight;
            font-size: 16px;
            font-family: $global-font;
            font-weight: $bold-weight;
            border-radius: 15px;
            border: 1px solid $global-fontBlackcolorlight;
            display: inline-block;
            padding: 6px 18px;
          }
        }
      }
    }
  }

  .modal-footer {
    border: 0;
    padding-bottom: 30px;
    padding-top: 0;

    button {
      background: #00b4bd;
      border-radius: 25px;
      padding: 10px 30px;
      color: $global-fontColor1;
      font-size: 14px;
      font-family: $global-font;
      font-weight: $medium-weight;
      border: 0;
      display: block;
      margin: 0 auto;
    }
  }
}

.modal-footer {
  border-top: none;
  border-bottom: none;
}

.gray_modalWrapper {
  .modal-dialog.modal-lg {
    max-width: 600px;
  }

  .modal-content {
    border-radius: 20px;
    background: #f5f5f5;
  }

  h5 {
    color: $global-fontBlackcolor;
    font-size: 20px;
    font-family: $global-font;
    font-weight: $bold-weight;

    img {
      margin-right: 20px;
    }
  }

  button {
    padding: 0;
    // background: #bbbbbb;
    // border-radius: 10px;
  }

  .modal-header {
    border-bottom: 2px solid #efefef;
    padding: 0 0 10px 0;
    margin: 1.5rem;
  }

  .profile_modal_contentWrapper {
    .uploadPPWrapper {
      .uploadPP_imgHolder {
        width: 100%;
        padding: 0;

        img {
          border: 2px solid #00b4bd;
          padding: 4px;
          border-radius: 16px;
          margin: 0 auto;
          display: block;
          width: 100%;
        }
      }

      .uploadPP_input {
        .btn-tertiary {
          color: #555;
          padding: 0;
          // line-height: 40px;
          margin: auto;
          display: block;
        }

        /* input file style */

        .input-file {
          width: 0.1px;
          height: 0.1px;
          opacity: 0;
          overflow: hidden;
          position: absolute;
          z-index: -1;

          +.js-labelFile {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 8px 10px 8px 38px;
            width: 100%;
            background: #00b4bd;
            position: relative;
            cursor: pointer;
            margin: 20px 0 0 0;
            border-radius: 20px;

            &::before {
              content: '';
              background: url('../images/camera_icon.svg');
              position: absolute;
              left: 12px;
              z-index: 999;
              top: 11px;
              padding: 10px;
              background-repeat: no-repeat;
            }

            &.has-file {
              .icon:before {
                //font-awesome
                content: '\f00c';
                color: #5aac7b;
              }
            }

            span {
              font-size: 14px;
              font-family: $global-font;
              font-weight: $medium-weight;
              color: $global-fontColor1;
            }
          }
        }

        input[type='file'] {}
      }
    }

    .profile_form {
      padding: 0;

      .uploadPP_inputTextRow {
        margin: 0 0 14px 0;

        label {
          color: $global-fontBlackcolorlight;
          font-size: 14px;
          font-family: $global-font;
          font-weight: $medium-weight;
        }

        p {
          color: #000;
          font-size: 14px;
          font-family: $global-font;
          font-weight: $medium-weight;
          margin-bottom: 10px;
        }

        input[type='text'] {
          border: 1px solid #e1e1e1;
          border-radius: 10px;
          background: #ffffff;
          color: $global-fontBlackcolorlight;
          font-size: 14px;
          font-family: $global-font;
          font-weight: $medium-weight;
        }

        textarea {
          border: 1px solid #e1e1e1;
          border-radius: 10px;
          background: #ffffff;
          color: $global-fontBlackcolorlight;
          font-size: 14px;
          font-family: $global-font;
          font-weight: $medium-weight;
          width: 100%;
          padding: 10px;
        }

        .upp_selectWrapper {
          position: relative;

          &::before {
            content: '';
            background: url('../images/drop_down.svg');
            background-repeat: no-repeat;
            position: absolute;
            top: 50%;
            right: 10px;
            background-repeat: no-repeat;
            padding: 4px;
            transform: translate(-50%);
          }

          select {
            border: 1px solid #e1e1e1;
            border-radius: 10px;
            background: #ffffff;
            color: $global-fontBlackcolorlight;
            font-size: 14px;
            font-family: $global-font;
            font-weight: $medium-weight;
          }
        }
      }
    }

    .profile_tagWrapper {
      h1 {
        color: $global-fontBlackcolor;
        font-size: 16px;
        font-family: $global-font;
        font-weight: $bold-weight;
        margin-bottom: 10px;
      }

      ul {
        li {
          display: inline-block;
          margin: 6px auto;
          padding: 0 6px;

          h2 {
            color: $globalGreenthemeBG_color;
            font-size: 16px;
            font-family: $global-font;
            font-weight: $bold-weight;
            border-radius: 15px;
            border: 1px solid $globalGreenthemeBG_color;
            display: inline-block;
            padding: 6px 18px;
          }

          h3 {
            color: $global-fontBlackcolorlight;
            font-size: 16px;
            font-family: $global-font;
            font-weight: $bold-weight;
            border-radius: 15px;
            border: 1px solid $global-fontBlackcolorlight;
            display: inline-block;
            padding: 6px 18px;
          }
        }
      }
    }
  }

  .modal-footer {
    border: 0;
    padding-bottom: 30px;
    padding-top: 0;
    justify-content: center;

    button {
      &:first-child {
        background-color: #fff;
        color: #00b4bd;
        border: 1px solid #00b4bd;
      }

      background: #00b4bd;
      border-radius: 25px;
      padding: 10px 30px;
      color: $global-fontColor1;
      font-size: 14px;
      font-family: $global-font;
      font-weight: $medium-weight;
      border: 0;
      display: block;
      margin: 0 10px;
    }
  }

  .grayfriendsListWrapper {
    height: 70vh;
    overflow: scroll;
    overflow-x: hidden;

    // custom single scroll bar start
    &::-webkit-scrollbar {
      width: 0.2em;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: #009299;
      outline: 1px solid slategrey;
    }

    // custom single scroll bar end
    ul {
      li {
        width: 100%;
        margin: 0 0 20px 0;
        padding: 0 10px 0 0;

        .fl_itemInner_Wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .fli_leftWrapper {
            display: flex;
            align-items: center;

            img {
              display: block;
              border-radius: 10px;
            }

            h1 {
              margin-left: 10px;
              color: $global-fontBlackcolor;
              font-size: 14px;
              font-family: $global-font;
              font-weight: $xbold-weight;
              text-align: left;
            }
          }

          a {
            img {
              display: block;
            }
          }
        }
      }
    }
  }
}

.profile_sidebar_modalWrapper {
  .modal-dialog {
    position: absolute;
    right: 20px;
    height: 90%;
    top: 50%;
    transform: translate(0%, -50%) !important;
    margin: 0;
  }

  .modal-dialog.modal-lg {
    max-width: 600px;
  }

  .modal-content {
    border-radius: 20px;
    overflow: hidden;
    height: 100%;
  }

  h5 {
    color: $global-fontBlackcolor;
    font-size: 20px;
    font-family: $global-font;
    font-weight: $bold-weight;
  }

  button {
    padding: 0;
    // background: #bbbbbb;
    // border-radius: 10px;
  }

  .modal-header {
    border-bottom: 0;
  }

  .modal-body {
    padding: 0;
  }

  .profile_sidebar_modal_contentWrapper {
    .card-body {
      border: 0;
      height: 40vh;
      overflow: scroll;
      overflow-x: hidden;

      // custom single scroll bar start
      &::-webkit-scrollbar {
        width: 0.2em;
      }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }

      &::-webkit-scrollbar-thumb {
        background-color: #009299;
        outline: 1px solid slategrey;
      }

      // custom single scroll bar end
    }

    button {
      &:focus {
        box-shadow: none;
      }

      &.ps_modalCollapsHeader {
        width: 100%;
        border-radius: 0;
        background: #efefef;
        border: 0;
        color: #000;
        color: $global-fontBlackcolor;
        font-size: 16px;
        font-family: $global-font;
        font-weight: $bold-weight;
        padding: 6px 10px;
        text-align: left;
      }
    }

    .friendsListWrapper {
      ul {
        li {
          width: 100%;
          margin: 0 0 20px 0;

          .fl_itemInner_Wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .fli_leftWrapper {
              display: flex;
              align-items: center;

              img {
                display: block;
                border-radius: 10px;
              }

              h1 {
                margin-left: 10px;
                color: $global-fontBlackcolor;
                font-size: 14px;
                font-family: $global-font;
                font-weight: $xbold-weight;
                text-align: left;
              }
            }

            a {
              img {
                display: block;
              }
            }
          }
        }
      }
    }
  }

  .profile_sidebar_modal_contentWrapper2 {
    .card-body {
      border: 0;
      height: 30vh;
      overflow: scroll;
      overflow-x: hidden;

      // custom single scroll bar start
      &::-webkit-scrollbar {
        width: 0.2em;
      }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }

      &::-webkit-scrollbar-thumb {
        background-color: #009299;
        outline: 1px solid slategrey;
      }

      // custom single scroll bar end
    }

    button {
      &:focus {
        box-shadow: none;
      }

      &.ps_modalCollapsHeader {
        width: 100%;
        border-radius: 0;
        background: #efefef;
        border: 0;
        color: #000;
        color: $global-fontBlackcolor;
        font-size: 16px;
        font-family: $global-font;
        font-weight: $bold-weight;
        padding: 6px 10px;
        text-align: left;
      }
    }

    .friendsListWrapper {
      ul {
        li {
          width: 100%;
          margin: 0 0 20px 0;

          .fl_itemInner_Wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .fli_leftWrapper {
              display: flex;
              align-items: center;

              img {
                display: block;
                border-radius: 10px;
              }

              h1 {
                margin-left: 10px;
                color: $global-fontBlackcolor;
                font-size: 14px;
                font-family: $global-font;
                font-weight: $xbold-weight;
                text-align: left;
              }
            }

            a {
              img {
                display: block;
              }

              span {
                color: #00b4bd;
                border: 1px solid #00b4bd;
                border-radius: 20px;
                padding: 2px 20px;
              }
            }
          }
        }
      }
    }
  }

  .modal-footer {
    border: 0;
    padding-bottom: 30px;
    padding-top: 0;

    button {
      background: #00b4bd;
      border-radius: 25px;
      padding: 10px 30px;
      color: $global-fontColor1;
      font-size: 14px;
      font-family: $global-font;
      font-weight: $medium-weight;
      border: 0;
      display: block;
      margin: 0 auto;
    }
  }
}

.gbl_col_70 {}

.aboutSection::-webkit-scrollbar {
  display: none;
}

.aboutSection {
  background: $global-fontColor1;
  border-radius: 20px;
  padding: 20px;
  margin-top: 20px;
  height: calc(100vh - 327px);
  overflow: auto;
  letter-spacing: 0;

  h1 {
    color: #1d1d25;
    font-size: 16px;
    font-family: $global-font;
    font-weight: $semibold-weight;
  }

  .abt_paraSection {
    background: #f5f5f5;
    padding: 20px;
    border-radius: 20px;
    margin: 20px 0;

    p {
      color: $global-fontBlackcolor;
      font-size: 14px;
      font-family: $global-font;
      font-weight: 600;
      letter-spacing: 0;
    }
  }

  .abt_contentSection {
    border-bottom: 1px solid #bbbbbb;
    border-top: 1px solid #bbbbbb;
    padding: 20px 0;

    h1 {
      color: $global-fontBlackcolor;
      font-size: 16px;
      font-family: $global-font;
      font-weight: $semibold-weight;
      letter-spacing: 0px;
    }

    p {
      color: $global-fontBlackcolorlight;
      font-size: 14px;
      font-family: $global-font;
      font-weight: 600;
      letter-spacing: 0;
    }
  }

  .abt_labelSection {
    h1 {
      color: #1d1d25;
      font-size: 16px;
      font-family: $global-font;
      font-weight: $semibold-weight;
      display: inline-block;
      margin: 20px 0;
    }

    ul {
      li {
        display: inline-block;

        a {
          color: #00b4bd;
          font-size: 16px;
          font-family: $global-font;
          text-align: center;
          padding: 10px 20px;
          border-radius: 15px;
          border: 1px solid $globalGreenthemeBG_color;
          margin: 0 20px 0 0px;
          display: block;
        }
      }
    }
  }
}

.friendSectionWrapper {
  overflow: auto;
}

.friendSectionWrapper .friendSectionScroll {
  height: 20px;
  // height: 354px;
  // background-color: black;
  overflow: auto;
}

.friendSectionWrapper::-webkit-scrollbar {
  display: none;
}

.friendSectionWrapper {
  background: $global-fontColor1;
  border-radius: 20px;
  padding: 20px;
  margin-top: 20px;
  // height: calc(100vh - 327px);

  .fs_header {
    display: flex;
    justify-content: space-between;

    h1 {
      color: $global-fontBlackcolor;
      font-size: 16px;
      font-family: $global-font;
      font-weight: 600;
      margin-bottom: 20px;
    }

    a {
      color: $global-fontBlackcolor;
      font-size: 16px;
      font-family: $global-font;
      font-weight: 600;
      margin-bottom: 20px;

      &:hover {
        color: $globalGreenthemeBG_color;
      }
    }

    button {
      border: 0;
      background: transparent;
      color: $global-fontBlackcolor;
      font-size: 16px;
      font-family: $global-font;
      font-weight: 600;
      margin-bottom: 20px;

      &:hover {
        color: $globalGreenthemeBG_color;
      }
    }
  }

  .friendSectionScroll {
    height: 337px;

    .friendSectionItem {
      background: #f5f5f5;
      padding: 10px;
      border-radius: 20px;
      margin: 0px 10px 20px 0;
      display: flex;
      align-items: center;

      .fs_details {
        margin: 0 0 0 20px;

        h2 {
          color: $globalGreenthemeBG_color;
          font-size: 14px;
          font-family: $global-font;
        }

        p {
          color: $global-fontBlackcolor;
          font-size: 14px;
          font-family: $global-font;
          padding: 0px 0 4px 0;
        }

        .fs_control {
          ul {
            display: flex;
            align-items: center;
            justify-content: space-between;

            li {
              a {
                color: $global-fontBlackcolorlight;
                font-size: 14px;
                font-family: $global-font;
                border: 1px solid $global-fontBlackcolorlight;
                padding: 4px 16px;
                border-radius: 20px;

                &.primary {
                  color: $global-fontColor1;
                  background: $globalGreenthemeBG_color;
                  border: 1px solid $globalGreenthemeBG_color;
                }
              }

              &:first-child {
                margin-right: 10px;
              }
            }
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

/**********************/
/* custom scroll code */
/**********************/

.thin-scroll::-webkit-scrollbar-track {
  background: linear-gradient(to right,
      var(--scroll-track-color) 0%,
      var(--scroll-track-color) 30%,
      var(--scroll-color) 60%,
      var(--body-bg-color) 61%,
      var(--body-bg-color) 100%);
}

.thin-scroll::-webkit-scrollbar {
  width: 4px;
}

.thin-scroll::-webkit-scrollbar-thumb {
  background-color: var(--scroll-color);
  border-radius: 4px;
}

:root {
  --body-bg-color: #e3e3e3;
  --scroll-color: #00b4bd;
  --scroll--hover-color: #666;
  --scroll-track-color: rgba(255, 255, 255, 0);
}

* {
  -webkit-overflow-scrolling: touch;
}

//   .content {
//     display: grid;
//     grid-template-columns: 180px 180px 180px;
//     grid-gap: 20px;
//     width: 180px;
//     height: 200px;
//   }

.scrollbar {
  // height: 200px;
  overflow-y: scroll;
  // background: #c3bebe;
  scrollbar-color: #00b4bd #c3bebe;
  scrollbar-width: thin;
}

.auth_mainWrapper {
  background-size: contain;
  display: block;
  align-items: center;
  background-position: bottom;

  .auth_innerWrapper {
    display: flex;
    flex-direction: column;
  }

  .auth_innerWrapper {
    display: block;
    height: 100vh;
    width: 100%;
    overflow-y: hidden;
    padding: 20px;

    .auth_twoPane {
      height: 100%;
      display: flex;
      flex-direction: row;

      &>div {
        flex: 1;

        &:first-child {
          display: flex;
        }
      }

      .auth_formWrapper {
        .auth_formbg1 {
          background: url('../images/login_shape.svg');
          background-position: top;
          background-repeat: no-repeat;
          background-size: cover;
        }

        .auth_formbg2 {
          background: url('../images/signup_shape.svg');
          background-position: top;
          background-repeat: no-repeat;
          background-size: cover;
        }

        .auth_innerformWrapper {
          // padding: 0 20px;
          // width: 80%;
          // margin: 20px auto 0 auto;
          padding: 0 20px;
          width: 80%;
          margin: 2px 50px;

          .auth_header_control {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 35px 20px 70px 20px;
            width: 100%;

            a {
              &:hover {
                color: $globalGreenthemeBG_color;
                border-bottom: 1px solid $globalGreenthemeBG_color;
              }

              &.authLink {
                border-bottom: 2px solid #00b4bd;
              }

              h1 {
                font-size: 30px;
                font-family: $global-font;
              }

              h2 {
                font-size: 16px;
                font-family: $global-font;
                color: $global-fontColor1;
                font-weight: $light-weight;
              }
            }
          }

          .auth_formHolderBG1 {
            background: #141314;
          }

          .auth_formHolderBG2 {
            background: #2d2d37;
          }

          .auth_formHolder {
            border-radius: 0 0 50px 50px;

            .auth_Gapper1 {
              padding: 50px 0 80px 0;
            }

            .auth_Gapper2 {
              padding: 0px 0 10px 0;
            }

            .auth_inputWrapper {
              .auth_iHolder {
                display: flex;
                margin: 0 30px 20px 30px;
                border-bottom: 1px solid $global-fontBlackcolorlight;
                align-items: center;
                justify-content: space-between;

                img {
                  width: 22px;
                }

                &.auth_iHolder03 {
                  border-bottom: 0 !important;

                  .auth_selectWrapper {
                    position: relative;
                    width: 100px;
                    padding: 0px 10px 0px 10px;
                    margin: 0 10px 0 10px;
                    border-bottom: 1px solid #707070;

                    &::after {
                      content: '';
                      background: url('../images/drop_down_greenIcon.svg');
                      background-repeat: no-repeat;
                      padding: 4px;
                      position: absolute;
                      top: 50%;
                      right: 10px;
                    }

                    select {
                      font-family: $global-font;
                      border-bottom: 0;
                      width: 100px;
                      background: transparent;
                      color: $global-fontBlackcolorlight;
                      border-right: 0;
                      border-left: 0;
                      border-top: 0;
                      border-radius: 0;
                      padding: 0.375rem 0.1rem;
                      font-size: 14px;
                      margin: 0 10px 0 0px;

                      &:focus {
                        outline: none;
                        box-shadow: none;
                      }

                      option {
                        background: #2d2d37;
                      }
                    }
                  }

                  input[type='text'] {
                    border-bottom: 1px solid $global-fontBlackcolorlight;
                    border-radius: 0;
                  }
                }

                // input[type="email"]:focus, input[type="email"]:hover, input[type="email"]:active, input[type="email"]:-webkit-autofill{
                //     background-color: blue !important;
                // }
                input[type='email']:-webkit-autofill,
                input[type='email']:-webkit-autofill:focus {
                  transition: background-color 600000s 0s, color 600000s 0s;
                }

                input[data-autocompleted] {
                  background-color: transparent !important;
                }

                input[type='password']:-webkit-autofill,
                input[type='password']:-webkit-autofill:focus {
                  transition: background-color 600000s 0s, color 600000s 0s;
                }

                input[data-autocompleted] {
                  background-color: transparent !important;
                }

                input[type='email'] {
                  border: 0;
                  background: transparent;
                  font-size: 14px;
                  font-family: $global-font;
                  color: $global-fontColor1;

                  &:focus {
                    border: 0;
                    outline: none;
                    box-shadow: none;
                  }
                }

                input[type='password'] {
                  border: 0;
                  background: transparent;
                  font-size: 14px;
                  font-family: $global-font;
                  color: $global-fontColor1;

                  &:focus {
                    border: 0;
                    outline: none;
                    box-shadow: none;
                  }
                }

                &.authLink {
                  border: none;
                  justify-content: end;

                  a {
                    text-align: right;
                    font-size: 14px;
                    font-family: $global-font;
                    color: $global-fontColor2;
                  }
                }

                .auth_selectWrapper {
                  width: 100%;
                  padding: 0 0 0 10px;
                  position: relative;

                  &::after {
                    content: '';
                    background: url('../images/drop_down_greenIcon.svg');
                    background-repeat: no-repeat;
                    padding: 4px;
                    position: absolute;
                    top: 50%;
                    right: 10px;
                  }

                  select {
                    font-family: $global-font;
                    border-bottom: 0;
                    width: 100%;
                    background: transparent;
                    color: $global-fontBlackcolorlight;
                    border-right: 0;
                    border-left: 0;
                    border-top: 0;
                    border-radius: 0;
                    padding: 0.375rem 0.1rem;
                    font-size: 14px;
                    margin: 0 10px 0 0px;

                    &:focus {
                      outline: none;
                      box-shadow: none;
                    }

                    option {
                      background: #2d2d37;
                    }
                  }
                }
              }
            }

            .auth_controlWrapper {
              a {
                text-align: center;
                font-size: 14px;
                font-family: $global-font;
                color: $global-fontColor1;
                background: $globalGreenthemeBG_color;
                border: 0;
                margin: 0 auto;
                display: table;
                padding: 10px 40px;
                border-radius: 100px;
                position: relative;
                bottom: -20px;
              }

              button {
                text-align: center;
                font-size: 14px;
                font-family: $global-font;
                color: $global-fontColor1;
                background: $globalGreenthemeBG_color;
                border: 0;
                margin: 0 auto;
                display: block;
                padding: 10px 40px;
                border-radius: 100px;
                position: relative;
                bottom: -20px;
              }
            }

            .checkboxWrapper {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin: 0px 30px 0px 30px;

              .checkboxWrapper_holder {
                label {
                  display: flex;
                  align-items: center;
                  margin: 0 0 0 0px;
                  color: $globalGreenthemeBG_color;
                  font-size: 14px;
                  font-family: $global-font;
                  font-weight: $medium-weight;
                  cursor: pointer;
                }

                // input[type="checkbox"]{
                //   margin-right: 10px;
                // }

                // label{
                //   color: #000;
                //   font-size: 14px;
                //   font-family: $global-font;
                //   font-weight: $medium-weight;
                // }

                .check__item label {
                  display: flex;
                  align-items: center;
                  -moz-column-gap: 6px;
                  column-gap: 6px;
                }

                .default__check[type='checkbox'] {
                  display: none;
                }

                .default__check[type='checkbox']:not(.switchbox)~.custom__check {
                  border-radius: 0.375rem;
                }

                .default__check[type='checkbox']:not(.switchbox)~.custom__check:after,
                .default__check[type='radio']:not(.switchbox)~.custom__check:after {
                  visibility: hidden;
                }

                .default__check[type='checkbox']~.custom__check:after,
                .default__check[type='radio']~.custom__check:after {
                  content: '';
                  display: inline-block;
                  position: absolute;
                  transition: all 0.4s ease;
                }

                .default__check[type='checkbox']:not(.switchbox)~.custom__check {
                  border-radius: 0.375rem;
                }

                .default__check[type='checkbox']:checked~.custom__check,
                .default__check[type='radio']:checked~.custom__check {
                  background-color: $globalGreenthemeBG_color;
                }

                .default__check[type='checkbox']:not(.switchbox)~.custom__check,
                .default__check[type='radio']:not(.switchbox)~.custom__check {
                  justify-content: center;
                  width: 22px;
                }

                .default__check[type='checkbox']~.custom__check,
                .default__check[type='radio']~.custom__check {
                  display: flex;
                  align-items: center;
                  height: 22px;
                  border: 1px solid $globalGreenthemeBG_color;
                  position: relative;
                  transition: all 0.4s ease;
                  cursor: pointer;
                  margin: 0 6px 0 0;
                }

                .default__check[type='checkbox']:not(.switchbox)~.custom__check:after {
                  height: 9px;
                  width: 5px;
                  margin-top: -1px;
                  border-bottom: 2px solid #fff;
                  border-right: 2px solid #fff;
                  transform: rotate(45deg);
                }

                .default__check[type='checkbox']:not(.switchbox)~.custom__check:after,
                .default__check[type='radio']:not(.switchbox)~.custom__check:after {
                  visibility: hidden;
                }

                .default__check[type='checkbox']:checked~.custom__check:after,
                .default__check[type='radio']:checked~.custom__check:after {
                  visibility: visible;
                }

                .default__check[type='checkbox']~.custom__check:after,
                .default__check[type='radio']~.custom__check:after {
                  content: '';
                  display: inline-block;
                  position: absolute;
                  transition: all 0.4s ease;
                }
              }
            }
          }

          .oAuthWrapper {
            margin: 40px 0 0px 0;

            h1 {
              text-align: center;
              font-size: 12px;
              font-family: $global-font;
              color: $global-fontBlackcolorlight;
            }

            ul {
              display: flex;
              justify-content: center;
              align-items: center;

              li {
                a {
                  background: $global-fontColor1;
                  border-radius: 100%;
                  height: 50px;
                  width: 50px;
                  display: block;
                  margin: 20px 10px;

                  &.google {
                    border: 1px solid #f14336;
                  }

                  &.fb {
                    border: 1px solid #3b5998;
                  }

                  img {
                    display: block;
                    position: relative;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                  }
                }
              }
            }
          }

          .auth_wizardWrapper {
            &.auth_wizardWrapper {
              margin-bottom: 20px;
            }

            ul {
              position: relative;
              margin: 0 auto;
              display: table;

              li {
                display: inline-block;
                position: relative;

                &::after {
                  content: '';
                  position: absolute;
                  height: 1px;
                  width: 100%;
                  left: 40px;
                  background: #707070;
                  top: 50%;
                  z-index: 100;
                }

                &:last-child {
                  &::after {
                    display: none;
                  }
                }

                a {
                  text-align: center;
                  font-size: 12px;
                  font-family: $global-font;
                  color: $global-fontBlackcolorlight;
                  border: 1px solid #707070;
                  padding: 6px;
                  background: #fff;
                  border-radius: 100px;
                  z-index: 900;
                  position: relative;
                  margin: 0 20px;

                  &.auth_completed {
                    background: $globalGreenthemeBG_color;
                    color: $global-fontColor1;
                    border: 3px solid #ffffff;

                    &::before {
                      content: '';
                      padding: 17px;
                      border: 2px solid #00b4bd;
                      position: absolute;
                      border-radius: 100px;
                      left: -5px;
                      top: -5px;
                    }
                  }
                }
              }
            }
          }

          .auth_wizardForm {
            h1 {
              text-align: left;
              font-size: 30px;
              font-family: $global-font;
              color: $global-fontBlackcolor;
              font-weight: $bold-weight;
              margin: 30px 0 14px 0;
            }

            p {
              text-align: left;
              font-size: 14px;
              font-family: $global-font;
              color: $global-fontBlackcolorlight;
              margin-bottom: 20px;
            }

            .auth_inner_wizardForm {
              width: 100%;

              .aWizardRow {
                width: 100%;

                .checkboxWrapper {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin: 0px;
                  width: 100%;

                  .checkboxWrapper_holder {
                    width: 100%;

                    label {
                      font-size: 14px;
                      font-family: $global-font;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      margin: 0 0 10px 0px;
                      color: $global-fontBlackcolor;
                      background: #ffffff;
                      font-size: 14px;
                      font-family: $global-font;
                      font-weight: $medium-weight;
                      cursor: pointer;
                      padding: 14px 16px;
                      border: 1px solid #e1e1e1;
                      border-radius: 20px;
                    }

                    // input[type="checkbox"]{
                    //   margin-right: 10px;
                    // }

                    // label{
                    //   color: #000;
                    //   font-size: 14px;
                    //   font-family: $global-font;
                    //   font-weight: $medium-weight;
                    // }

                    .check__item label {
                      display: flex;
                      align-items: center;
                      -moz-column-gap: 6px;
                      column-gap: 6px;
                    }

                    .default__check[type='checkbox'] {
                      display: none;
                    }

                    .default__check[type='checkbox']:not(.switchbox)~.custom__check {
                      border-radius: 1.375rem;
                    }

                    .default__check[type='checkbox']:not(.switchbox)~.custom__check:after,
                    .default__check[type='radio']:not(.switchbox)~.custom__check:after {
                      visibility: hidden;
                    }

                    .default__check[type='checkbox']~.custom__check:after,
                    .default__check[type='radio']~.custom__check:after {
                      content: '';
                      display: inline-block;
                      position: absolute;
                      transition: all 0.4s ease;
                    }

                    .default__check[type='checkbox']:not(.switchbox)~.custom__check {
                      border-radius: 1.375rem;
                    }

                    .default__check[type='checkbox']:checked~.custom__check,
                    .default__check[type='radio']:checked~.custom__check {
                      background-image: linear-gradient(#02b3bd, #00868e);
                    }

                    .default__check[type='checkbox']:not(.switchbox)~.custom__check,
                    .default__check[type='radio']:not(.switchbox)~.custom__check {
                      justify-content: center;
                      width: 22px;
                    }

                    .default__check[type='checkbox']~.custom__check,
                    .default__check[type='radio']~.custom__check {
                      display: flex;
                      align-items: center;
                      height: 22px;
                      border: 1px solid $globalGreenthemeBG_color;
                      position: relative;
                      transition: all 0.4s ease;
                      cursor: pointer;
                      margin: 0 6px 0 0;
                    }

                    .default__check[type='checkbox']:not(.switchbox)~.custom__check:after {
                      height: 9px;
                      width: 5px;
                      margin-top: -1px;
                      border-bottom: 2px solid #fff;
                      border-right: 2px solid #fff;
                      transform: rotate(45deg);
                    }

                    .default__check[type='checkbox']:not(.switchbox)~.custom__check:after,
                    .default__check[type='radio']:not(.switchbox)~.custom__check:after {
                      visibility: hidden;
                    }

                    .default__check[type='checkbox']:checked~.custom__check:after,
                    .default__check[type='radio']:checked~.custom__check:after {
                      visibility: visible;
                    }

                    .default__check[type='checkbox']~.custom__check:after,
                    .default__check[type='radio']~.custom__check:after {
                      content: '';
                      display: inline-block;
                      position: absolute;
                      transition: all 0.4s ease;
                    }
                  }
                }
              }

              .auth_controlWrapper2 {
                a {
                  text-align: center;
                  font-size: 14px;
                  font-family: $global-font;
                  color: $global-fontColor1;
                  background: $globalGreenthemeBG_color;
                  border: 0;
                  margin: 0 auto;
                  display: table;
                  padding: 10px 40px;
                  border-radius: 100px;
                  position: relative;
                  // bottom: -20px;
                }

                button {
                  text-align: center;
                  font-size: 14px;
                  font-family: $global-font;
                  color: $global-fontColor1;
                  background: $globalGreenthemeBG_color;
                  border: 0;
                  margin: 0 auto;
                  display: block;
                  padding: 10px 40px;
                  border-radius: 100px;
                  position: relative;
                  // bottom: -20px;
                }
              }
            }
          }

          .verification_wrapper {
            height: 90%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;

            h1 {
              text-align: center;
              font-size: 30px;
              font-family: $global-font;
              color: $global-fontBlackcolor;
              font-weight: $bold-weight;
              margin: 30px 0 14px 0;
            }

            p {
              text-align: center;
              font-size: 14px;
              font-family: $global-font;
              color: $global-fontBlackcolorlight;
              margin-bottom: 20px;
            }

            .scannerWrapper {
              position: relative;
              border-radius: 20px;
              overflow: hidden;

              img {
                display: block;
                margin: 0 auto;
                width: 100%;
              }

              &.verification03_pp {
                border-radius: 24px;
                border: 1px solid $globalGreenthemeBG_color;
                overflow: hidden;
                padding: 4px;

                & img {
                  border-radius: 20px;
                }
              }

              &.verification04_pp {
                border-radius: 24px;
                overflow: hidden;
                position: relative;

                a {
                  position: absolute;
                  top: 10px;
                  right: 10px;
                }
              }
            }

            .vari_controlWrapper1 {
              a {
                text-align: center;
                font-size: 14px;
                font-family: $global-font;
                color: $global-fontColor1;
                background: $globalGreenthemeBG_color;
                border: 0;
                margin: 0 auto;
                display: table;
                padding: 10px 40px;
                border-radius: 100px;
                position: relative;
                // bottom: -20px;
              }

              button {
                text-align: center;
                font-size: 14px;
                font-family: $global-font;
                color: $global-fontColor1;
                background: $globalGreenthemeBG_color;
                border: 0;
                margin: 0 auto;
                display: block;
                padding: 10px 40px;
                border-radius: 100px;
                position: relative;
                // bottom: -20px;
              }

              &.vari_controlWrapper3 {
                display: flex;

                .cancelbtn {
                  background: transparent;
                  color: $global-fontBlackcolorlight;
                  border: 1px solid $global-fontBlackcolorlight;
                }

                a {
                  margin: 0 6px;
                }
              }
            }

            .uploadPPWrapper {
              width: 100%;

              .uploadPP_imgHolder {
                width: 100%;
                padding: 0;

                img {
                  border: 2px solid #00b4bd;
                  padding: 4px;
                  border-radius: 16px;
                  margin: 0 auto;
                  display: block;
                  width: 100%;
                }
              }

              .uploadPP_input {
                .btn-tertiary {
                  color: #555;
                  padding: 0;
                  // line-height: 40px;
                  margin: auto;
                  display: block;
                }

                /* input file style */

                .input-file {
                  width: 0.1px;
                  height: 0.1px;
                  opacity: 0;
                  overflow: hidden;
                  position: absolute;
                  z-index: -1;

                  +.js-labelFile {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    padding: 50px 10px;
                    width: 100%;
                    background: #fff;
                    position: relative;
                    cursor: pointer;
                    margin: 0px 0 0 0;
                    border-radius: 20px;
                    border: 1px dashed #00b4bd;

                    &::before {
                      content: '';
                      background: url('../images/upload_icon.svg');
                      background-size: cover;
                      position: absolute;
                      left: 50%;
                      z-index: 999;
                      top: 30%;
                      padding: 10px;
                      background-repeat: no-repeat;
                      transform: translate(-50%, -50%);
                    }

                    &.has-file {
                      .icon:before {
                        //font-awesome
                        content: '\f00c';
                        color: #5aac7b;
                      }
                    }

                    span {
                      font-size: 14px;
                      font-family: $global-font;
                      font-weight: $medium-weight;
                      color: $global-fontBlackcolorlight;
                    }
                  }
                }

                input[type='file'] {}
              }
            }
          }
        }
      }
    }
  }

  .verificationAdj01_height {
    height: 100%;
    flex-direction: column;
  }

  .auth_banner {
    background: url('../images/login_banner.png');
    background-repeat: no-repeat;
    height: 100%;
    background-size: cover;
    border-radius: 20px;
    flex: 2 !important;
    flex-direction: column;
    justify-content: end;
    align-items: flex-start;

    .auth_logoWrapper {
      .auth_logoHolder {
        display: flex;
        padding: 20px;
        align-items: center;

        .animatedLogo {
          svg {
            height: 100px !important;
          }
        }

        h1 {
          color: $global-fontColor1;
          font-size: 30px;
          font-family: $global-font;
          padding: 0 0 0 6px;
        }
      }
    }
  }
}

.chat_sidebarWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;

  .chat_tabs_header {
    width: 100%;
    // padding: 10px 10px 10px 10px;

    ul {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-bottom: 20px;

      li {
        a {
          color: $global-fontBlackcolorlight;
          border: 1px solid #bbbbbb;
          text-align: center;
          font-size: 14px;
          font-family: $global-font;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 !important;
          border-radius: 26px;
          padding: 10px 23px;
          width: 100px !important;
          color: #bbbbbb;

          &.ct_active {
            box-shadow: 0px 0px 10px rgba(1, 124, 130, 0.4);
            background: transparent linear-gradient(180deg, #02b3bd 0%, #00868e 100%) 0% 0% no-repeat padding-box;
            color: #ffffff;
            border: 1px solid $globalGreenthemeBG_color;
          }
        }
      }
    }
  }

  .chat_tabList {
    background: #fff;
    width: 100%;
    margin: 0px 0;
    // border-radius: 20px;
    // padding: 10px 0;

    ul {
      // height: 430px;

      li {
        &.active {
          background: #f4f8f8;
        }

        cursor: pointer;

        .chat_listItem {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          // padding: 10px 0px;

          .chatListAvatar {
            img {
              display: block;
              border-radius: 13px;
            }
          }

          .chatListTextcontent {
            h1 {
              color: #21212c;
              text-align: left;
              // font-size: 14px;
              // font: normal normal medium 14px/16px SF Pro Text;
              // font-family: $global-font;
              // font-weight: $bold-weight;
              // fontFamily: SF Pro Text;
              //     fontSize: 14px;
              //     lineHeight: 16px;
              //     fontStyle: normal;
              font-family: Source Sans Pro, sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
            }

            p {
              color: $global-fontBlackcolorlight;
              text-align: left;
              font-size: 12px;
              font-family: $global-font;
              font-weight: $normal-weight;
              white-space: nowrap;
              width: 230px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          h2 {
            font-size: 9px;
            font-family: $global-font;
            font-weight: $normal-weight;
            color: #bbbbbb;
          }
        }
      }
    }
  }

  .chat_createGroupBTN {
    width: 100%;

    button {
      background: #00ffdc;
      border: 0;
      border-radius: 100px;
      font-size: 14px;
      font-family: $global-font;
      font-weight: $bold-weight;
      padding: 10px 0;
      margin: 0;
      width: 100%;
      display: block;
    }

    a {
      background: #00ffdc;
      border: 0;
      border-radius: 100px;
      font-size: 14px;
      font-family: $global-font;
      font-weight: $bold-weight;
      padding: 10px 0;
      margin: 0;
      width: 100%;
      display: block;
      text-align: center;

      &:hover {
        color: #1d1d25;
      }
    }
  }
}

.chat_createGroupBTN-1 {
  width: 100%;
  // height: 55px;
  margin-top: 20px;

  button {
    // background: #00ffdc;
    border: 0;
    border-radius: 100px;
    font-size: 14px;
    font-family: $global-font;
    font-weight: $bold-weight;
    padding: 10px 0;
    margin: 0;
    width: 100%;
    display: block;
  }

  a {
    background: #00ffdc;
    border: 0;
    border-radius: 100px;
    font-size: 14px;
    font-family: $global-font;
    font-weight: $bold-weight;
    padding: 10px 0;
    margin: 0;
    width: 100%;
    display: block;
    text-align: center;

    &:hover {
      color: #1d1d25;
    }
  }
}

.chat_createGroupBTN-Chat-body {
  width: 194px;
  // height: 94px;

  button {
    background: #00ffdc;
    // border: 0;
    border-radius: 20px 20px 0px 20px;
    font-size: 14px;
    font-family: $global-font;
    font-weight: $bold-weight;
    padding: 10px 0;
    margin: 0;
    width: 100%;
    display: block;
  }

  // minor comment
  a {
    background: #00ffdc;
    border: 0;
    border-radius: 100px;
    font-size: 14px;
    font-family: $global-font;
    font-weight: $bold-weight;
    padding: 10px 0;
    margin: 0;
    width: 100%;
    display: block;
    text-align: center;

    &:hover {
      color: #1d1d25;
    }
  }
}

.img-chat-body {
  height: 105px;
}

.chat-body-web {
  height: 45px;
}

.chat-body-Audio {
  height: 50px;
  width: 150px;
}

.chat_bodyWrapper {
  position: relative;
  background: #fff;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 161px);

  .group_callingWrapper {
    position: absolute;
    box-shadow: 0px 0px 10px #707070;
    background: #fff;
    border-radius: 10px;
    // right: 20px;
    z-index: 999;
    margin-left: 415px;

    .gc_imgWrapper {
      padding: 6px;
      border-radius: 10px;
      border: 1px solid #00b4bd;
      margin: 6px;

      img {
        display: block;
        border-radius: 8px;
      }
    }

    .gc_dailup {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding: 12px;

      a {
        display: block;
      }
    }

    h1 {
      color: #21212c;
      text-align: center;
      font-size: 20px;
      font-family: $global-font;
      font-weight: $bold-weight;
      margin: 30px 0 10px 0;
    }

    p {
      color: $global-fontBlackcolorlight;
      text-align: center;
      font-size: 12px;
      font-family: $global-font;
      font-weight: $bold-weight;
      margin: 14px 0 0px 0;
    }
  }

  .chat_bodyHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: #e6e6e6 1px solid;
    padding: 0px 0 20px 0;

    .chat_bodyAvatar {
      display: inherit;
      align-items: center;

      .chat_bodyAvatarInnerHolder {
        img {
          display: block;
          border-radius: 15px;
        }
      }

      h2 {
        color: #21212c;
        text-align: left;
        font-size: 14px;
        font-family: $global-font;
        font-weight: $bold-weight;
        margin: 0 0 0 14px;
      }
    }

    .chat_HeaderControlsWrapper {
      a {
        border: 0;
        background: transparent;
        padding: 0px 10px;

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }

      button {
        border: 0;
        background: transparent;
        padding: 0px 10px;

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }

      .dropdown {
        padding: 0px 10px;
        display: inline-block;
      }
    }
  }

  .chat_contentWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    // justify-content: space-between;
    padding: 10px 0;

    .chatDateWrapper {
      width: 100%;
      display: flex;
      align-items: center;
      position: relative;
      padding: 0px 0;

      &::after {
        content: '';
        height: 0.4px;
        width: 200px;
        background: #bbbb;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 100;
      }

      h3 {
        color: $global-fontBlackcolorlight;
        text-align: center;
        font-size: 14px;
        font-family: $global-font;
        font-weight: $light-weight;
        background: #fff;
        position: relative;
        padding: 0 10px;
        z-index: 999;
        display: inline-block;
        margin: 0 auto;
      }
    }

    .CC_rowWrapper {
      display: flex;

      .CC_avatarWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 12px 0;

        img {}

        h1 {
          color: #21212c;
          text-align: left;
          font-size: 14px;
          font-family: $global-font;
          font-weight: $medium-weight;
          margin: 0 0 0 10px;
        }
      }

      .chatbubble {
        font-size: 12px;
        font-weight: 500;
        background-color: #91EFDC;
        border-radius: 20px 20px 0 20px;
        padding: 18px 20px 0px 20px;

        .contain_data {
          color: black;
        }

        .time {
          text-align: end;
          margin: 0;
          margin-top: 5px;
          margin-bottom: 5px;
          color: rgb(80, 80, 80);
          font-size: 10px;
        }
      }

      .left_bubble {
        font-size: 12px;
        font-weight: 500;
        background-color: lightgray;
        border-radius: 20px 20px 20px 0px;
        padding: 18px 20px 0px 20px;

        .contain_data {
          color: black;
        }

        .time {
          text-align: end;
          margin: 0;
          margin-top: 5px;
          margin-bottom: 5px;
          color: rgb(80, 80, 80);
          font-size: 10px;
        }
      }

      &.jcLeft {
        justify-content: flex-start;
        padding: 5px;
      }

      &.jcRight {
        justify-content: flex-end;
        padding: 5px;
      }

      &.jcCenter {
        justify-content: center;
      }

      h4 {
        color: $global-fontBlackcolorlight;
        text-align: center;
        font-size: 14px;
        font-family: $global-font;
        font-weight: $light-weight;
        position: relative;
        padding: 0 10px;
        z-index: 999;
        display: inline-block;
        margin: 0 auto;
      }
    }
  }

  .chat_bodyFooter {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    background: #ececec;
    border: 1px solid #bbbbbb;
    padding: 6px 6px 6px 20px;
    border-radius: 50px;

    .chat_footer_inputWrapper {
      width: 70%;

      input[type='text'] {
        border: 0;
        width: 100%;
        text-align: left;
        font-size: 14px;
        font-family: $global-font;
        font-weight: $bold-weight;
        background: transparent;
        height: 38px;
        display: block;
        width: 100%;

        &:focus {
          box-shadow: none;
          outline: none;
        }
      }
    }

    .chat_footerControls {
      button {
        cursor: pointer;
        border: 0;
        background: transparent;
        padding: 0 0 0 30px;
      }
    }
  }
}

.send-icon-1-send {
  padding: 0 0 0 20px;
  cursor: pointer;
}

.chatbodyplacee::placeholder {
  color: #bbbb;
  font-size: 12px;
  margin-left: 30px;
}

.create_screenWrapper {
  // background: #fff;
  // border-radius: 20px !important;
  // padding: 20px 20px 20px  !important;

  &.createforumAdj01 {
    height: 78vh;

    textarea {
      height: 170px;
    }
  }

  &.formsscreenAdj01 {
    margin-top: 20px;
    background: transparent;
    padding-left: 0;
    padding-right: 0;

    select {
      // background: #fff !important;
      padding: 12px 12px;
      font-weight: $semibold-weight;
    }
  }

  .modal-dialog.modal-lg {
    max-width: 600px;
  }

  .modal-content {
    border-radius: 20px;
  }

  h5 {
    color: $global-fontBlackcolor;
    font-size: 20px;
    font-family: $global-font;
    font-weight: $bold-weight;
  }

  button {
    padding: 0;
    // background: #bbbbbb;
    // border-radius: 10px;
  }

  a {
    padding: 0 10px;
    border-radius: 4px;
    border: 1px solid #e7e7e7;
    font-size: 20px;
    margin: 0 6px 0 0;
    background: #f5f5f5;

    &:focus {
      box-shadow: none;
    }
  }

  .modal-header {
    border-bottom: 10px;
    padding: 8px 0;
  }

  .profile_modal_contentWrapper {
    .uploadPPWrapper {
      .uploadPP_imgHolder {
        width: 100%;
        padding: 0;

        img {
          border: 2px solid #00b4bd;
          padding: 4px;
          border-radius: 16px;
          margin: 0 auto;
          display: block;
          width: 100%;
        }
      }

      .uploadPP_input02 {
        .btn-tertiary {
          color: #555;
          padding: 0;
          // line-height: 40px;
          margin: auto;
          display: block;
        }

        /* input file style */

        .input-file {
          width: 0.1px;
          height: 0.1px;
          opacity: 0;
          overflow: hidden;
          position: absolute;
          z-index: -1;

          +.js-labelFile {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 8px 10px 8px 38px;
            width: 100%;
            background: #00b4bd;
            position: relative;
            cursor: pointer;
            // margin: 20px 0 0 0;
            border-radius: 20px;

            &::before {
              content: '';
              background: url('../images/camera_icon.svg');
              position: absolute;
              left: 12px;
              z-index: 999;
              top: 11px;
              padding: 10px;
              background-repeat: no-repeat;
            }

            &.has-file {
              .icon:before {
                //font-awesome
                content: '\f00c';
                color: #5aac7b;
              }
            }

            span {
              font-size: 14px;
              font-family: $global-font;
              font-weight: $medium-weight;
              color: $global-fontColor1;
            }
          }
        }

        input[type='file'] {}
      }
    }

    .profile_form {
      .uploadPP_inputTextRow {
        // margin: 0 0 0px 0;
        // width: 100%  !important;

        label {
          color: $global-fontBlackcolorlight;
          font-size: 14px;
          font-family: $global-font;
          font-weight: $medium-weight;
        }

        input[type='text'] {
          border: 1px solid #e1e1e1;
          border-radius: 10px;
          background: #fbf8f8;
          color: $global-fontBlackcolorlight;
          font-size: 14px;
          font-family: $global-font;
          font-weight: $medium-weight;
        }

        textarea {
          border: 1px solid #e1e1e1;
          border-radius: 10px;
          background: #fbf8f8;
          color: $global-fontBlackcolorlight;
          font-size: 14px;
          font-family: $global-font;
          font-weight: $medium-weight;
          width: 100%;
          padding: 10px;

          &.textareaAdj01 {
            height: 20px;
            width: 100% !important;
          }
        }

        textarea {
          border: 1px solid #e1e1e1;
          border-radius: 10px;
          background: #fbf8f8;
          color: $global-fontBlackcolorlight;
          font-size: 14px;
          font-family: $global-font;
          font-weight: $medium-weight;
          // width: 200%;
          padding: 10px;

          &.textareaAdj01-1 {
            height: 127px !important;
            width: 100% !important;
            margin-top: 0px !important;
          }
        }

        textarea {
          border: 1px solid #e1e1e1;
          border-radius: 10px;
          background: #fbf8f8;
          color: $global-fontBlackcolorlight;
          font-size: 14px;
          font-family: $global-font;
          font-weight: $medium-weight;
          //  width: 200%;
          padding: 10px;

          &.Describe-are-1 {
            height: 88px !important;
            width: 104% !important;
            margin-top: 0px !important;
          }
        }

        .upp_selectWrapper {
          position: relative;

          &::before {
            content: '';
            background: url('../images/drop_down.svg');
            background-repeat: no-repeat;
            position: absolute;
            top: 50%;
            right: -530px;
            background-repeat: no-repeat;
            padding: 4px;
            transform: translate(-50%);
          }

          select {
            border: 1px solid #e1e1e1;
            border-radius: 10px;
            background: #fbf8f8;
            color: $global-fontBlackcolorlight;
            font-size: 14px;
            font-family: $global-font;
            font-weight: $medium-weight;
          }
        }
      }
    }

    .profile_tagWrapper {
      h1 {
        color: $global-fontBlackcolor;
        font-size: 16px;
        font-family: $global-font;
        font-weight: 600;
        margin-bottom: 10px;
      }

      ul {
        li {
          display: inline-block;
          margin: 6px auto;
          padding: 0 6px;

          h2 {
            color: $globalGreenthemeBG_color;
            font-size: 16px;
            font-family: $global-font2;
            font-weight: $bold-weight;
            border-radius: 15px;
            border: 1px solid $globalGreenthemeBG_color;
            display: inline-block;
            padding: 6px 18px;
          }

          h3 {
            color: $global-fontBlackcolorlight;
            font-size: 16px;
            font-family: $global-font;
            font-weight: $bold-weight;
            border-radius: 15px;
            border: 1px solid $global-fontBlackcolorlight;
            display: inline-block;
            padding: 6px 18px;
          }
        }
      }
    }

    .uploadPPWrapper {
      width: 100%;

      .uploadPP_imgHolder {
        width: 100%;
        padding: 0;

        img {
          border: 2px solid #00b4bd;
          padding: 4px;
          border-radius: 16px;
          margin: 0 auto;
          display: block;
          width: 100%;
        }
      }

      .uploadPP_input {
        .btn-tertiary {
          color: #555;
          padding: 0;
          // line-height: 40px;
          margin: auto;
          display: block;
        }

        /* input file style */

        .input-file {
          width: 0.1px;
          height: 0.1px;
          opacity: 0;
          overflow: hidden;
          position: absolute;
          z-index: -1;

          +.js-labelFile {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 66px 10px;
            width: 100%;
            background: #fff;
            position: relative;
            cursor: pointer;
            margin: 0px 0 0 0;
            border-radius: 20px;
            border: 1px dashed #00b4bd;

            &::before {
              content: '';
              background: url('../images/upload_icon.svg');
              background-size: cover;
              position: absolute;
              left: 50%;
              z-index: 999;
              top: 30%;
              padding: 10px;
              background-repeat: no-repeat;
              transform: translate(-50%, -50%);
            }

            &.has-file {
              .icon:before {
                //font-awesome
                content: '\f00c';
                color: #5aac7b;
              }
            }

            span {
              font-size: 14px;
              font-family: $global-font;
              font-weight: $medium-weight;
              color: $globalGreenthemeBG_color;
            }
          }
        }

        input[type='file'] {}
      }
    }

    .cp_uploadImgHolder {
      // position: relative;

      a {
        top: 25px;
        right: 4px;
        position: absolute;
      }

      img {
        display: block;
        width: 100%;
        border-radius: 10px;
      }
    }
  }

  .modal-footer {
    border: 0;
    padding-bottom: 30px;
    padding-top: 0;

    button {
      background: #00b4bd;
      border-radius: 25px;
      padding: 10px 30px;
      color: $global-fontColor1;
      font-size: 14px;
      font-family: $global-font;
      font-weight: $medium-weight;
      border: 0;
      display: block;
      margin: 0 auto;
    }
  }

  .c_selectUserWrapper {
    h1 {
      color: $global-fontBlackcolor;
      // font-size: 16px;
      font: normal normal 600 16px/22px Nunito;
      font-family: $global-font;
      font-weight: $bold-weight;
      margin: 10px 0 0 0;

      span {
        font-weight: $semibold-weight;
        color: $globalGreenthemeBG_color;
      }
    }

    ul {
      display: flex;

      li {
        margin: 10px 10px;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        .cselect_item {
          .ci_avatar {
            position: relative;
            border: 1px solid #40f5cd;
            border-radius: 22px;
            padding: 4px;

            img {
              border-radius: 8px;
            }

            button {
              background: #d30000;
              color: #fff;
              border: 0;
              padding: 4px;
              position: absolute;
              right: -6px;
              bottom: -6px;
              border-radius: 100px;
              height: 22px;
              width: 22px;
              display: flex;
              align-items: center;
              justify-content: center;

              i {
                padding: 0;
                margin: 0;
              }
            }
          }

          h1 {
            color: $global-fontBlackcolor;
            font-size: 14px;
            font-family: $global-font;
            font-weight: $bold-weight;
            margin: 8px 0 0 0;
            text-align: center;
          }
        }
      }
    }
  }

  .c_addUserWrapper {
    h1 {
      color: $global-fontBlackcolor;
      // font-size: 16px;
      font: normal normal 600 16px/22px Nunito;
      font-family: $global-font;
      font-weight: $bold-weight;
      margin: 10px 0 10px 0;
    }

    .caddUserItemWrapper {
      width: 100%;
      background: #fbf8f8;
      border-radius: 10px;
      border: 1px solid #e1e1e1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px;
      margin: 0 0 14px 0;

      .caddUser_avatarItem {
        img {
          border-radius: 8px;
          display: block;
        }
      }

      .caddUserChackbox {
        width: 76%;

        .checkboxWrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0px;
          width: 100%;

          .checkboxWrapper_holder {
            width: 100%;

            label {
              font-size: 14px;
              font-family: $global-font;
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin: 0 0 0px 0px;
              color: $global-fontBlackcolor;
              background: transparent;
              font-size: 14px;
              font-family: $global-font;
              font-weight: $medium-weight;
              cursor: pointer;
              padding: 14px 0px 14px 0px;
              border: 0px solid #e1e1e1;
              border-radius: 20px;
            }

            // input[type="checkbox"]{
            //   margin-right: 10px;
            // }

            // label{
            //   color: #000;
            //   font-size: 14px;
            //   font-family: $global-font;
            //   font-weight: $medium-weight;
            // }

            .check__item label {
              display: flex;
              align-items: center;
              -moz-column-gap: 6px;
              column-gap: 6px;
            }

            .default__check[type='checkbox'] {
              display: none;
            }

            .default__check[type='checkbox']:not(.switchbox)~.custom__check {
              border-radius: 1.375rem;
            }

            .default__check[type='checkbox']:not(.switchbox)~.custom__check:after,
            .default__check[type='radio']:not(.switchbox)~.custom__check:after {
              visibility: hidden;
            }

            .default__check[type='checkbox']~.custom__check:after,
            .default__check[type='radio']~.custom__check:after {
              content: '';
              display: inline-block;
              position: absolute;
              transition: all 0.4s ease;
            }

            .default__check[type='checkbox']:not(.switchbox)~.custom__check {
              border-radius: 1.375rem;
            }

            .default__check[type='checkbox']:checked~.custom__check,
            .default__check[type='radio']:checked~.custom__check {
              background-color: $globalGreenthemeBG_color;
            }

            .default__check[type='checkbox']:not(.switchbox)~.custom__check,
            .default__check[type='radio']:not(.switchbox)~.custom__check {
              justify-content: center;
              width: 22px;
            }

            .default__check[type='checkbox']~.custom__check,
            .default__check[type='radio']~.custom__check {
              display: flex;
              align-items: center;
              height: 22px;
              border: 1px solid $globalGreenthemeBG_color;
              position: relative;
              transition: all 0.4s ease;
              cursor: pointer;
              margin: 0 6px 0 0;
            }

            .default__check[type='checkbox']:not(.switchbox)~.custom__check:after {
              height: 9px;
              width: 5px;
              margin-top: -1px;
              border-bottom: 2px solid #fff;
              border-right: 2px solid #fff;
              transform: rotate(45deg);
            }

            .default__check[type='checkbox']:not(.switchbox)~.custom__check:after,
            .default__check[type='radio']:not(.switchbox)~.custom__check:after {
              visibility: hidden;
            }

            .default__check[type='checkbox']:checked~.custom__check:after,
            .default__check[type='radio']:checked~.custom__check:after {
              visibility: visible;
            }

            .default__check[type='checkbox']~.custom__check:after,
            .default__check[type='radio']~.custom__check:after {
              content: '';
              display: inline-block;
              position: absolute;
              transition: all 0.4s ease;
            }
          }
        }
      }
    }
  }

  .create_screenControls {
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      color: $global-fontBlackcolorlight;
      font-size: 14px;
      font-family: $global-font;
      border: 1px solid $global-fontBlackcolorlight;
      padding: 10px 16px;
      border-radius: 20px;
      margin: 10px 8px;
      width: 180px;
      text-align: center;

      &.primary {
        color: $global-fontColor1;
        background: $globalGreenthemeBG_color;
        border: 1px solid $globalGreenthemeBG_color;
      }
    }
  }

  .cc_uploadRow {
    margin: 20px 0;
  }
}

.connectionSwipmainWrapper {
  .swipe {
    position: absolute;
  }

  .cardContainer {
    width: 90vw;
    max-width: 260px;

    height: 510px;
    margin: 0 auto;
    padding: 0;

    .card_actionWrapper {
      display: flex;
      justify-content: center;
      z-index: 600;
      position: relative;
      bottom: -26rem;
    }
  }

  .card {
    position: relative;
    background-color: #fff;
    width: 80vw;
    max-width: 260px;
    height: 400px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    background-size: cover;
    background-position: center;

    .swip_content {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translate(-50%, -0%);
      width: 80%;
      padding: 0 0 10px 0;

      h1 {
        color: $globalGreenthemeBG_color;
        font-size: 20px;
        font-family: $global-font;
        font-weight: $bold-weight;
        text-align: center;
      }

      h2 {
        color: #fff;
        font-size: 14px;
        font-family: $global-font;
        font-weight: $bold-weight;
        text-align: center;
        margin: 4px 0 20px 0;
      }

      p {
        color: #fff;
        font-size: 12px;
        font-family: $global-font;
        font-weight: $medium-weight;
        text-align: center;
      }
    }
  }

  .cardContent {
    width: 100%;
    height: 100%;
  }

  .swipe:last-of-type {}

  .card h3 {
    position: absolute;
    bottom: 0;
    margin: 10px;
    color: #fff;
  }

  .infoText {
    width: 100%;
    height: 28px;
    justify-content: center;
    display: flex;
    color: #fff;
    animation-name: popup;
    animation-duration: 800ms;
  }

  .buttons {
    margin: 20px;
    display: flex;
  }

  .buttons button {
    flex-shrink: 0;
    padding: 10px;
    border-radius: 5px;
    border: none;
    color: #fff;
    font-size: 18px;
    background-color: #9198e5;
    transition: 200ms;
    margin: 0 10px;
    font-weight: bolder;
    width: 160px;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  }

  .buttons button:hover {
    transform: scale(1.05);
  }

  @keyframes popup {
    0% {
      transform: scale(1, 1);
    }

    10% {
      transform: scale(1.1, 1.1);
    }

    30% {
      transform: scale(0.9, 0.9);
    }

    50% {
      transform: scale(1, 1);
    }

    57% {
      transform: scale(1, 1);
    }

    64% {
      transform: scale(1, 1);
    }

    100% {
      transform: scale(1, 1);
    }
  }
}

.globalPages_tabWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .gb_tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // border: 1px solid red;
    width: 100%;

    // margin: 0px 10px;

    button {
      border: 0;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    a {
      color: #bbbbbb;
      border: 1px solid #bbbbbb;
      text-align: center;
      font-size: 14px;
      font-family: $global-font;
      display: block;
      margin: 0;
      border-radius: 20px;
      padding: 8px;
      font-weight: 600;

      &.ct_active {
        // box-shadow: 0px 0px 10px rgba(1, 124, 130, 0.3);
        background: $globalGreenthemeBG_color;
        color: #ffffff;
        border: 1px solid $globalGreenthemeBG_color;
      }
    }
  }

  .gb_tabs-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // border: 1px solid red;
    width: 90%;
    margin-left: 40px;

    margin: 0px 10px;

    button {
      border: 0;
    }

    &:first-child {
      margin-left: 0px;
    }

    &:last-child {
      margin-right: -60;
    }

    a {
      color: $global-fontBlackcolorlight;
      border: 1px solid $global-fontBlackcolorlight;
      text-align: center;
      font-size: 13px;
      font-family: $global-font;
      display: block;
      margin: 0;
      border-radius: 20px;
      padding: 10px 34px;

      &.ct_active {
        // box-shadow: 0px 0px 10px rgba(1, 124, 130, 0.3);
        background: $globalGreenthemeBG_color;
        color: #ffffff;
        border: 1px solid $globalGreenthemeBG_color;
      }
    }
  }

  .gb_tabs-btn-oder {
    display: flex;

    align-items: center;
    // border: 1px solid red;
    width: 90%;
    margin-left: 40px;

    margin: 0px 10px;

    button {
      border: 0;
    }

    &:first-child {
      margin-left: 0px;
    }

    &:last-child {
      margin-right: -60;
    }

    a {
      color: $global-fontBlackcolorlight;
      border: 1px solid $global-fontBlackcolorlight;
      text-align: center;
      font-size: 13px;
      font-family: $global-font;
      display: block;
      margin: 0;
      border-radius: 20px;
      padding: 10px 34px;

      &.ct_active {
        // box-shadow: 0px 0px 10px rgba(1, 124, 130, 0.3);
        background: $globalGreenthemeBG_color;
        color: #ffffff;
        border: 1px solid $globalGreenthemeBG_color;
      }
    }
  }

  .gb_tabs_ControlsWrapper {
    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;

      li {
        margin: 0px 10px;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        a {
          color: $globalGreenthemeBG_color;
          border: 1px solid $globalGreenthemeBG_color;
          text-align: center;
          font-size: 14px;
          font-family: $global-font;
          display: block;
          margin: 0;
          border-radius: 20px;
          padding: 10px 34px;

          span {
            color: $globalGreenthemeBG_color;
            font-size: 14px;
            font-family: $global-font;
            position: relative;
            margin: 0 0 0 14px;

            &:after {
              content: '';
              width: 4px;
              height: 4px;
              background: #00b4bd;
              position: absolute;
              left: -10px;
              top: 8px;
              border-radius: 100px;
            }
          }

          &.ct_active {
            // box-shadow: 0px 0px 10px rgba(1, 124, 130, 0.4);
            background: #00ffdc;
            color: $global-fontBlackcolor;
            border: 1px solid #00ffdc;
          }
        }
      }
    }
  }
}

.forumsSearchWrapper {
  background: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 25px;
  padding: 10px 15px;

  input[type='text'] {
    border: 0;
    color: $global-fontBlackcolor;
    font-size: 14px;
    font-family: $global-font;
    width: 300px;
    background-color: #ffffff;
  }

  button {
    border: 0;
    background: transparent;
  }
}

.gblThumb_outerWrapper {
  background: #ffffff;
  padding: 20px 20px 0px 20px;
  border-radius: 20px;
  overflow-y: scroll;
  // height: 70vh;
  margin: 20px 0 0 0;

  // custom single scroll bar start
  &::-webkit-scrollbar {
    width: 0.2em;
  }

  // &::-webkit-scrollbar-track {
  //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  // }

  &::-webkit-scrollbar-thumb {
    background-color: white;
    outline: 1px solid white;
  }

  // custom single scroll bar end
}

.gblThumb_outerWrapper {
  background: #ffffff;
  padding: 20px 20px 0px 20px;
  border-radius: 20px;
  overflow-y: scroll;
  // height: 70vh;
  margin: 20px 0 0 0;

  // custom single scroll bar start
  &::-webkit-scrollbar {
    width: 0.2em;
  }

  // &::-webkit-scrollbar-track {
  //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  // }

  &::-webkit-scrollbar-thumb {
    background-color: white;
    outline: 1px solid white;
  }

  // custom single scroll bar end
}

.gblThumb_outerWrapper-1 {
  background: #ffffff;
  padding: 20px 20px 0px 20px;
  border-radius: 20px;
  overflow-y: scroll;
  height: 70vh;
  margin: 20px 0 0 0;

  // custom single scroll bar start
  &::-webkit-scrollbar {
    width: 0.2em;
  }

  // &::-webkit-scrollbar-track {
  //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  // }

  &::-webkit-scrollbar-thumb {
    background-color: white;
    outline: 1px solid white;
  }

  // custom single scroll bar end
}

.gblThumb_outerWrapper-2 {
  background: #ffffff;
  padding: 20px 20px 0px 20px;
  border-radius: 20px;
  overflow-y: scroll;
  height: 70vh;
  margin: 20px 0 0 0;

  // custom single scroll bar start
  &::-webkit-scrollbar {
    width: 0.2em;
  }

  // &::-webkit-scrollbar-track {
  //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  // }

  &::-webkit-scrollbar-thumb {
    background-color: white;
    outline: 1px solid white;
  }

  // custom single scroll bar end
}

.gbl_thumbWrapper04 {
  background: #ffe8e9 !important;
  border: 1px solid #ff3f00;

  .gbl_thumbControlWrapper {
    h1 {
      text-align: left;
      font-size: 16px;
      font-family: $global-font;
      color: $global-fontBlackcolor;
      font-weight: $bold-weight;
      margin: 0 0 10px 0;
    }

    a {
      background: #ffe8e9 !important;
      border: 1px solid #ff3f00;
      color: #ff3f00 !important;
    }
  }
}

.gbl_thumbWrapper02 {
  margin-top: 20px;
  background: #fff !important;
  overflow: unset !important;

  .tooltipInfoWrapper {
    position: relative;
    // right: -20rem;
    // top: 10rem;
    background: #fff;
    border-radius: 20px;
    padding: 20px;
    width: 300px;
    box-shadow: 0 0 2px 0 black;
    z-index: 100000;

    .ttInfoHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 4px 0 10px 0;

      h1 {
        text-align: left;
        font-size: 16px;
        font-family: $global-font;
        color: #1d1d25;
        font-weight: 600;
      }

      a {
        padding: 0 10px;
        border-radius: 4px;
        border: 1px solid #e7e7e7;
        font-size: 20px;
        margin: 0 6px 0 0;
        background: #f5f5f5;
      }
    }

    .ttMapWrapper {
      iframe {
        width: 100%;
      }
    }

    .ttAddressWrapper {
      h1 {
        text-align: left;
        font-size: 16px;
        font-family: $global-font;
        color: #1d1d25;
        font-weight: 600;
        margin: 10px 0 6px 0;
      }

      p {
        text-align: left;
        font-size: 14px;
        font-family: $global-font;
        color: #707070;
        font-weight: 600;
      }
    }
  }
}

.img_thumbWrapper.img_thumbWrapperAdj01>a {
  padding: 7px 8px;
  display: inline-block;
  position: absolute;
  left: 10px;
  top: 10px;
  background: #efefef;
  border-radius: 12px;
}

.gbl_thumbWrapper {
  background: #f5f5f5;
  border-radius: 20px;
  overflow: hidden;
  margin: 0 0 20px 0 !important;
  position: relative;

  .img_thumbWrapper {
    position: relative;
    // padding: 20px;

    &.img_thumbWrapperAdj01 {
      img {
        height: 240px;
        // padding: 20px;
        border-radius: 10px;
      }
    }

    width: 100%;

    .dropdown {
      padding: 0px 2px;
      display: inline-block;
      position: absolute;
      right: 10px;
      top: 10px;
      background: #efefef;
      border-radius: 12px;
    }

    img {
      display: block;
      width: 100%;
      border-radius: 0px 0px 20px 20px;
    }
  }

  .gbl_thumbContent {
    padding: 15px 20px 20px 20px;
    position: relative;

    &.gbl_thumbContent02 {
      h4 {
        margin-top: 10px;
      }
    }

    &:hover+.tooltipInfoWrapper {
      display: block;
    }

    .tooltipdirection {
      position: absolute;
      top: 20px;
      right: 20px;
    }

    h1 {
      color: #1d1d25;
      font-size: 20px;
      font-family: $global-font;
      font-weight: bold;
    }

    h6 {
      color: #1d1d25;
      font-size: 13px;
      font-family: $global-font;
      font-weight: 600;
      margin: 10px 0;

      a {
        color: #1d1d25;
        font-size: 15px;
        font-family: $global-font;
        font-weight: 700;
        margin: 10px 0;
      }
    }

    h2 {
      color: #1d1d25;
      font-size: 16px;
      font-family: $global-font;
      font-weight: 16px;
      margin: 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // border: 1px solid red;
      width: 50%;

      span {
        color: #707070;
        font-size: 16px;
        font-family: $global-font;
        // font-weight: $light-weight;
        margin: 2px 0;
        // float: left;
      }
    }

    h4 {
      color: #707070;
      font-size: 14px;
      font-family: $global-font;
      font-weight: 500;
      margin: 0 0 4px 0;
    }

    .gbl_thumbControlWrapper02 {
      border-top: 1px solid $global-fontColor2;
      padding: 20px 0 0 0;
      display: block !important;
    }

    .gbl_thumbControlWrapper03 {
      align-items: flex-start !important;
      justify-content: flex-start !important;
      margin: 10px 0px 20px 0px;

      p {
        padding: 0 20px 0 0;
      }
    }

    .gbl_thumbControlWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        color: #707070;
        font-size: 14px;
        font-family: $global-font;
        font-weight: 500;
        display: flex;
        align-items: center;

        &.jobPara {
          font-weight: $bold-weight;
          color: $global-fontBlackcolor;
        }

        i {
          margin-right: 6px;
          color: #bbbbbb;

          img {
            display: block;
            margin: 0 8px 0 0;
          }
        }
      }

      a {
        background: $globalGreenthemeBG_color;

        color: #ffffff;
        font-size: 14px;
        font-family: 'Nunito', sans-serif;
        font-weight: 500;
        padding: 8px 10px;
        border-radius: 10px;

        &.eventsthumbBtn {
          padding: 10px 16px !important;
          background: #fff;
          color: #00b4bd;
        }

        .jobbtn {
          background: #00b4bd !important;
        }
      }
    }

    .jobImgThumbWrapper {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #cfcfcf;
      padding: 0 0 20px 0;
      margin: 0 0 20px 0;

      .dropdown {
        button {
          background: #fff;
        }
      }

      .jiInnerWrapper {
        .jii_wrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .jii_InnerWrapper {
            img {
              display: block;
            }
          }

          .jii_TextContent {
            // padding: 0 0 0 20px;

            h1 {
              color: #1d1d25;
              font-size: 16px;
              font-family: $global-font;
              font-weight: $bold-weight;
            }

            p {
              color: $global-fontBlackcolorlight;
              font-size: 13px;
              font-family: $global-font;
              font-weight: $bold-weight;
            }
          }
        }
      }
    }
  }
}

.gbl_thumbWrapper-card-oder {
  background: #f5f5f5;
  border-radius: 20px;
  overflow: hidden;
  margin: 0 0 20px 0;
  position: relative;
  height: 139px;
  // width: 313px;

  .img_thumbWrapper {
    position: relative;

    &.img_thumbWrapperAdj01 {
      img {
        height: 240px;
        border-radius: 20px;
      }
    }

    width: 100%;

    .dropdown {
      padding: 0px 2px;
      display: inline-block;
      position: absolute;
      right: 10px;
      top: 10px;
      background: #efefef;
      border-radius: 12px;
    }

    img {
      display: block;
      width: 100%;
      border-radius: 0px 0px 20px 20px;
    }
  }

  .gbl_thumbContent {
    padding: 15px 20px 20px 40px;
    position: relative;

    &.gbl_thumbContent02 {
      h4 {
        margin-top: 10px;
      }
    }

    &:hover+.tooltipInfoWrapper {
      display: block;
    }

    .tooltipdirection {
      position: absolute;
      top: 20px;
      right: 20px;
    }

    h1 {
      color: #1d1d25;
      font-size: 22px;

      font-family: $global-font;
      font-weight: 800;
    }

    h6 {
      color: #1d1d25;
      font-size: 13px;
      font-family: $global-font;
      font-weight: 700;
      margin: 10px 0;

      a {
        color: #1d1d25;
        font-size: 15px;
        font-family: $global-font;
        font-weight: 700;
        margin: 10px 0;
      }
    }

    h2 {
      color: #1d1d25;
      font-size: 16px;
      font-family: $global-font;
      font-weight: $bold-weight;
      margin: 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // border: 1px solid red;
      width: 50%;

      span {
        color: $global-fontBlackcolorlight;
        font-size: 16px;
        font-family: $global-font;
        font-weight: $light-weight;
        margin: 2px 0;
        float: left;
      }
    }

    h4 {
      color: #707070;
      font-size: 14px;
      font-family: $global-font;
      font-weight: 500;
      margin: 0 0 4px 0;
    }

    .gbl_thumbControlWrapper02 {
      border-top: 1px solid $global-fontColor2;
      padding: 20px 0 0 0;
      display: block !important;
    }

    .gbl_thumbControlWrapper03 {
      align-items: flex-start !important;
      justify-content: flex-start !important;
      margin: 10px 0px 20px 0px;

      p {
        padding: 0 20px 0 0;
      }
    }

    .gbl_thumbControlWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        color: #707070;
        font-size: 14px;
        font-family: $global-font;
        font-weight: 500;
        display: flex;
        align-items: center;

        &.jobPara {
          font-weight: $bold-weight;
          color: $global-fontBlackcolor;
        }

        i {
          margin-right: 6px;
          color: #bbbbbb;

          img {
            display: block;
            margin: 0 8px 0 0;
          }
        }
      }

      a {
        background: $globalGreenthemeBG_color;

        color: #ffffff;
        font-size: 14px;
        font-family: 'Nunito', sans-serif;
        font-weight: 500;
        padding: 8px 10px;
        border-radius: 10px;

        &.eventsthumbBtn {
          padding: 10px 16px !important;
          background: #fff;
          color: #00b4bd;
        }

        .jobbtn {
          background: #00b4bd !important;
        }
      }
    }

    .jobImgThumbWrapper {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #cfcfcf;
      padding: 0 0 20px 0;
      margin: 0 0 20px 0;

      .dropdown {
        button {
          background: #fff;
        }
      }

      .jiInnerWrapper {
        .jii_wrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .jii_InnerWrapper {
            img {
              display: block;
            }
          }

          .jii_TextContent {
            padding: 0 0 0 20px;

            h1 {
              color: #1d1d25;
              font-size: 16px;
              font-family: $global-font;
              font-weight: $bold-weight;
            }

            p {
              color: $global-fontBlackcolorlight;
              font-size: 13px;
              font-family: $global-font;
              font-weight: $bold-weight;
            }
          }
        }
      }
    }
  }

  .gbl_thumbContent-Order-datails {
    padding: 5px 10px 0px 5px;

    position: relative;

    &.gbl_thumbContent02 {
      h4 {
        margin-top: 10px;
      }
    }

    &:hover+.tooltipInfoWrapper {
      display: block;
    }

    .tooltipdirection {
      position: absolute;
      top: 20px;
      right: 20px;
    }

    h1 {
      color: #1d1d25;
      font-size: 22px;

      font-family: $global-font;
      font-weight: 800;
    }

    h6 {
      color: #1d1d25;
      font-size: 13px;
      font-family: $global-font;
      font-weight: 700;
      margin: 10px 0;

      a {
        color: #1d1d25;
        font-size: 15px;
        font-family: $global-font;
        font-weight: 700;
        margin: 10px 0;
      }
    }

    h2 {
      color: #1d1d25;
      font-size: 16px;
      font-family: $global-font;
      font-weight: $bold-weight;
      margin: 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // border: 1px solid red;
      width: 50%;

      span {
        color: $global-fontBlackcolorlight;
        font-size: 16px;
        font-family: $global-font;
        font-weight: $light-weight;
        margin: 2px 0;
        float: left;
      }
    }

    h4 {
      color: #707070;
      font-size: 14px;
      font-family: $global-font;
      font-weight: 500;
      margin: 0 0 4px 0;
    }

    .gbl_thumbControlWrapper02 {
      border-top: 1px solid $global-fontColor2;
      padding: 20px 0 0 0;
      display: block !important;
    }

    .gbl_thumbControlWrapper03 {
      align-items: flex-start !important;
      justify-content: flex-start !important;
      margin: 10px 0px 20px 0px;

      p {
        padding: 0 20px 0 0;
      }
    }

    .gbl_thumbControlWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        color: #707070;
        font-size: 14px;
        font-family: $global-font;
        font-weight: 500;
        display: flex;
        align-items: center;

        &.jobPara {
          font-weight: $bold-weight;
          color: $global-fontBlackcolor;
        }

        i {
          margin-right: 6px;
          color: #bbbbbb;

          img {
            display: block;
            margin: 0 8px 0 0;
          }
        }
      }

      a {
        background: $globalGreenthemeBG_color;

        color: #ffffff;
        font-size: 14px;
        font-family: 'Nunito', sans-serif;
        font-weight: 500;
        padding: 8px 10px;
        border-radius: 10px;

        &.eventsthumbBtn {
          padding: 10px 16px !important;
          background: #fff;
          color: #00b4bd;
        }

        .jobbtn {
          background: #00b4bd !important;
        }
      }
    }

    .jobImgThumbWrapper {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #cfcfcf;
      padding: 0 0 20px 0;
      margin: 0 0 20px 0;

      .dropdown {
        button {
          background: #fff;
        }
      }

      .jiInnerWrapper {
        .jii_wrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .jii_InnerWrapper {
            img {
              display: block;
            }
          }

          .jii_TextContent {
            padding: 0 0 0 20px;

            h1 {
              color: #1d1d25;
              font-size: 16px;
              font-family: $global-font;
              font-weight: $bold-weight;
            }

            p {
              color: $global-fontBlackcolorlight;
              font-size: 13px;
              font-family: $global-font;
              font-weight: $bold-weight;
            }
          }
        }
      }
    }
  }

  .gbl_thumbContentoder-payment {
    padding: 0px 20px 20px 0px;
    position: relative;
    width: 200px;

    &.gbl_thumbContent02 {
      h4 {
        margin-top: 10px;
      }
    }

    &:hover+.tooltipInfoWrapper {
      display: block;
    }

    .tooltipdirection {
      position: absolute;
      top: 20px;
      right: 20px;
    }

    h1 {
      color: #1d1d25;
      font-size: 22px;

      font-family: $global-font;
      font-weight: 800;
    }

    h6 {
      color: #1d1d25;
      font-size: 13px;
      font-family: $global-font;
      font-weight: 700;
      margin: 10px 0;

      a {
        color: #1d1d25;
        font-size: 15px;
        font-family: $global-font;
        font-weight: 700;
        margin: 10px 0;
      }
    }

    h2 {
      color: #1d1d25;
      font-size: 16px;
      font-family: $global-font;
      font-weight: $bold-weight;
      margin: 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // border: 1px solid red;
      width: 50%;

      span {
        color: $global-fontBlackcolorlight;
        font-size: 16px;
        font-family: $global-font;
        font-weight: $light-weight;
        margin: 2px 0;
        float: left;
      }
    }

    h4 {
      color: #707070;
      font-size: 14px;
      font-family: $global-font;
      font-weight: 500;
      margin: 0 0 4px 0;
    }

    .gbl_thumbControlWrapper02 {
      border-top: 1px solid $global-fontColor2;
      padding: 20px 0 0 0;
      display: block !important;
    }

    .gbl_thumbControlWrapper03 {
      align-items: flex-start !important;
      justify-content: flex-start !important;
      margin: 10px 0px 20px 0px;

      p {
        padding: 0 20px 0 0;
      }
    }

    .gbl_thumbControlWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        color: #707070;
        font-size: 14px;
        font-family: $global-font;
        font-weight: 500;
        display: flex;
        align-items: center;

        &.jobPara {
          font-weight: $bold-weight;
          color: $global-fontBlackcolor;
        }

        i {
          margin-right: 6px;
          color: #bbbbbb;

          img {
            display: block;
            margin: 0 8px 0 0;
            width: 200px;
          }
        }
      }

      a {
        background: $globalGreenthemeBG_color;

        color: #ffffff;
        font-size: 14px;
        font-family: 'Nunito', sans-serif;
        font-weight: 500;
        padding: 8px 10px;
        border-radius: 10px;

        &.eventsthumbBtn {
          padding: 10px 16px !important;
          background: #fff;
          color: #00b4bd;
        }

        .jobbtn {
          background: #00b4bd !important;
        }
      }
    }

    .jobImgThumbWrapper {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #cfcfcf;
      padding: 0 0 20px 0;
      margin: 0 0 20px 0;

      .dropdown {
        button {
          background: #fff;
        }
      }

      .jiInnerWrapper {
        .jii_wrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .jii_InnerWrapper {
            img {
              display: block;
            }
          }

          .jii_TextContent {
            padding: 0 0 0 20px;

            h1 {
              color: #1d1d25;
              font-size: 16px;
              font-family: $global-font;
              font-weight: $bold-weight;
            }

            p {
              color: $global-fontBlackcolorlight;
              font-size: 13px;
              font-family: $global-font;
              font-weight: $bold-weight;
            }
          }
        }
      }
    }
  }

  .gbl_thumbContentoder {
    padding: 15px 20px 20px 20px;
    position: relative;

    &.gbl_thumbContent02 {
      h4 {
        margin-top: 10px;
      }
    }

    &:hover+.tooltipInfoWrapper {
      display: block;
    }

    .tooltipdirection {
      position: absolute;
      top: 20px;
      right: 20px;
    }

    h1 {
      color: #1d1d25;
      font-size: 22px;

      font-family: $global-font;
      font-weight: 800;
    }

    h6 {
      color: #1d1d25;
      font-size: 13px;
      font-family: $global-font;
      font-weight: 700;
      margin: 10px 0;

      a {
        color: #1d1d25;
        font-size: 15px;
        font-family: $global-font;
        font-weight: 700;
        margin: 10px 0;
      }
    }

    h2 {
      color: #1d1d25;
      font-size: 16px;
      font-family: $global-font;
      font-weight: $bold-weight;
      margin: 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // border: 1px solid red;
      width: 50%;

      span {
        color: $global-fontBlackcolorlight;
        font-size: 16px;
        font-family: $global-font;
        font-weight: $light-weight;
        margin: 2px 0;
        float: left;
      }
    }

    h4 {
      color: #707070;
      font-size: 14px;
      font-family: $global-font;
      font-weight: 500;
      margin: 0 0 4px 0;
    }

    .gbl_thumbControlWrapper02 {
      border-top: 1px solid $global-fontColor2;
      padding: 20px 0 0 0;
      display: block !important;
    }

    .gbl_thumbControlWrapper03 {
      align-items: flex-start !important;
      justify-content: flex-start !important;
      margin: 10px 0px 20px 0px;

      p {
        padding: 0 20px 0 0;
      }
    }

    .gbl_thumbControlWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        color: #707070;
        font-size: 14px;
        font-family: $global-font;
        font-weight: 500;
        display: flex;
        align-items: center;

        &.jobPara {
          font-weight: $bold-weight;
          color: $global-fontBlackcolor;
        }

        i {
          margin-right: 6px;
          color: #bbbbbb;

          img {
            display: block;
            margin: 0 8px 0 0;
          }
        }
      }

      a {
        background: $globalGreenthemeBG_color;

        color: #ffffff;
        font-size: 14px;
        font-family: 'Nunito', sans-serif;
        font-weight: 500;
        padding: 8px 10px;
        border-radius: 10px;

        &.eventsthumbBtn {
          padding: 10px 16px !important;
          background: #fff;
          color: #00b4bd;
        }

        .jobbtn {
          background: #00b4bd !important;
        }
      }
    }

    .jobImgThumbWrapper {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #cfcfcf;
      padding: 0 0 20px 0;
      margin: 0 0 20px 0;

      .dropdown {
        button {
          background: #fff;
        }
      }

      .jiInnerWrapper {
        .jii_wrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .jii_InnerWrapper {
            img {
              display: block;
            }
          }

          .jii_TextContent {
            padding: 0 0 0 20px;

            h1 {
              color: #1d1d25;
              font-size: 16px;
              font-family: $global-font;
              font-weight: $bold-weight;
            }

            p {
              color: $global-fontBlackcolorlight;
              font-size: 13px;
              font-family: $global-font;
              font-weight: $bold-weight;
            }
          }
        }
      }
    }
  }
}

.gbl_thumbWrapper-card-oder-payment {
  background: #f5f5f5;
  border-radius: 20px;
  overflow: hidden;
  margin: 0 0px 20px 0px;
  position: relative;
  height: 139px;
  width: 252px;

  .img_thumbWrapper {
    position: relative;

    &.img_thumbWrapperAdj01 {
      img {
        height: 240px;
        border-radius: 20px;
      }
    }

    width: 100%;

    .dropdown {
      padding: 0px 2px;
      display: inline-block;
      position: absolute;
      right: 10px;
      top: 10px;
      background: #efefef;
      border-radius: 12px;
    }

    img {
      display: block;
      width: 100%;
      border-radius: 0px 0px 20px 20px;
    }
  }

  .gbl_thumbContent {
    padding: 15px 20px 20px 40px;
    position: relative;

    &.gbl_thumbContent02 {
      h4 {
        margin-top: 10px;
      }
    }

    &:hover+.tooltipInfoWrapper {
      display: block;
    }

    .tooltipdirection {
      position: absolute;
      top: 20px;
      right: 20px;
    }

    h1 {
      color: #1d1d25;
      font-size: 22px;

      font-family: $global-font;
      font-weight: 800;
    }

    h6 {
      color: #1d1d25;
      font-size: 13px;
      font-family: $global-font;
      font-weight: 700;
      margin: 10px 0;

      a {
        color: #1d1d25;
        font-size: 15px;
        font-family: $global-font;
        font-weight: 700;
        margin: 10px 0;
      }
    }

    h2 {
      color: #1d1d25;
      font-size: 16px;
      font-family: $global-font;
      font-weight: $bold-weight;
      margin: 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // border: 1px solid red;
      width: 50%;

      span {
        color: $global-fontBlackcolorlight;
        font-size: 16px;
        font-family: $global-font;
        font-weight: $light-weight;
        margin: 2px 0;
        float: left;
      }
    }

    h4 {
      color: #707070;
      font-size: 14px;
      font-family: $global-font;
      font-weight: 500;
      margin: 0 0 4px 0;
    }

    .gbl_thumbControlWrapper02 {
      border-top: 1px solid $global-fontColor2;
      padding: 20px 0 0 0;
      display: block !important;
    }

    .gbl_thumbControlWrapper03 {
      align-items: flex-start !important;
      justify-content: flex-start !important;
      margin: 10px 0px 20px 0px;

      p {
        padding: 0 20px 0 0;
      }
    }

    .gbl_thumbControlWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        color: #707070;
        font-size: 14px;
        font-family: $global-font;
        font-weight: 500;
        display: flex;
        align-items: center;

        &.jobPara {
          font-weight: $bold-weight;
          color: $global-fontBlackcolor;
        }

        i {
          margin-right: 6px;
          color: #bbbbbb;

          img {
            display: block;
            margin: 0 8px 0 0;
          }
        }
      }

      a {
        background: $globalGreenthemeBG_color;

        color: #ffffff;
        font-size: 14px;
        font-family: 'Nunito', sans-serif;
        font-weight: 500;
        padding: 8px 10px;
        border-radius: 10px;

        &.eventsthumbBtn {
          padding: 10px 16px !important;
          background: #fff;
          color: #00b4bd;
        }

        .jobbtn {
          background: #00b4bd !important;
        }
      }
    }

    .jobImgThumbWrapper {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #cfcfcf;
      padding: 0 0 20px 0;
      margin: 0 0 20px 0;

      .dropdown {
        button {
          background: #fff;
        }
      }

      .jiInnerWrapper {
        .jii_wrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .jii_InnerWrapper {
            img {
              display: block;
            }
          }

          .jii_TextContent {
            padding: 0 0 0 20px;

            h1 {
              color: #1d1d25;
              font-size: 16px;
              font-family: $global-font;
              font-weight: $bold-weight;
            }

            p {
              color: $global-fontBlackcolorlight;
              font-size: 13px;
              font-family: $global-font;
              font-weight: $bold-weight;
            }
          }
        }
      }
    }
  }

  .gbl_thumbContentoder-payment {
    padding: 0px 20px 20px 0px;
    position: relative;
    width: 200px;

    &.gbl_thumbContent02 {
      h4 {
        margin-top: 10px;
      }
    }

    &:hover+.tooltipInfoWrapper {
      display: block;
    }

    .tooltipdirection {
      position: absolute;
      top: 20px;
      right: 20px;
    }

    h1 {
      color: #1d1d25;
      font-size: 22px;

      font-family: $global-font;
      font-weight: 800;
    }

    h6 {
      color: #1d1d25;
      font-size: 13px;
      font-family: $global-font;
      font-weight: 700;
      margin: 10px 0;

      a {
        color: #1d1d25;
        font-size: 15px;
        font-family: $global-font;
        font-weight: 700;
        margin: 10px 0;
      }
    }

    h2 {
      color: #1d1d25;
      font-size: 16px;
      font-family: $global-font;
      font-weight: $bold-weight;
      margin: 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // border: 1px solid red;
      width: 50%;

      span {
        color: $global-fontBlackcolorlight;
        font-size: 16px;
        font-family: $global-font;
        font-weight: $light-weight;
        margin: 2px 0;
        float: left;
      }
    }

    h4 {
      color: #707070;
      font-size: 14px;
      font-family: $global-font;
      font-weight: 500;
      margin: 0 0 4px 0;
    }

    .gbl_thumbControlWrapper02 {
      border-top: 1px solid $global-fontColor2;
      padding: 20px 0 0 0;
      display: block !important;
    }

    .gbl_thumbControlWrapper03 {
      align-items: flex-start !important;
      justify-content: flex-start !important;
      margin: 10px 0px 20px 0px;

      p {
        padding: 0 20px 0 0;
      }
    }

    .gbl_thumbControlWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        color: #707070;
        font-size: 14px;
        font-family: $global-font;
        font-weight: 500;
        display: flex;
        align-items: center;

        &.jobPara {
          font-weight: $bold-weight;
          color: $global-fontBlackcolor;
        }

        i {
          margin-right: 6px;
          color: #bbbbbb;

          img {
            display: block;
            margin: 0 8px 0 0;
            width: 200px;
          }
        }
      }

      a {
        background: $globalGreenthemeBG_color;

        color: #ffffff;
        font-size: 14px;
        font-family: 'Nunito', sans-serif;
        font-weight: 500;
        padding: 8px 10px;
        border-radius: 10px;

        &.eventsthumbBtn {
          padding: 10px 16px !important;
          background: #fff;
          color: #00b4bd;
        }

        .jobbtn {
          background: #00b4bd !important;
        }
      }
    }

    .jobImgThumbWrapper {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #cfcfcf;
      padding: 0 0 20px 0;
      margin: 0 0 20px 0;

      .dropdown {
        button {
          background: #fff;
        }
      }

      .jiInnerWrapper {
        .jii_wrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .jii_InnerWrapper {
            img {
              display: block;
            }
          }

          .jii_TextContent {
            padding: 0 0 0 20px;

            h1 {
              color: #1d1d25;
              font-size: 16px;
              font-family: $global-font;
              font-weight: $bold-weight;
            }

            p {
              color: $global-fontBlackcolorlight;
              font-size: 13px;
              font-family: $global-font;
              font-weight: $bold-weight;
            }
          }
        }
      }
    }
  }

  .gbl_thumbContentoder {
    padding: 15px 20px 20px 20px;
    position: relative;

    &.gbl_thumbContent02 {
      h4 {
        margin-top: 10px;
      }
    }

    &:hover+.tooltipInfoWrapper {
      display: block;
    }

    .tooltipdirection {
      position: absolute;
      top: 20px;
      right: 20px;
    }

    h1 {
      color: #1d1d25;
      font-size: 22px;

      font-family: $global-font;
      font-weight: 800;
    }

    h6 {
      color: #1d1d25;
      font-size: 13px;
      font-family: $global-font;
      font-weight: 700;
      margin: 10px 0;

      a {
        color: #1d1d25;
        font-size: 15px;
        font-family: $global-font;
        font-weight: 700;
        margin: 10px 0;
      }
    }

    h2 {
      color: #1d1d25;
      font-size: 16px;
      font-family: $global-font;
      font-weight: $bold-weight;
      margin: 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // border: 1px solid red;
      width: 50%;

      span {
        color: $global-fontBlackcolorlight;
        font-size: 16px;
        font-family: $global-font;
        font-weight: $light-weight;
        margin: 2px 0;
        float: left;
      }
    }

    h4 {
      color: #707070;
      font-size: 14px;
      font-family: $global-font;
      font-weight: 500;
      margin: 0 0 4px 0;
    }

    .gbl_thumbControlWrapper02 {
      border-top: 1px solid $global-fontColor2;
      padding: 20px 0 0 0;
      display: block !important;
    }

    .gbl_thumbControlWrapper03 {
      align-items: flex-start !important;
      justify-content: flex-start !important;
      margin: 10px 0px 20px 0px;

      p {
        padding: 0 20px 0 0;
      }
    }

    .gbl_thumbControlWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        color: #707070;
        font-size: 14px;
        font-family: $global-font;
        font-weight: 500;
        display: flex;
        align-items: center;

        &.jobPara {
          font-weight: $bold-weight;
          color: $global-fontBlackcolor;
        }

        i {
          margin-right: 6px;
          color: #bbbbbb;

          img {
            display: block;
            margin: 0 8px 0 0;
          }
        }
      }

      a {
        background: $globalGreenthemeBG_color;

        color: #ffffff;
        font-size: 14px;
        font-family: 'Nunito', sans-serif;
        font-weight: 500;
        padding: 8px 10px;
        border-radius: 10px;

        &.eventsthumbBtn {
          padding: 10px 16px !important;
          background: #fff;
          color: #00b4bd;
        }

        .jobbtn {
          background: #00b4bd !important;
        }
      }
    }

    .jobImgThumbWrapper {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #cfcfcf;
      padding: 0 0 20px 0;
      margin: 0 0 20px 0;

      .dropdown {
        button {
          background: #fff;
        }
      }

      .jiInnerWrapper {
        .jii_wrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .jii_InnerWrapper {
            img {
              display: block;
            }
          }

          .jii_TextContent {
            padding: 0 0 0 20px;

            h1 {
              color: #1d1d25;
              font-size: 16px;
              font-family: $global-font;
              font-weight: $bold-weight;
            }

            p {
              color: $global-fontBlackcolorlight;
              font-size: 13px;
              font-family: $global-font;
              font-weight: $bold-weight;
            }
          }
        }
      }
    }
  }
}

.gbl_thumbWrapper-card {
  background: #f5f5f5;
  border-radius: 20px;
  overflow: hidden;
  margin: 0 0 20px 0;
  position: relative;
  height: 249px;
  // width: 313px;

  .img_thumbWrapper {
    position: relative;

    &.img_thumbWrapperAdj01 {
      img {
        height: 240px;
        border-radius: 20px;
      }
    }

    width: 100%;

    .dropdown {
      padding: 0px 2px;
      display: inline-block;
      position: absolute;
      right: 10px;
      top: 10px;
      background: #efefef;
      border-radius: 12px;
    }

    img {
      display: block;
      width: 100%;
      border-radius: 0px 0px 20px 20px;
    }
  }

  .gbl_thumbContent {
    padding: 15px 20px 20px 40px;
    position: relative;

    &.gbl_thumbContent02 {
      h4 {
        margin-top: 10px;
      }
    }

    &:hover+.tooltipInfoWrapper {
      display: block;
    }

    .tooltipdirection {
      position: absolute;
      top: 20px;
      right: 20px;
    }

    h1 {
      color: #1d1d25;
      font-size: 22px;

      font-family: $global-font;
      font-weight: 800;
    }

    h6 {
      color: #1d1d25;
      font-size: 13px;
      font-family: $global-font;
      font-weight: 700;
      margin: 10px 0;

      a {
        color: #1d1d25;
        font-size: 15px;
        font-family: $global-font;
        font-weight: 700;
        margin: 10px 0;
      }
    }

    h2 {
      color: #1d1d25;
      font-size: 16px;
      font-family: $global-font;
      font-weight: $bold-weight;
      margin: 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // border: 1px solid red;
      width: 50%;

      span {
        color: $global-fontBlackcolorlight;
        font-size: 16px;
        font-family: $global-font;
        font-weight: $light-weight;
        margin: 2px 0;
        float: left;
      }
    }

    h4 {
      color: #707070;
      font-size: 14px;
      font-family: $global-font;
      font-weight: 500;
      margin: 0 0 4px 0;
    }

    .gbl_thumbControlWrapper02 {
      border-top: 1px solid $global-fontColor2;
      padding: 20px 0 0 0;
      display: block !important;
    }

    .gbl_thumbControlWrapper03 {
      align-items: flex-start !important;
      justify-content: flex-start !important;
      margin: 10px 0px 20px 0px;

      p {
        padding: 0 20px 0 0;
      }
    }

    .gbl_thumbControlWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        color: #707070;
        font-size: 14px;
        font-family: $global-font;
        font-weight: 500;
        display: flex;
        align-items: center;

        &.jobPara {
          font-weight: $bold-weight;
          color: $global-fontBlackcolor;
        }

        i {
          margin-right: 6px;
          color: #bbbbbb;

          img {
            display: block;
            margin: 0 8px 0 0;
          }
        }
      }

      a {
        background: $globalGreenthemeBG_color;

        color: #ffffff;
        font-size: 14px;
        font-family: 'Nunito', sans-serif;
        font-weight: 500;
        padding: 8px 10px;
        border-radius: 10px;

        &.eventsthumbBtn {
          padding: 10px 16px !important;
          background: #fff;
          color: #00b4bd;
        }

        .jobbtn {
          background: #00b4bd !important;
        }
      }
    }

    .jobImgThumbWrapper {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #cfcfcf;
      padding: 0 0 20px 0;
      margin: 0 0 20px 0;

      .dropdown {
        button {
          background: #fff;
        }
      }

      .jiInnerWrapper {
        .jii_wrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .jii_InnerWrapper {
            img {
              display: block;
            }
          }

          .jii_TextContent {
            padding: 0 0 0 20px;

            h1 {
              color: #1d1d25;
              font-size: 16px;
              font-family: $global-font;
              font-weight: $bold-weight;
            }

            p {
              color: $global-fontBlackcolorlight;
              font-size: 13px;
              font-family: $global-font;
              font-weight: $bold-weight;
            }
          }
        }
      }
    }
  }
}

.gbl_thumbWrapper-card-payment {
  background: #f5f5f5;
  border-radius: 20px;
  overflow: hidden;
  margin: 0 0 20px 0;
  position: relative;
  height: 312px;
  width: 300px;

  .img_thumbWrapper {
    position: relative;

    &.img_thumbWrapperAdj01 {
      img {
        height: 240px;
        border-radius: 20px;
      }
    }

    width: 100%;

    .dropdown {
      padding: 0px 2px;
      display: inline-block;
      position: absolute;
      right: 10px;
      top: 10px;
      background: #efefef;
      border-radius: 12px;
    }

    img {
      display: block;
      width: 100%;
      border-radius: 0px 0px 20px 20px;
    }
  }

  .gbl_thumbContent {
    // padding: 15px 20px 20px 40px;
    position: relative;

    &.gbl_thumbContent02 {
      h4 {
        margin-top: 10px;
      }
    }

    &:hover+.tooltipInfoWrapper {
      display: block;
    }

    .tooltipdirection {
      position: absolute;
      top: 20px;
      right: 20px;
    }

    h1 {
      color: #1d1d25;
      font-size: 22px;

      font-family: $global-font;
      font-weight: 800;
    }

    h6 {
      color: #1d1d25;
      font-size: 13px;
      font-family: $global-font;
      font-weight: 700;
      margin: 10px 0;

      a {
        color: #1d1d25;
        font-size: 15px;
        font-family: $global-font;
        font-weight: 700;
        margin: 10px 0;
      }
    }

    h2 {
      color: #1d1d25;
      font-size: 16px;
      font-family: $global-font;
      font-weight: $bold-weight;
      margin: 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // border: 1px solid red;
      width: 50%;

      span {
        color: $global-fontBlackcolorlight;
        font-size: 16px;
        font-family: $global-font;
        font-weight: $light-weight;
        margin: 2px 0;
        float: left;
      }
    }

    h4 {
      color: #707070;
      font-size: 14px;
      font-family: $global-font;
      font-weight: 500;
      margin: 0 0 4px 0;
    }

    .gbl_thumbControlWrapper02 {
      border-top: 1px solid $global-fontColor2;
      padding: 20px 0 0 0;
      display: block !important;
    }

    .gbl_thumbControlWrapper03 {
      align-items: flex-start !important;
      justify-content: flex-start !important;
      margin: 10px 0px 20px 0px;

      p {
        padding: 0 20px 0 0;
      }
    }

    .gbl_thumbControlWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        color: #707070;
        font-size: 14px;
        font-family: $global-font;
        font-weight: 500;
        display: flex;
        align-items: center;

        &.jobPara {
          font-weight: $bold-weight;
          color: $global-fontBlackcolor;
        }

        i {
          margin-right: 6px;
          color: #bbbbbb;

          img {
            display: block;
            margin: 0 8px 0 0;
          }
        }
      }

      a {
        background: $globalGreenthemeBG_color;

        color: #ffffff;
        font-size: 14px;
        font-family: 'Nunito', sans-serif;
        font-weight: 500;
        padding: 8px 10px;
        border-radius: 10px;

        &.eventsthumbBtn {
          padding: 10px 16px !important;
          background: #fff;
          color: #00b4bd;
        }

        .jobbtn {
          background: #00b4bd !important;
        }
      }
    }

    .jobImgThumbWrapper {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #cfcfcf;
      padding: 0 0 20px 0;
      margin: 0 0 20px 0;

      .dropdown {
        button {
          background: #fff;
        }
      }

      .jiInnerWrapper {
        .jii_wrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .jii_InnerWrapper {
            img {
              display: block;
            }
          }

          .jii_TextContent {
            padding: 0 0 0 20px;

            h1 {
              color: #1d1d25;
              font-size: 16px;
              font-family: $global-font;
              font-weight: $bold-weight;
            }

            p {
              color: $global-fontBlackcolorlight;
              font-size: 13px;
              font-family: $global-font;
              font-weight: $bold-weight;
            }
          }
        }
      }
    }
  }
}

.mapWrapper {
  iframe {
    width: 100%;
    margin: 30px 0 0 0;
    border-radius: 10px;
    height: 120px;
  }
}

.forumListWrapper {
  background: #fff;
  border-radius: 20px;
  padding: 20px;
  height: 55vh;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0.2em;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #009299;
    outline: 1px solid slategrey;
  }

  .flInnerItemsWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 0 20px 0;
    padding: 0 0 20px 0;
    border-bottom: 1px solid #d3d3d3;

    &:last-child {
      margin-bottom: 0;
    }

    .fl_section01 {
      img {
        display: block;
        border-radius: 18px;
      }
    }

    .fl_section02 {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      padding: 0 0 0 20px;

      .fl_userTitle {
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
          border-radius: 10px;
          display: block;
        }

        h1 {
          font-family: $global-font;
          color: $global-fontBlackcolor;
          font-size: 14px;
          font-weight: $semibold-weight;
          margin: 0 0 0 10px;
        }
      }

      p {
        font-family: $global-font;
        color: $global-fontBlackcolor;
        font-size: 14px;
        font-weight: $semibold-weight;
        padding: 14px 0;
      }

      .fl_controlsWrapper {
        ul {
          display: flex;
          justify-content: space-between;
          align-items: center;

          li {
            &.active {

              i,
              h4 {
                color: $globalGreenthemeBG_color;
              }
            }

            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px 0 0;

            i {
              color: #707070;
            }

            h4 {
              font-family: 'Nunito', sans-serif;
              color: #707070;
              font-size: 14px;
              font-weight: 600;
              margin: 0 0 0 6px;
            }
          }
        }
      }
    }

    .fl_section03 {
      .dropdown button {
        background: #efefef;
        border-radius: 8px;
        padding: 8px 15px;
      }
    }
  }
}

.offersThumbWrapper {
  background: rgb(255, 167, 190);
  background: radial-gradient(circle,
      rgba(255, 167, 190, 1) 0%,
      rgba(209, 177, 233, 1) 58%,
      rgba(241, 203, 187, 1) 100%);
  border-radius: 20px;
  overflow: hidden;
  margin: 0 0 20px 0;
  position: relative;
  padding: 46px 40px;

  h1 {
    font-size: 15px;
    color: $global-fontBlackcolor;
    text-align: center;
    font-weight: $bold-weight;
  }

  .couponWrapper {
    background: url('../images/cuponBG.svg') no-repeat;
    background-position: center;
    background-size: cover;
    // justify-content: space-between;
    align-items: center;
    padding: 11px 0;
    width: 216px;
    margin: 20px auto;

    .codeTitle {
      h2 {
        font-family: $global-font;
        color: $global-fontBlackcolor;
        font-size: 14px;
        margin-left: 18px;
      }
    }

    .accordion-button {
      background-image: none !important;
    }



    .codeapplied {
      // margin: 0px 20px;
      display: flex;
      justify-content: space-between;

      h1 {
        font-family: $global-font;
        color: $global-fontBlackcolor;
        font-size: 10px;
        margin-top: 4px;
      }

      a {
        i {
          color: $globalGreenthemeBG_color;
          bottom: unset !important;
          margin-right: 26px
        }
      }
    }
  }

  .swaipWrapper {
    background: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 0 10px;
    width: 220px;
    margin: 0 auto;

    a {
      font-family: $global-font;
      color: $global-fontBlackcolor;
      font-size: 14px;
      font-weight: $bold-weight;
    }

    img {
      display: block;
    }
  }
}

.pages_screenWrapper {
  background: #fff;
  border-radius: 20px;
  padding: 20px 20px 0px 20px;

  .gbl_jumbotron {
    background: rgb(0, 134, 142);
    background: linear-gradient(0deg,
        rgba(0, 134, 142, 1) 0%,
        rgba(2, 179, 189, 1) 100%);
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding: 10px 20px;
    border-radius: 20px;

    h1 {
      font-family: $global-font;
      color: #fff;
      font-size: 20px;
      font-weight: $bold-weight;
      display: flex;
      align-items: center;
    }

    img {
      display: block;
    }
  }

  .gbl_paraWrapper {
    padding: 20px 0;

    p {
      font-family: $global-font;
      color: #141314;
      font-size: 16px;
      font-weight: $normal-weight;
      text-align: justify;
      padding: 0 0 20px 0;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

.contactusTronWrapper {
  margin-top: 20px;

  img {
    display: block;
    margin: 0 auto;
  }
}

.baloonThumbWrapper01 {
  background: #ebebeb url('../images/baloon_01.svg');
  background-repeat: no-repeat;
  background-position: 0px 10px;

  &.active01 {
    background: #009299 url('../images/baloon_01.svg') no-repeat 10px 10px !important;

    h1,
    h2,
    p {
      color: #fff !important;
    }
  }
}

.baloonThumbWrapper02 {
  background: #ebebeb url('../images/baloon_02.svg');
  background-repeat: no-repeat;
  background-position: 10px 10px;

  &.active02 {
    background: #009299 url('../images/baloon_02.svg') no-repeat 10px 10px !important;

    h1,
    h2,
    p {
      color: #fff !important;
    }
  }
}

.baloonThumbWrapper03 {
  background: #ebebeb url('../images/baloon_03.svg');
  background-repeat: no-repeat;
  background-position: 10px 10px;

  &.active03 {
    background: #009299 url('../images/baloon_03.svg') no-repeat 10px 10px !important;

    h1,
    h2,
    p {
      color: #fff !important;
    }
  }
}

.baloonThumbWrapper04 {
  background: #ebebeb url('../images/baloon_04.svg');
  background-repeat: no-repeat;
  background-position: 0px 10px;

  &.active04 {
    background: #009299 url('../images/baloon_04.svg') no-repeat 0px 10px !important;

    h1,
    h2,
    p {
      color: #fff !important;
    }
  }
}

.baloonThumbWrapper {
  border-radius: 20px;
  padding: 20px 20px 0px 20px;
  position: relative;

  h1 {
    text-align: center;
    font-family: $global-font;
    color: $globalGreenthemeBG_color;
    font-size: 20px;
    font-weight: $bold-weight;
    padding-top: 159px;
    position: relative;

    &::after {
      position: absolute;
      content: '';
      width: 100px;
      background: #bbbbbb;
      height: 1px;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 20px);
    }

    span {
      font-size: 30px;
    }
  }

  h2 {
    text-align: center;
    font-family: $global-font;
    color: #2d2d37;
    font-size: 18px;
    font-weight: $normal-weight;
    padding: 40px 0 21px 0;
  }

  p {
    text-align: center;
    font-family: $global-font;
    color: #707070;
    font-size: 14px;
    font-weight: $normal-weight;
    padding: 0 50px;
  }

  a {
    text-align: center;
    font-family: $global-font;
    color: #141314;
    font-size: 16px;
    font-weight: $bold-weight;
    background: #40f5cd;
    border-radius: 20px;
    display: block;
    margin: 40px auto 0 auto;
    padding: 10px 0;
    position: relative;
    bottom: -20px;
  }

  .aWizardRow {
    width: auto;
    position: absolute;
    right: 10px;
    top: 10px;

    .checkboxWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0px;
      width: 100%;

      .checkboxWrapper_holder {
        width: 100%;

        label {
          font-size: 14px;
          font-family: $global-font;
          // display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0 0 0px 0px;
          color: $global-fontBlackcolor;
          background: #ffffff;
          font-size: 14px;
          font-family: $global-font;
          font-weight: $medium-weight;
          cursor: pointer;
          padding: 0;
          border: 0px solid #e1e1e1;
          border-radius: 20px;
        }

        // input[type="checkbox"]{
        //   margin-right: 10px;
        // }

        // label{
        //   color: #000;
        //   font-size: 14px;
        //   font-family: $global-font;
        //   font-weight: $medium-weight;
        // }

        .check__item label {
          display: flex;
          align-items: center;
          -moz-column-gap: 6px;
          column-gap: 6px;
        }

        .default__check[type='checkbox'] {
          display: none;
        }

        .default__check[type='checkbox']:not(.switchbox)~.custom__check {
          border-radius: 1.375rem;
        }

        .default__check[type='checkbox']:not(.switchbox)~.custom__check:after,
        .default__check[type='radio']:not(.switchbox)~.custom__check:after {
          visibility: hidden;
        }

        .default__check[type='checkbox']~.custom__check:after,
        .default__check[type='radio']~.custom__check:after {
          content: '';
          display: inline-block;
          position: absolute;
          transition: all 0.4s ease;
        }

        .default__check[type='checkbox']:not(.switchbox)~.custom__check {
          border-radius: 1.375rem;
        }

        .default__check[type='checkbox']:checked~.custom__check,
        .default__check[type='radio']:checked~.custom__check {
          background-color: $globalGreenthemeBG_color;
        }

        .default__check[type='checkbox']:not(.switchbox)~.custom__check,
        .default__check[type='radio']:not(.switchbox)~.custom__check {
          justify-content: center;
          width: 22px;
        }

        .default__check[type='checkbox']~.custom__check,
        .default__check[type='radio']~.custom__check {
          display: flex;
          align-items: center;
          height: 22px;
          border: 1px solid $globalGreenthemeBG_color;
          position: relative;
          transition: all 0.4s ease;
          cursor: pointer;
          margin: 0 0px 0 0;
        }

        .default__check[type='checkbox']:not(.switchbox)~.custom__check:after {
          height: 9px;
          width: 5px;
          margin-top: -1px;
          border-bottom: 2px solid #fff;
          border-right: 2px solid #fff;
          transform: rotate(45deg);
        }

        .default__check[type='checkbox']:not(.switchbox)~.custom__check:after,
        .default__check[type='radio']:not(.switchbox)~.custom__check:after {
          visibility: hidden;
        }

        .default__check[type='checkbox']:checked~.custom__check:after,
        .default__check[type='radio']:checked~.custom__check:after {
          visibility: visible;
        }

        .default__check[type='checkbox']~.custom__check:after,
        .default__check[type='radio']~.custom__check:after {
          content: '';
          display: inline-block;
          position: absolute;
          transition: all 0.4s ease;
        }
      }
    }
  }
}

.baloonScreenWrapper {
  padding: 20px 20px 40px 20px !important;
}

.search {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 400px;
  height: 50px;
  transform: translateX(-50%) translateY(-50%);

  &__toggle:checked {
    ~.search__field {
      width: calc(100% - 80px);
      border-width: 2px;

      .search__input {
        &::placeholder {
          opacity: 1;
        }
      }

      .search__close {
        left: -25px;
      }

      .search__button {
        right: 5px;
        width: 35px;
        height: 35px;
        border-radius: 50px;

        &--toggle {
          display: none;
        }

        &--submit {
          display: flex;
        }
      }
    }
  }

  &__close {
    position: relative;
    top: 50%;
    left: 0;
    transform: translateX(-50%);
    transition: 0.3s;

    &::before,
    &::after {
      position: absolute;
      display: block;
      content: '';
      width: 15px;
      height: 3px;
      background-color: #fff;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  &__field {
    position: absolute;
    right: 0;
    left: 50%;
    width: 40px;
    height: 45px;
    border: 0px solid #fff;
    border-radius: 50px;
    transform: translateX(-50%);
    transform-origin: right;
    transition: 0.3s;
  }

  &__input {
    position: absolute;
    left: 0;
    box-sizing: border-box;
    width: calc(100% - 40px);
    height: 100%;
    padding: 5px 15px;
    color: white;
    background-color: transparent;
    border: 0;
    outline: none;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;

    &::placeholder {
      color: white;
      opacity: 0;
      transition: 0.3s;
      transition-delay: 0.3s;
    }
  }

  &__label {
    cursor: pointer;
  }

  &__button {
    position: absolute;
    top: 50%;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: #fff;
    border-radius: 5px;
    transform: translateY(-50%);
    cursor: pointer;
    transition: 0.3s;

    &--submit {
      display: none;
      border: 0;
    }
  }

  &__icon {
    position: relative;
    width: 19px;
    height: 19px;

    &::before {
      display: block;
      content: '';
      width: 12px;
      height: 12px;
      background-color: transparent;
      border: 2px solid #0099ff;
      border-radius: 50px;
    }

    &::after {
      position: absolute;
      right: 0;
      bottom: 0;
      display: block;
      content: '';
      width: 8px;
      height: 2px;
      background-color: #0099ff;
      transform: rotate(45deg);
      transform-origin: right;
    }
  }
}

//////////////////////// connection Css ////////////////////////////////

.connectionSwip-card {
  padding: 0;
  border-radius: 22px;
  background: transparent linear-gradient(180deg, #02b3bd 0%, #00868e 100%) 0% 0% no-repeat padding-box;

  .connection-top-section {
    background-color: #f5f5f5;
    box-shadow: 0 0 2px 0 black;
    border-radius: 20px;

    .image-name-con {
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      //  text-align: center;
      img {
        height: 83px;
        width: 83px;
        margin-right: 30px;
        border-radius: 20px;
      }
    }

    .connection-matual {
      text-align: center;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .connection-botton {
    text-align: center;
    padding: 10px;
    font-style: normal normal bold 15px/20px Nunito;
    background-color: #00868e;
    border-radius: 0px 0px 20px 20px;

    .grp-btn {
      color: white;
      font-weight: 400;
    }
  }
}

.profile-input-details-model {
  width: 300px;

  label {
    color: $global-fontBlackcolorlight;
    // font-size: 14px;
    // font: normal normal normal 14px/19px Nunito;
    font-family: $global-font;
    font-weight: $medium-weight;
  }

  input[type='text'] {
    border: 1px solid #e1e1e1;
    border-radius: 10px;
    background: #fbf8f8;
    color: black;
    font-size: 14px;
    font-family: $global-font;
    font-weight: 700;
  }

  select {
    border: 1px solid #e1e1e1;
    border-radius: 10px;
    color: $global-fontBlackcolorlight;

    font-size: 14px;
    font-family: $global-font;
    font-weight: $medium-weight;
  }
}

.profile-input-details-text-are {
  // width: 300px;
  border: none;

  label {
    color: $global-fontBlackcolorlight;
    font-size: 14px;
    font-family: $global-font;
    font-weight: $medium-weight;
  }

  input[type='text'] {
    border: 1px solid #e1e1e1;
    border-radius: 10px;
    background: #fbf8f8;
    color: black;
    font-size: 14px;
    font-family: $global-font;
    font-weight: 700;
  }

  textarea {
    border: 1px solid #e1e1e1;
    border-radius: 10px;
    color: $global-fontBlackcolorlight;
    width: 300px;
    font-size: 14px;
    font-family: $global-font;
    font-weight: $medium-weight;
  }
}

input[type='text'] {
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  background: #fbf8f8;
  color: black;
  font-size: 14px;
  font-family: $global-font;
  font-weight: 700;
}

select {
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  color: $global-fontBlackcolorlight;

  font-size: 14px;
  font-family: $global-font;
  font-weight: $medium-weight;
}

.usa-btn {
  display: flex;
  justify-content: center;
  align-items: center;

  .primary {
    color: $global-fontBlackcolorlight;
    font-size: 14px;
    font-family: $global-font;

    padding: 10px 16px;
    border-radius: 30px;
    margin: 10px 8px;
    width: 180px;
    text-align: center;
    background-color: #00b4bd;
    color: white;
    // border: 1px solid red;
    // border: 1px solid red;
  }

  .fir {
    color: $global-fontBlackcolorlight;
    font-size: 14px;
    font-family: $global-font;
    border: 1px solid $global-fontBlackcolorlight;
    padding: 10px 16px;
    border-radius: 30px;
    margin: 10px 8px;
    width: 180px;
    text-align: center;
  }
}

.usa-btn-model {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 129px;

  .primary {
    color: $global-fontBlackcolorlight;
    font-size: 14px;
    font-family: $global-font;

    padding: 10px 16px;
    border-radius: 30px;
    margin: 10px 8px;
    width: 180px;
    text-align: center;
    background-color: #00b4bd;
    color: white;
    // border: 1px solid red;
    // border: 1px solid red;
  }

  .fir {
    color: $global-fontBlackcolorlight;
    font-size: 14px;
    font-family: $global-font;
    border: 1px solid $global-fontBlackcolorlight;
    padding: 10px 16px;
    border-radius: 30px;
    margin: 10px 8px;
    width: 180px;
    text-align: center;
  }
}

.usa-btn-adrees {
  display: flex;
  justify-content: center;
  align-items: center;

  .primary {
    color: $global-fontBlackcolorlight;
    font-size: 14px;
    font-family: $global-font;

    padding: 10px 16px;
    border-radius: 20px;
    margin: 10px 8px;
    width: 180px;
    text-align: center;
    background-color: #00b4bd;
    color: white;
    margin-bottom: 300px;
    // border: 1px solid red;
    // border: 1px solid red;
  }

  .fir {
    color: $global-fontBlackcolorlight;
    font-size: 14px;
    font-family: $global-font;
    border: 1px solid $global-fontBlackcolorlight;
    padding: 10px 16px;
    border-radius: 20px;
    margin: 10px 8px;
    width: 180px;
    text-align: center;
  }
}

.main-slider {
  .slider-top {
    width: 300px;
    display: flex;
    justify-content: space-between;
  }
}

.frnd-botm {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  .fir-frnd {
    display: flex;

    img {
      height: 40px;

      border-radius: 10px;
    }

    p {
      margin-left: 20px;
      color: black;
      align-items: center;
      margin-top: 10px;
    }
  }

  .chaticon {
    margin-top: 10px;
  }
}

.upp_selectWrapper {
  select {
    border: 1px solid #e1e1e1;
    border-radius: 10px;
    // background: #fbf8f8;
    color: #707070;
    font-size: 14px;
    font-family: 'Nunito', sans-serif;
    font-weight: 500;
    background-color: #fbf8f8;
  }

  input {
    border: 1px solid #e1e1e1;
    border-radius: 10px;
    background: #fbf8f8;
    color: #707070;
    font-size: 14px;
    font-family: 'Nunito', sans-serif;
    font-weight: 500;
  }
}

.usalabel {
  .form-label {
    color: #707070;
    font-size: 14px;
    font-family: 'Nunito', sans-serif;
    font-weight: 500;
  }
}

.bost-btn {
  font-size: 11px;
}

//////////////////// Setting Page Css //////////

.SettingMainListing {
  .mainTab {
    width: 40%;
    height: 80vh;

    .settingtabs {
      margin-bottom: 10px;
      text-align: left;
      height: 50px;
      background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
      color: #21212c;
      font-size: 16px;
      background-color: white;
      border-radius: 20px;
    }
  }

  .nav-pills .nav-link.active,
  .nav-pills .show>.nav-link {
    border-radius: 1px solid var(--unnamed-color-00b4bd);
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #00b4bd;
    border-radius: 20px;
    width: 98%;
  }

  .settingdataviwe {
    width: 60%;
    height: 80vh;
    padding: 20px 20px;
    background-color: white;
    border-radius: 20px;
  }

  .settingform {
    padding: 5px 5px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-left: 50px;
    padding-right: 50px;
  }

  .settingfilds {
    width: 100%;

    button {
      background-color: white;
      border: 1px solid #ced4da;
      border-left: none;
    }

    .btn-check:active+.btn-outline-secondary,
    .btn-check:checked+.btn-outline-secondary,
    .btn-outline-secondary.active,
    .btn-outline-secondary.dropdown-toggle.show,
    .btn-outline-secondary:active {
      color: #21212c;
      background-color: transparent;
      border-color: none;
      border: none;
    }
  }

  .FBheading {
    p {
      color: #21212c;
      font-size: 16px;
      padding-bottom: 20px;

      color: #21212c;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .FBmessage1 {
    label {
      color: #707070;
      font-size: 14px;
      margin-bottom: 10px;
    }

    textarea {
      width: 100%;
      height: 20vh;
      border: 1px solid #bbbbbb;
      border-radius: 20px;
      padding-left: 20px;
      padding-top: 10px;
    }

    textarea::placeholder {
      color: #bbbbbb;
      font-size: 14px;
    }
  }

  .MIbtn {
    width: 100%;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    height: 50px;
    align-items: center;
    border: none;
    padding-left: 0px;
    padding-right: 50px;
    color: #21212c;
    font-size: 16px;
    border-bottom: 2px solid #e9e9e9;
    margin-top: 20px;
  }
}

.SettingMainListing-job-volunteer {
  .mainTab {
    width: 1100vh;
    height: 50vh;

    .settingtabs {
      margin: 10px;
      text-align: left;
      height: 50px;
      background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
      color: #21212c;
      font-size: 16px;
      background-color: white;
      border: 1px solid #e1e1e1;
      border-radius: 20px;
    }
  }

  .nav-pills .nav-link.active,
  .nav-pills .show>.nav-link {
    border-radius: 1px solid var(--unnamed-color-00b4bd);
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #00b4bd;
    border-radius: 20px;
    // width: 98%;
  }

  .settingdataviwe {
    width: 60%;
    height: 80vh;
    padding: 20px 20px;
    background-color: white;
    border-radius: 20px;
  }

  .settingform {
    padding: 5px 5px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-left: 50px;
    padding-right: 50px;
  }

  .settingfilds {
    width: 100%;

    button {
      background-color: white;
      border: 1px solid #ced4da;
      border-left: none;
    }

    .btn-check:active+.btn-outline-secondary,
    .btn-check:checked+.btn-outline-secondary,
    .btn-outline-secondary.active,
    .btn-outline-secondary.dropdown-toggle.show,
    .btn-outline-secondary:active {
      color: #21212c;
      background-color: transparent;
      border-color: red;
      border: none;
    }
  }

  .FBheading {
    p {
      color: #21212c;
      font-size: 16px;
      margin-bottom: 20px;
    }
  }

  .FBmessage1 {
    label {
      color: #707070;
      font-size: 14px;
      margin-bottom: 10px;
    }

    textarea {
      width: 150%;
      height: 20vh;
      border: 1px solid #bbbbbb;
      border-radius: 20px;
      padding-left: 20px;
      padding-top: 10px;
    }

    textarea::placeholder {
      color: #bbbbbb;
      font-size: 14px;
    }

    button {
      position: relative;
      right: -115%;
      bottom: 60px;
      padding: 5px;
      width: 30%;
      color: white;
      background-color: #00b4bd;
      border-radius: 10px;
      border: none;
    }
  }

  .MIbtn {
    width: 100%;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    height: 50px;
    align-items: center;
    border: none;
    padding-left: 0px;
    padding-right: 50px;
    color: #21212c;
    font-size: 16px;
    border-bottom: 2px solid #e9e9e9;
    margin-top: 20px;
  }
}

///////////////// Setting About page ///////////////////

.setabtmain {
  width: 100%;
  height: 80vh;
  padding: 20px;

  .mainabt1 {
    display: flex;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 5px;
    // padding-top: 5px;
    margin-bottom: 10px;
    align-items: center;
    background: transparent linear-gradient(180deg, #02b3bd 0%, #00868e 100%) 0% 0% no-repeat padding-box;
    border-radius: 10px;

    p {
      color: white;
      font-size: 20px;
    }
  }

  .mainabt2 {
    p {
      font-size: 16px;
      color: #21212c;
    }
  }
}

.btn-check:checked+.btn-outline-danger {
  border: none;
  border-radius: 20px;
  background-color: transparent;
  border-color: transparent;
  color: #00b4bd;
  border: 1px solid #00b4bd;
  box-shadow: none;
}

.btn-outline-danger {
  color: $global-fontBlackcolorlight;
  font-size: 16px;
  font-family: $global-font;
  font-weight: $bold-weight;
  border-radius: 15px;
  border: 1px solid $global-fontBlackcolorlight;
  display: inline-block;
  padding: 6px 18px;
}

.btn-outline-danger:hover {
  background-color: white;
  color: $global-fontBlackcolorlight;
  border: 1px solid #00b4bd;
}

// ashmer code

// .nav-pills .nav-link.active,

// .nav-pills .show>.nav-link {

//   color: #120f0f;

//   background: #FFFFFF 0% 0% no-repeat padding-box;

//  border: 1px solid #E1E1E1;

//   // width: 308px;

//   height: 50px;

//   border-radius: 20px;

//     opacity: 1;

// }

.gb_tabs-1 {
  display: flex;

  justify-content: space-between;

  align-items: center;

  // border: 1px solid red;

  width: 100%;

  margin: 0px 10px;

  button {
    border: 0;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  a {
    color: $global-fontBlackcolorlight;

    // border: 1px solid $global-fontBlackcolorlight;

    border: 1px solid #00b4bd;

    text-align: center;

    font-size: 13px;

    font-family: $global-font;

    display: block;

    margin: 0;

    border-radius: 20px;

    padding: 10px 34px;

    &.ct_active {
      // box-shadow: 0px 0px 10px rgba(1, 124, 130, 0.3);

      background: $globalGreenthemeBG_color;

      color: #ffffff;

      border: 1px solid $globalGreenthemeBG_color;
    }
  }
}

.gb_tabs-2 {
  display: flex;

  justify-content: space-between;

  align-items: center;

  // border: 1px solid red;

  width: 100%;

  margin: 0px 10px;

  button {
    border: 0;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  a {
    color: $global-fontBlackcolorlight;

    // border: 1px solid $global-fontBlackcolorlight;

    border: 1px solid #00b4bd;

    text-align: center;

    font-size: 13px;

    font-family: $global-font;

    display: block;

    margin: 0;

    border-radius: 20px;

    // padding: 10px 34px;

    &.ct_active {
      // box-shadow: 0px 0px 10px rgba(1, 124, 130, 0.3);

      background: $globalGreenthemeBG_color;

      color: #ffffff;

      border: 1px solid $globalGreenthemeBG_color;
    }
  }
}

//////////////////////////////////// Ashmer/////////////////////////////////////////////////////////////////////////
.right-check input {
  position: absolute;
  left: 260px;
  top: -17px;
}

.create_screenWrapper .profile_modal_contentWrapper .profile_form .uploadPP_inputTextRow .upp_selectWrapper::before {
  content: '';
  background: url(http://localhost:3000/static/media/drop_down.d71fc32….svg);
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  right: 530px;
  background-repeat: no-repeat;
  padding: 4px;
  transform: translate(-50%);
}

.radio-custom {
  opacity: 0;

  position: absolute;
}

.radio-custom,
.radio-custom-label {
  display: inline-block;

  vertical-align: middle;

  // margin: 5px;

  cursor: pointer;
}

.radio-custom-label {
  position: relative;
}

.radio-custom+.radio-custom-label:before {
  content: '';

  background: #fff;

  border: 2px solid #ddd;

  display: inline-block;

  width: 20px;

  height: 20px;

  position: absolute;

  left: -10px;

  top: -7px;
}

.radio-custom+.radio-custom-label:before {
  border-radius: 50%;
}

.radio-custom:checked+.radio-custom-label:before {
  // content: "\f00c";

  font-family: 'FontAwesome';

  position: absolute;

  border: 1px solid #f5f5f5;

  background: #54dc58;
}

.radio-custom:checked+.radio-custom-label::after {
  content: '';

  border: 2px solid white;

  width: 10px;

  height: 5px;

  position: absolute;

  left: -5px;

  top: -1px;

  border-top: none;

  border-right: none;

  transform: rotate(-45deg);
}

@import url('./event.css');

.offcanvas-end {
  margin: 20px !important;
  border-radius: 24px !important;
  padding: 20px !important;
  width: 338px;
  --bs-backdrop-zindex: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 0;
}

.offcanvas.show {
  margin: 20px !important;
  border-radius: 24px !important;
  padding: 20px !important;
  width: 338px;
}

.chatListTextcontent {
  margin-left: 14px;
  margin-top: 6px;
}

.chat_sidebarWrapper .chat_tabList ul::-webkit-scrollbar {
  display: none;
}

.connection-card-wrapper {
  background-color: white;
  padding: 20px;
  border-radius: 24px !important;
}

.chat_sidebarWrapper-card {
  //  height: 53vh;
  // width: 50vh;
}

.connection-card-wrapper-body-web {
  background-color: white;
  padding: 0px 0;
  border-radius: 24px !important;
}

.connection-card-wrapper-1 {
  background-color: white;
  padding: 10px;
  border-radius: 24px !important;
}

.connectionSwip-card .connection-top-section {
  border: 0 !important;
}

.connection-top-section {
  border: 0px !important;
}

.chat_sidebarWrapper {
  height: 100%;
  width: 100%;
}

.custom-wrapper-height {
  height: calc(100vh - 137px);
}

.chat_sidebarWrapper .chat_tabList {
  height: 80%;
  overflow: auto;
}

.chat_sidebarWrapper .chat_tabList::-webkit-scrollbar {
  display: none;
}

// .connectionSwip-card{
//   margin-top: 10px !important;
//   margin-left: 6px !important;
//   margin-right: 6px !important;
//   width: 32%;

// }
.card-t {
  font-size: 18px;
  font-weight: bold;
}

.connectionSwip-card .connection-top-section {
  box-shadow: none;
}

.image-name-con {
  padding: 8px !important;
}

.connectionSwip-card .connection-top-section .image-name-con {
  justify-content: start !important;
}

.connectionSwip-card .connection-top-section .image-name-con img {
  height: 92px !important;
  width: 96px !important;
}

.gblThumb_outerWrapper {
  // height: calc(105vh - 199.5px) !important;
}

.gblThumb_outerWrapper-2 {
  height: calc(100vh - 199.5px) !important;
}

.gblThumb_outerWrapper-1 {
  height: calc(100vh - 199.5px) !important;
}

.connection-card-wrapper {
  height: calc(100vh - 138px);
  overflow: auto;
  //  height: 415px;
  width: 355px;
}

.connection-card-wrapper-body-web {
  height: calc(100vh - 290px) !important;
  overflow: auto;
  //  height: 415px;
  width: 355px;
}

// .connection-card-wrapper-body-web-chat {
//   height: calc(78vh - 0px);
//   overflow: auto;
//   //  height: 415px;
//   // width: 355px;

// }
.connection-card-wrapper-1 {
  height: calc(100vh - 156px);
  overflow: auto;
  //  height: 415px;
  width: 355px;
  // margin: 3px;
}

.connection-card-wrapper-1::-webkit-scrollbar {
  display: none;
}

.connection-card-wrapper-body-web::-webkit-scrollbar {
  display: none;
}

.connection-card-wrapper-body-web-chat::-webkit-scrollbar {
  display: none;
}

.modal-backdrop {
  background-color: black;
}

.create_screenWrapper {
  // height: calc(82vh - 10px)
  // !important;
  overflow: auto;
}

.formsscreenAdj01 {
  height: auto !important;
}

.forumListWrapper {
  height: 59vh;
  overflow: auto;
}

.forumListWrapper .flInnerItemsWrapper .fl_section03 .dropdown button {
  padding: 4px 15px !important;
}

.upp_selectWrapper select {
  background-color: white;
  // width: 253px !important
}

.upp_selectWrapper-block-1 select {
  background-color: white;
}

.upp_selectWrapper input {
  background-color: white;
}

.cp-select {
  background-color: #fbf8f8 !important;
}

.upp_selectWrapper input {
  background-color: #fbf8f8 !important;
}

.mtop {
  margin-top: 0px !important;
}

.btn-tertiary {
  margin-bottom: 4px;
}

.create_screenWrapper .profile_modal_contentWrapper .uploadPPWrapper .uploadPP_input .input-file+.js-labelFile {
  padding: 57px 10px !important;
}

.reduce-margin {
  margin-top: 3px !important;
}

///////////////////////// stepper/////////////////

.step {
  padding: 10px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  background-color: cream;
}

.v-stepper {
  position: relative;
  /*   visibility: visible; */
}

/* regular step */
.step .circle {
  background-color: white;
  border: 3px solid gray;
  border-radius: 100%;
  width: 20px;
  /* +6 for border */
  height: 20px;
  display: inline-block;
}

.step .line {
  top: 23px;
  left: 12px;
  /*   height: 120px; */
  height: 100%;

  position: absolute;
  border-left: 3px solid gray;
}

.step.completed .circle {
  visibility: visible;
  background-color: rgb(6, 150, 215);
  border-color: rgb(6, 150, 215);
}

.step.completed .line {
  border-left: 3px solid rgb(6, 150, 215);
}

.step.active .circle {
  visibility: visible;
  border-color: rgb(6, 150, 215);
}

.step.empty .circle {
  visibility: hidden;
}

.step.empty .line {
  /*     visibility: hidden; */
  /*   height: 150%; */
  top: 0;
  height: 150%;
}

.step:last-child .line {
  border-left: 3px solid white;
  z-index: -1;
  /* behind the circle to completely hide */
}

// .content {
//   margin-left: 20px;
//   display: inline-block;
// }

.modal-title-block {
  // padding: 20px 0px 5px 20px;
  font-size: 20px;
  font-family: $global-font;
  color: #21212c;
}

.modal-body-user-1 {
  font-size: 14px;
  font-family: $global-font;
  letter-spacing: 0px;
  color: #707070;
}

.upp_selectWrapper-block-1 {
  position: relative;

  &::before {
    content: '';
    background: url('../images/drop_down.svg');
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    right: 10px;
    background-repeat: no-repeat;
    padding: 4px;
    transform: translate(-50%);
  }

  select {
    border: 1px solid #e1e1e1;
    border-radius: 10px;
    background: #fbf8f8;
    color: $global-fontBlackcolorlight;
    font-size: 14px;
    font-family: $global-font;
    font-weight: $medium-weight;
  }
}

.connection-p-model {
  color: #707070;
  font-size: 14px;
  font-family: $global-font;
}

.modal-backdrop {
  //  display: none !important;
}

#help {
  margin-left: 50%;
}

.modal-backdrop {
  //  display: none !important;
}

body.modal-open {
  overflow: visible;
}

.time1 {
  color: #bbbbbb;

  font-family: $global-font;
  // font-weight: $medium-weight;
  color: var(--unnamed-color-bbbbbb);
  text-align: left;
  font-size: 10px;
  letter-spacing: 0px;
  color: #bbbbbb;
  opacity: 1;
  margin-top: 18px;
}

//shahzeb
//  .search-my-web{
//    width: 100%;
// ashmer minor

//  }
.aboutSection .abt_labelSection ul li {
  margin-bottom: 10px;
}

.up-img-wrapper {
  margin-top: 0px !important;
}

///////////////////////////////////////// Uploed//@at-root/////////////////////////////////////////////////////////

.ce-input-1 {
  background-color: #fbf8f8;
  border: 1px solid #e1e1e1;
  width: 100%;
  height: 40px;
  display: block;
  border-radius: 10px;
  padding: 0 20px;
  color: #1d1d25 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  margin-top: 10px;
  margin-bottom: 20px;
}

.ce-input-1::placeholder {
  color: #bbbbbb;
}

.custom-icon-image-1 {
  text-align: center;
}

.ce-label {
  color: #707070;
  font-size: 14px !important;
  font-weight: normal !important;
}

.ce-lg-input {
  height: 80px;
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
}

.ce-map {
  height: 80px;
  padding: 0 !important;
}

.ce-center {
  text-align: center !important;
}

.ce-upload-1 {
  height: 130px;
  border: 2px dashed #00b4bd !important;
  position: relative;
}

.custom-file-1 input {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.custom-icon-image-1 {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cancel {
  background-color: transparent;
  border: 1px solid #00b4bd;
  width: 160px;
  height: 50px;
  border-radius: 25px;
  color: #00b4bd;
  font-size: 14px;
  font-weight: 600;
}

.upload-icon-1 {
  height: 20px;
  width: 20px;
}

.custom-icon-image-1 p {
  color: #bbbbbb;
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px;
}

.uimage-1 {
  color: #00b4bd !important;
  font-size: 14px !important;
  font-weight: bold !important;
}

.uploaded-image-1 {
  position: relative;
}

.uploaded-image-1 img {
  width: 100% !important;
  height: 148px !important;
  object-fit: cover;
  border-radius: 10px;
}

.close-mark-1 {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: white;
  display: block;
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.header-container {
  height: fit-content !important;
}

.dropdown-menu {
  width: fit-content !important;
  border-radius: 10px;
}

.dropdown-menu li {
  width: 100% !important;
  display: flex;
  justify-content: center;
}

.dropdown-menu li a {
  width: fit-content !important;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #ececec;
}

.dropdown-menu li a:last-child {
  border-bottom: none;
}

.gbl_thumbWrapper .gbl_thumbContent {
  // padding: 20px !important;
}

.SettingMainListing .settingform {
  padding: 0 !important;
}

.tab-pane {
  padding: 0 !important;
  width: 100% !important;
}

.setting-text {
  position: relative;
}

.setting-send {
  position: absolute;
  bottom: 20px;
  right: 10px;
  background-color: #00b4bd;
  padding: 6px 32px;
  border: none;
  border-radius: 20px;
  color: white;
  font-size: 16px;
  font-weight: 600;
}

.MIbtn div p {
  color: #21212c;
  font-size: 16px;
  font-weight: 600;
}

.MIbtn {
  display: flex !important;
  align-items: center;
  height: 62px !important;
  padding: 0 !important;
  margin: 0 !important;
}

.frnd-botm {
  display: flex;
  align-items: center;
}

.chaticon {
  display: flex;
  align-items: center !important;
  justify-content: end;
  margin: 0 !important;
  color: #1d1d25;
  font-size: 14px;
  font-weight: 600;
}

.frnd-botm .fir-frnd p {
  margin: 0 !important;
  color: #1d1d25;
  font-size: 14px;
  font-weight: 600;
}

.fir-frnd {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.b-contacts,
.b-contacts-counts {
  color: #21212c;
  font-size: 16px;
  font-weight: bold;
}

.c-thumbnial {
  position: relative;
  background-color: #00da5a;
  height: 200px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.back {
  height: 34px;
  width: 34px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  position: absolute;
  top: 20px;
  left: 20px;
}

.gridList {
  display: grid;
  grid-gap: 10px;
}

.grid-item {
  width: 100% !important;
}

.thin-scroll li {
  padding: 19px 20px;
  margin: 0 !important;
}

.thin-scroll li:hover {
  background-color: #f4f8f8;
}

.chat_createGroupBTN-1 a {
  height: 50px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.cx-list li a {
  padding: 10px 16px;
}

.dropdown-menu.show {
  min-width: 80px !important;
  // transform: translate3d(-74px, 0px, 0px) !important;
}

.dropdown-menu {
  padding: 10px;
}

.dropdown-menu li {
  border-bottom: 1px solid #e2e2e2;
  padding: 4px 0;
}

.dropdown-menu li:last-child {
  border: none !important;
}

.ip-label {
  position: relative;
  margin-bottom: 10px;
}

.ip-label label {
  width: 100%;
  background-color: white;
  height: 50px;
  border: 1px solid #e1e1e1;
  border-radius: 25px;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.ip-label input {
  display: none;
}

.ip-label input:checked+label {
  border: 1px solid #00b4bd;
}

.ip-label label {
  color: #21212c;
  font-size: 16px;
  font-weight: normal;
}

.ip-label input:checked+label::after {
  content: url('../images/check.svg');
  width: 18px;
  height: 18px;
  border-radius: 9px;
  background-image: linear-gradient(#02b3bd, #00868e);
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  border: none;
}

.ip-label label::after {
  content: url('');
  width: 18px;
  height: 18px;
  border-radius: 9px;
  border: 1px solid #bbbbbb;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.cr-check-list div:last-child {
  margin: 0 !important;
}

.modal-dialog {
  margin: auto !important;
}

.offersThumbWrapper .couponWrapper {
  display: flex !important;
  // justify-content:  center;
  column-gap: 20px;
}

.offersThumbWrapper .couponWrapper {
  width: 100% !important;
  // max-width: 215px !important;
}

// .nav-pills .nav-link.active,
// .nav-pills .show>.nav-link {
//   // width:100px;
//   height: 40px;
//   background-image: linear-gradient(#02B3BD, #00868E);
//   border-radius: 20px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   color: #ffffff;
//   font-size: 14px;
//   font-weight: 600;
// }

.offersThumbWrapper .swaipWrapper {
  width: 100% !important;
  max-width: 215px !important;
  position: relative;
  height: 27px !important;
}

.offersThumbWrapper .swaipWrapper img {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.gbl_thumbWrapper {
  position: relative;
}

.heart-check {
  position: absolute;
  top: 20px;
  right: 20px;
  width: fit-content;
  z-index: 1;
}

.heart-check input {
  display: none;
}

.label-text:before {
  content: url('../images/dslike.svg');
}

.heart-check input:checked+.label-text::before {
  content: url('../images/likeh.svg');
}

.gbl_thumbWrapper {
  margin-bottom: 0 !important;
}

.row-gap-20 {
  row-gap: 20px !important;
}

.gblThumb_outerWrapper-2 {
  padding: 20px !important;
  height: fit-content !important;
}

.friendSectionWrapper .friendSectionScroll .friendSectionItem .fs_details .fs_control ul li:first-child {
  padding: 0px !important;
}

.thin-scroll li {
  padding: 0 !important;
}

.house-radio input {
  display: none;
}

.house-radio label {
  border: 1px solid #bbbbbb;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border-radius: 10px;
  color: #21212c;
  font-size: 14px;
  font-weight: normal;
}

.house-radio input:checked+label {
  border-color: #00b4bd;
}

// accordian

.accordion-item {
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;
  margin-bottom: 30px;
}

.accordion-item.collapsed {
  max-height: 0;
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}

.accordion-title {
  font-size: 16px;
  font-weight: normal !important;
  height: 50px;
  color: #21212c;
  padding: 0.5em 20px;
  border: solid 1px #ccc;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid currentColor;
  }

  &:hover,
  &.open {
    color: black;
  }

  &.open {
    border-color: #00b4bd;
  }

  &.open {
    &::after {
      content: '';
      border-top: 0;
      border-bottom: 4px solid;
    }
  }
}

.accordion-content {
  box-shadow: 0 !important;
  border: 0 !important;
  background-color: white;
  border-radius: 20px !important;
  padding: 14px 20px !important;
}

.accordion-item {
  box-shadow: 0 !important;
  border-radius: 20px !important;
  border: none;
  margin-top: 10px;
}

.accordion-item label:last-child {
  margin: 0;
}

// accordian ends

//accordian option starts
.pf-option {
  position: relative;
  margin-bottom: 20px;
}

.accordion-content .pf-option:last-child {
  margin: 0;
}

.pf-option label {
  width: 100%;
  background-color: white;

  display: flex;
  align-items: center !important;
}

.pf-option input {
  display: none;
}

.pf-option label {
  color: #21212c;
  font-size: 16px;
  font-weight: 600;
}

.pf-option input:checked+label::after {
  content: url('../images/check.svg');
  width: 18px;
  height: 18px;
  border-radius: 9px;
  background-image: linear-gradient(#02b3bd, #00868e);
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  border: none;
}

.pf-option label::after {
  content: url('');
  width: 18px;
  height: 18px;
  border-radius: 9px;
  border: 1px solid #bbbbbb;
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
}

.pf-search {
  width: 100%;
  height: 50px;
  border-radius: 25px !important;
  background-color: #f0f0f0 !important;
  border: none !important;
  color: #21212c;
  font-size: 16px !important;
  font-weight: normal !important;
  padding: 0 40px 0 20px !important;
}

.pf-search::after {
  width: 20px;
  height: 20px;
  background-color: black;
}

.pf-search::placeholder {
  color: #21212c;
  font-size: 16px;
  font-weight: normal;
}

.input-cover {
  position: relative;
}

.input-cover img {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
}

// accordian option end

// search result start
.search-results {
  margin-top: 10px;
  // height: 162px;
}

.pf-body {
  height: 362px !important;
  overflow: auto;
}

.pf-body::-webkit-scrollbar {
  display: none;
}

.search-result {
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  display: flex;
  column-gap: 20px;
}

.search-result img {
  width: 74px;
  height: 74px;
  object-fit: cover;
  border-radius: 10px;
}

.search-result span {
  color: #21212c;
  font-size: 16px;
  font-weight: normal;
}

.pf-heading {
  color: #1d1d25;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.pf-label {
  color: #707070;
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 10px;
}

.globalPages_tabWrapper .gb_tabs a {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.search-input {
  position: relative;
  width: 183px;
  height: 40px;
}

.search-input input {
  // width: 100%;
  height: 100%;
  padding: 0 40px 0 20px;
  background-color: #ffffff;
  border: 1px solid #e1e1e1;
  color: black;
  font-size: 16px;
  font-weight: 600;
  border-radius: 20px;
}

.search-input input::placeholder {
  color: #bbbbbb;
}

.search-input img {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.profile_header .ph_section01 .ph_profilePhoto_details ul>li {
  margin-top: 0 !important;
}

.intrest-list li label {
  min-width: 95px !important;
}

.connection-top-section hr {
  background-color: #e5e5e5;
  height: 2px;
  margin: 0 20px !important;
}

.cx-list li a span {
  padding: 0 0 0 22px !important;
}

.aboutSection .abt_labelSection ul li a {
  min-width: 114px !important;
}

.profile_header .ph_section02 a {
  // background-color: transparent !important;
}

.reportPop.show {
  // transform: translate3d(1095px, 8px, 0px) !important;
  transform: translate(984px, 10px) !important;
}

// details heart start
.heart-check2 {
  position: absolute;
  top: 20px;
  right: 20px;
  width: fit-content;
  z-index: 1;
}

.heart-check2 input {
  display: none;
}

.heart-check2 .label-text:before {
  content: url('../images/dslike2.svg');
}

.heart-check2 input:checked+.label-text::before {
  content: url('../images/likeh2.svg');
}

// details heart ends

.gb_tabs::-webkit-scrollbar {
  display: none;
}

.detail-item-20 {
  padding: 0 0 18px 0;
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 16px;
}

.primary-text {
  color: #21212c;
  font-size: 16px;
  font-weight: 600;
}

.secondary-text {
  color: '#707070';
  font-size: 14px;
  font-weight: 600;
}

.color-radio input {
  display: none;
}

.color-radio label {
  height: 20px;
  width: 20px;
  border-radius: 4px;
  transition: 0.05s;
}

.color-radio input:checked+label {
  outline: 1px solid #00b4bd !important;
  outline-offset: 2px;
}

.color-container {
  display: flex;
  column-gap: 20px;
  margin-top: 20px;
}

// size radios starts
.size-container {
  column-gap: 20px;
  // margin-left: 10px;
  margin: 20px auto 0;
}

.size-radio {
  max-width: 45px;
  padding: 0;

  margin-top: 0;
}

.size-radio input {
  display: none;
}

.size-radio label {
  background-color: transparent;
  border: 1px solid #00b4bd;
  border-radius: 8px;
  color: #707070;
  font-size: 14px;
  font-weight: 600;
  max-width: 45px;
  width: 100%;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.size-radio input:checked+label {
  background-image: linear-gradient(#02b3bd, #00868e) !important;
  border: none;
  color: white;
}

// size radio ends

.last-no-border div:last-child {
  border: none;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.details {
  max-height: 450px;
  overflow: auto;
}

.details::-webkit-scrollbar {
  display: none;
}

#dining-back {
  position: absolute !important;
  top: 30 !important;
  left: 10px !important;
}

.dining-ver {
  position: absolute !important;
  top: 30 !important;
  right: 10px !important;
}

// .nav-pills .nav-link{
//   // width:100px;
//   height: 40px;
//   background-color: transparent;
//   border-radius: 20px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border: 1px solid #BBBBBB;
//   color:#BBBBBB;
//   font-size: 14px;
//   font-weight: 600;
// }
// .nav-pills .nav-link.active, .nav-pills .show > .nav-link{
//   // width:100px;
//   height: 40px;
//   background-image: linear-gradient(#02B3BD, #00868E);
//   border-radius: 20px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   color:#ffffff;
//   font-size: 14px;
//   font-weight: 600;
// }

// group chatlist starts

.list-container {
  background-color: white;
  border-radius: 20px;
  max-height: calc(100vh - 286px);
  overflow-y: auto;
  padding-bottom: 15px !important;
}

.list-item {
  display: flex;
  column-gap: 14px;
  align-items: center;
  padding: 20px;
}

.list-item:hover {
  background-color: #f4f8f8;
}

.list-item img {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  object-fit: cover;
}

.name-msg {
  flex: 1;
}

.chat_bodyWrapper .chat_bodyFooter {
  height: 60px;
  background-color: #ececec;
  border-color: #bbbbbb !important;
  margin-top: 5px;
}

.chat_footer_inputWrapper input::placeholder {
  color: #c5c4c3;
  font-size: 12px;
  font-weight: normal;
}

.border-radius {
  border-radius: 20px !important;
}

.create_screenWrapper .c_addUserWrapper .caddUserItemWrapper .caddUserChackbox .checkboxWrapper .checkboxWrapper_holder .default__check[type='checkbox']:checked~.custom__check,
.create_screenWrapper .c_addUserWrapper .caddUserItemWrapper .caddUserChackbox .checkboxWrapper .checkboxWrapper_holder .default__check[type='radio']:checked~.custom__check {
  background-image: linear-gradient(#02b3bd, #00868e);
}

.add-check input {
  // display: none;
}

.add-check label {
  background-color: #fbf8f8;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  width: 100%;
  padding: 10px;
}

.add-check>input:checked+.label::after {
  content: url('');
  width: 20px;
  height: 20px;
  background-color: #000;
  right: 20px;
}

// ip-label2-starts
.ip-label2 {
  position: relative;
  margin-bottom: 10px;
}

.ip-label2 label {
  width: 100%;
  background-color: #fbf8f8;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 10px;
}

.ip-label2 input {
  display: none;
}

.ip-label2 label {
  color: #21212c;
  font-size: 16px;
  font-weight: normal;
}

.ip-label2 input:checked+label::after {
  content: url('../images/check.svg');
  width: 18px;
  height: 18px;
  border-radius: 9px;
  background-image: linear-gradient(#02b3bd, #00868e);
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  border: none;
}

.ip-label2 label::after {
  content: url('');
  width: 18px;
  height: 18px;
  border-radius: 9px;
  border: 1px solid #bbbbbb;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

// ip-label2-ends

.add-input::placeholder {
  color: #bbbbbb;
  font-size: 14px;
  font-weight: 600;
}

.add-input label {
  margin-bottom: 10px;
}

.SettingMainListing .nav-pills .nav-link.active,
.SettingMainListing .nav-pills .show>.nav-link {
  height: 50px !important;
  width: 100% !important;
  color: black !important;
  font-size: 16px !important;
  font-weight: 600;
}

.Loader-1 {
  margin-left: 140px !important;
  margin-top: -90px !important;
}

.scrolling::-webkit-scrollbar {
  display: none;
}

.errmsgshow {
  margin-left: 170px;
  margin-bottom: 30px;
  color: red;
}

.SettingMainListing .nav-pills .nav-link.active,
.SettingMainListing .nav-pills .show>.nav-link,
.SettingMainListing .mainTab .settingtabs {
  display: flex;
  justify-content: start;
}

.SettingMainListing .mainTab .settingtabs {
  border: none;
}

.conf-div {
  position: relative;
}

.conf-div img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
}

.conf-div input::placeholder {
  color: #707070;
  font-size: 14px;
  font-weight: normal;
}

.box {
  width: 40px;
  height: 50px;
  background-color: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #707070 !important;
  font-size: 14px !important;
  font-weight: normal !important;
  box-shadow: 0 0 20px 5px #e1e1e1;
  text-align: center;
}

.showbox {
  display: block;
}

.no-show {
  display: none !important;
}

////////////////////////////////////////api ui /////////////////////////
.intrest-item {
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-items: center;
}

.intrest-item input {
  display: none;
  width: 100%;
}

.intrest-item span {
  border: 1px solid #bbbbbb;
  color: #21212c;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  width: 100%;
  display: block;
  border-radius: 10px;
  height: 40px;
  max-width: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.intrest-item .icheck:checked+.i-label-text {
  border-color: #00868e;
  color: #00868e;
}

.mmmmm {
  width: 30px !important;
}

.content-main::-webkit-scrollbar {
  display: none;
}

.conf-div {
  display: flex;
  align-items: center;
}

.card-container::-webkit-scrollbar {
  display: none;
}

.fc-wrap::-webkit-scrollbar {
  display: none;
}

.f-input-wrapper input::placeholder {
  color: #c5c4c3;
  font-size: 12px;
  font-weight: normal;
}

.f-input-wrapper {
  ul{
    background-color: white !important;
  }
  input {
    color: #000000;
    font-size: 12px;
    font-weight: normal;
  }
}

.pac-container {
  z-index: 99999999 !important;
}

.event-address {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  width: 50%;
}

.offer-tab-ul {
  display: flex;
  justify-content: space-between;
}

// .nav-pills .tab-button.active{
//   background-image: linear-gradient(#000000,#000000) !important;
// }


.nav-pills {
  --bs-nav-pills-border-radius: 0.375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #00868E;
}

.tab-btn {
  background-color: transparent !important;
  height: 40px !important;
  width: 130px !important;
  border-radius: 20px !important;
  border: 1px solid #bbbbbb !important;
  color: #bbbbbb !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.tab-btn.active {
  background-image: linear-gradient(#02b3bd, #00868e) !important;
  border: none !important;
  color: white !important;
}

.content {
  overflow: hidden !important;
  // width:170px !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
}

.PhoneInputCountrySelect option {
  background-color: white;
  color: 'black';
}

.PhoneInputCountrySelect option:hover {
  background-color: #009299 !important;
}

.atm-card {
  background-image: linear-gradient(to right, #9c1c0b, #9e60bf);
  height: 126px;
  border-radius: 10px;
  padding: 20px;
}

.black-card {
  background-image: linear-gradient(to right, #b5b1b1, #8f8989) !important;
}

.comments-container {
  height: 385px;
  overflow: auto;
}

.atm-card {
  // background-image: linear-gradient(to right, #9c1c0b, #9e60bf);

  background-image: linear-gradient(to right, #b5b1b1, #8f8989);

  height: 126px;

  border-radius: 10px;

  padding: 20px;
}

.black-card {
  background-image: linear-gradient(to right, #b5b1b1, #8f8989) !important;
}

.comments-container {
  height: 385px;

  overflow: auto;
}

#atm-card {
  width: 100%;
}

.atm-radio {
  display: none;
}

.atm-radio:checked~label {
  background-image: linear-gradient(to right, #9c1c0b, #9e60bf) !important;

  outline: 2px solid #03b2cb;

  outline-offset: 2px;
}

.myContent {
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 20px;
}

.changeColor {
  color: #00b4bd;
}

.custom-dialog {
  border-radius: 30px;
}

.modal-content {
  border-radius: 30px;
  background: #f5f5f5;
}

// .nav-pills .nav-link {
//   background-color: transparent;
//   border: 1px solid #bbb;
//   border-radius: 20px;
//   color: #bbb;
// }

.accordion-button::after {
  background-image: none !important;
}

.accordion-button:not(.collapsed) {
  color: #ffffff;
  background-color: #F2F2F2;
  // box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.accordion-button:not(.disabled) {
  color: #ffffff;
  background-color: #F2F2F2;
  border: none;
  // box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.accordion-body {
  background-color: #F2F2F2;
}

.p-inputtext {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  color: #495057;
  background: #ffffff;
  padding: 0.75rem 0.75rem;
  /* border: 1px solid #ced4da; */
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  appearance: none;
  border-radius: 0px;
}

.read-more-less--more {
  color: rgb(0, 180, 189) !important;
}

.read-more-less--less {
  color: rgb(0, 180, 189) !important;
}

.card.card-body.text-dark.p-0 {
  textarea {
    // &:focus-visible {
    //   outline: none !important;
    // }

    div {
      right: -200px !important
    }

    top: 4px !important;
    width: auto !important;
    height: 77% !important;
    // border: none !important;
    // font-size: 12px !important;

  }
}

.f-input-wrapper {
  textarea {
    &:focus-visible {
      outline: none !important;
    }

    div {
      right: -200px !important
    }

    top: 4px !important;
    width: 96% !important;
    height: 45px !important;
    border: none !important;
    font-size: 12px !important;

  }
}

.YourHighlightClass {
  background-color: transparent !important;
  color: rgb(0, 180, 189) !important;

}

#f-input-wrapper {
  ul {
    background-color: white !important;
    position: relative !important;
    // left: 0;
    z-index: 1;
  }

  div {
    position: unset !important;
    width: 100% !important;

    textarea {
      border: none !important;
      width: 85% !important;

      &:focus {
        outline: none !important;
      }
    }
  }
  
}

.Selected_Friends_style {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  ul {
    margin-right: 10px;
  }
}

#ForemVoivher{
  display: flex;
  justify-content: space-between;
}