/* AllCareerRegister.css */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input[type="email"] {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.file-upload {
  margin-top: 10px;
  border: 2px dashed #007bff;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  color: #007bff;
  position: relative;
}

.file-upload p {
  margin: 10px 0 0 0;
}

.file-upload input[type="file"] {
  display: none;
}

button {
  padding: 10px 15px;
  margin-right: 10px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

button[type="button"] {
  background-color: #6c757d;
}

button:hover {
  opacity: 0.9;
}
